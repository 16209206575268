import React, { createRef } from 'react'
import './../App.css'
import NumberFormat from 'react-number-format'
import './../AppMobile.css'
import Portal from './Portal'
import Backdrop from './Backdrop'
import 'reactjs-popup/dist/index.css'
import ModalCalendar from './ModalCalendar'
import ModalReleasingValues from './ModalReleasingValues'
import { DateTime } from 'luxon'
import { dateIntervalObject, updateQtrlyTimingInfo, getExitDateFor, getSpecificQuarterDate } from './Helpers'

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from 'react-device-detect'


class LeasingEventBlock extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      monthsToLeaseExpiry: this.props.monthsToLeaseExpiry,
      appliedLeaseExpiry: this.props.theUnitInfo.leaseExpiry,
      marketRentLevelpa: this.props.day1MarketRentPA,
      theDay1NIA: this.props.day1NIA,
      calendarModalIsOn: false,
      releasingModalIsOn: false,
      rentPerSquareUnit: 0,
      theUnitInfo: this.props.theUnitInfo,
    }

    //this.updateMarketRentAndRentPSU = this.updateMarketRentAndRentPSU.bind(this)
    this.updateLeaseExpiryDate = this.updateLeaseExpiryDate.bind(this)
    this.handleReleasingValues = this.handleReleasingValues.bind(this)
    this.handleCancelModal = this.handleCancelModal.bind(this)
    this.openCalendarModal = this.openCalendarModal.bind(this)
    this.openReleasingModal = this.openReleasingModal.bind(this)
    this.handleCancelReleasingModal = this.handleCancelReleasingModal.bind(this)
  }

  handleReleasingValues(theUpdatedUnitInfo) {
    //Called upon Cal edit and Releasing edit
    
    let newDate = new Date()
    newDate.setHours(0,0,0,0)
    
    let endHoldingDate = getExitDateFor(newDate, this.props.holdPeriodYears)
    let newLeasingDate = new Date(this.state.appliedLeaseExpiry)
    newLeasingDate.setHours(0,0,0,0)
    // console.log(" @@@ appliedLeaseExpiry LEX: " + this.state.appliedLeaseExpiry)
    // console.log(" @@@ New Leasing Date: " + newLeasingDate)
    // console.log(" void: " + theUpdatedUnitInfo.leasingVoidMths + " RF mths: " + theUpdatedUnitInfo.leasingRentFreeMonths)
    newLeasingDate = new Date(newLeasingDate.setMonth(newLeasingDate.getMonth() + theUpdatedUnitInfo.leasingVoidMths + theUpdatedUnitInfo.leasingRentFreeMonths))
    
    // console.log(" @@@2New Leasing Date: " + newLeasingDate)
    // endHoldingDate.setMonth(endHoldingDate.getMonth()-2)
    // endHoldingDate.setDate(endHoldingDate.getDate()+3)
    let releasingInfo = updateQtrlyTimingInfo(newDate, newLeasingDate, endHoldingDate)

    var updatedUnitInfo_Update = {
      id: theUpdatedUnitInfo.id,
      leaseExpiry: this.state.appliedLeaseExpiry,
      leaseExpiryDilaps: theUpdatedUnitInfo.leaseExpiryDilaps,
      // these are updated:
      leasingVoidMths: theUpdatedUnitInfo.leasingVoidMths,
      leasingVoidBizCostsPSUPA: theUpdatedUnitInfo.leasingVoidBizCostsPSUPA,
      leasingVoidSCCostsPSUPA: theUpdatedUnitInfo.leasingVoidSCCostsPSUPA,
      leasingRentFreeMonths: theUpdatedUnitInfo.leasingRentFreeMonths,

      startQtrDate: releasingInfo.startQtrDate,
      startQtr: releasingInfo.startQtr,
      leadingDays: releasingInfo.leadingDays,
      leadingMths: releasingInfo.leadingMths,

      endQtrDate: releasingInfo.endQtrDate,
      endQtr: releasingInfo.endQtr,
      trailingDays: releasingInfo.trailingDays,
      trailingMths: releasingInfo.trailingMths,
    }

    //console.log(" \\\\ " + JSON.stringify(updatedUnitInfo_Update))
    this.setState({theUnitInfo: updatedUnitInfo_Update})
    this.props.handleReleasingValues(updatedUnitInfo_Update)

    this.handleCancelReleasingModal()
  }

  openCalendarModal() {
    this.setState({ calendarModalIsOn: true })
    // console.log('Open Calendar modal and send: ' + this.state.leaseExpiryDate)
    // var tempDate = Date(JSON.stringify(this.state.leaseExpiryDate))
    // console.log(JSON.stringify(tempDate))
  }

  handleCancelModal() {
    this.setState({ calendarModalIsOn: false })
  }

  openReleasingModal() {
    this.setState({ releasingModalIsOn: true })
  }

  handleCancelReleasingModal() {
    this.setState({ releasingModalIsOn: false })
  }

  updateLeaseExpiryDate(theDate) {
    //console.log('updating lease exp value: '+ theDate)
    //console.log('...also as '+ JSON.stringify(theDate))

    this.setState({ appliedLeaseExpiry: theDate })

    this.setState({ calendarModalIsOn: false})

    // console.log(" \\\\ 11 " + JSON.stringify(this.props.theUnitInfo))
    
    let newDate = new Date()
    newDate.setHours(0,0,0,0)
    
    let endHoldingDate = getExitDateFor(newDate, this.props.holdPeriodYears)
    let newLeasingDate = new Date(theDate)
    newLeasingDate.setHours(0,0,0,0)
   
    newLeasingDate = new Date(newLeasingDate.setMonth(newLeasingDate.getMonth() + this.state.theUnitInfo.leasingVoidMths + this.state.theUnitInfo.leasingRentFreeMonths))
    
    let releasingInfo = updateQtrlyTimingInfo(newDate, newLeasingDate, endHoldingDate)

    var updatedUnitInfo_Update = {
      id: this.state.theUnitInfo.id,
      leaseExpiry: theDate,
      leaseExpiryDilaps: this.state.theUnitInfo.leaseExpiryDilaps,
      // these are updated:
      leasingVoidMths: this.state.theUnitInfo.leasingVoidMths,
      leasingVoidBizCostsPSUPA: this.state.theUnitInfo.leasingVoidBizCostsPSUPA,
      leasingVoidSCCostsPSUPA: this.state.theUnitInfo.leasingVoidSCCostsPSUPA,
      leasingRentFreeMonths: this.state.theUnitInfo.leasingRentFreeMonths,

      startQtrDate: releasingInfo.startQtrDate,
      startQtr: releasingInfo.startQtr,
      leadingDays: releasingInfo.leadingDays,
      leadingMths: releasingInfo.leadingMths,

      endQtrDate: releasingInfo.endQtrDate,
      endQtr: releasingInfo.endQtr,
      trailingDays: releasingInfo.trailingDays,
      trailingMths: releasingInfo.trailingMths,
    }

    // console.log(" \\\\ 22" + JSON.stringify(updatedUnitInfo_Update))
    this.setState({theUnitInfo: updatedUnitInfo_Update})
    this.props.handleReleasingValues(updatedUnitInfo_Update)

  }


  render() {
    var theColumnForIncome = isMobile
      ? 'columnForIncomeMobile'
      : 'columnForIncomeDate'
    var theComponentTitle = isMobile ? 'componentTitleMobile' : 'componentTitle'
    var theFlexParent = isMobile ? 'flexParentMobile' : 'flexParent'
    var theLabelTextDate = isMobile ? 'labelDateTextMobile' : 'labelDateText' 
    var theLabelDateTextNoBorder = isMobile ? 'labelDateTextNoBorderMobile' : 'labelDateTextNoBorder'

    var formatter = new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: this.props.currencyCode,
      maximumFractionDigits: 2,
    })

    var theRentPerSqUnit = 0
    if (this.props.day1NIA > 0) {
      theRentPerSqUnit = this.props.day1MarketRentPA / this.props.day1NIA
    }

    var tempExpDate = this.state.appliedLeaseExpiry.toLocaleDateString('en-GB', {
      day: 'numeric', month: 'short', year: 'numeric'
    }).replace(/ /g, '-');

    //var tempStartDate = new Date(this.state.appliedLeaseExpiry)
    var tempStartDate = new Date(this.props.theUnitInfo.leaseExpiry)
    tempStartDate.setHours(0,0,0,0)

    tempStartDate.setMonth(this.props.theUnitInfo.leaseExpiry.getMonth() + Number(this.props.theUnitInfo.leasingRentFreeMonths) + Number(this.props.theUnitInfo.leasingVoidMths))
    
    var tempStartDateDisplay = tempStartDate.toLocaleDateString('en-GB', {
      month: 'short', year: 'numeric'
    }).replace(/ /g, '-');

    if (this.props.theUnitInfo.leaseExpiry.getTime() > 0) {
      tempExpDate = this.props.theUnitInfo.leaseExpiry.toLocaleDateString('en-GB', {
        month: 'short', year: 'numeric'
      }).replace(/ /g, '-');
    }

    return (
      <div>
        <div className={theColumnForIncome}>

        <div className={theFlexParent}>
          <div className={theComponentTitle}>{this.props.title}</div>
          <div
            onClick = {this.openCalendarModal} 
            className={theLabelTextDate}
            >
                Expiry:{tempExpDate}
          </div>
          
          <div 
            onClick = {this.openReleasingModal} >
              <div className={theLabelTextDate}>{this.props.theUnitInfo.leasingVoidMths}mths void</div>
              {/* <div className='labelDateTextNoBorder'>{this.props.theUnitInfo.leasingVoidMths}mths void ({this.props.currencySymbol}{this.props.theUnitInfo.leasingVoidBizCostsPSUPA+this.props.theUnitInfo.leasingVoidSCCostsPSUPA}psf cost pa)</div> */}
              {/* <div className='labelDateTextNoBorder'>New Lease: {this.props.theUnitInfo.leasingRentFreeMonths}mths Rent Free</div> */}
          </div>
          
          {(theRentPerSqUnit>0) &&
          (
            <div className={theLabelDateTextNoBorder}>Income starts: {tempStartDateDisplay} @ {this.props.currencySymbol}{Number(theRentPerSqUnit).toFixed(2)}psf</div>
          )}
          {(theRentPerSqUnit==0) &&
          (
            <div className={theLabelDateTextNoBorder}>Income starts: {tempStartDateDisplay}</div>
          )}
        </div>

            {(this.state.calendarModalIsOn==true) && (
            <Portal>
              <Backdrop/>
              <ModalCalendar
                autoFocus
                title="Select Lease Expiry Date"
                leaseExpiryDate={this.state.appliedLeaseExpiry}
                //leaseExpiryDate={Date(JSON.stringify(this.state.leaseExpiryDate))}
                applyModalValue={this.updateLeaseExpiryDate}
                handleCancelModal={this.handleCancelModal}
              />
            </Portal>
            )}

            {(this.state.releasingModalIsOn==true) && (
            <Portal>
              <Backdrop/>
              <ModalReleasingValues
                autoFocus
                title="Releasing Assumptions"
                currencySymbol={this.props.currencySymbol}
                theUnitInfo={this.props.theUnitInfo}
                day1NIA = {this.props.day1NIA}
                handleReleasingValues={this.handleReleasingValues}
                handleCancelModal={this.handleCancelReleasingModal}
              />
            </Portal>
            )}
          
        </div>
      </div>
    )
  }
}
export default LeasingEventBlock
