import React, { useTransition } from 'react'
import './../App.css'
import NumberFormat from 'react-number-format'
import Popup from 'reactjs-popup'
import Portal from './Portal'
import ModalValueForTarget from './ModalValueForTarget'
import Backdrop from './Backdrop'
import targetIcon from '../images/targetIcon.png'
import 'reactjs-popup/dist/index.css'
import { getExitDateFor, getRelevantRentPAatExit } from './Helpers'
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from 'react-device-detect'

import { useState } from 'react'

class BuySellBlock extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      inputValuePrice: this.props.price,
      inputValueCosts: this.props.buyOrSellCosts,
      inputValueYield: this.props.theYield,

      rentReviewDate: this.props.rentReviewDate,
      rentReviewHeadlinePercent: this.props.rentReviewHeadlinePercent,

      modalIsOnForNIYield: false,
      modalIsOnForMarketYield: false,
    }

    this.submitPriceValueToParent = this.submitPriceValueToParent.bind(this)
    this.submitPriceTargetFromYield = this.submitPriceTargetFromYield.bind(this)
    this.submitPriceTargetFromMarketYield = this.submitPriceTargetFromMarketYield.bind(this)
    this.removeModalForYield = this.removeModalForYield.bind(this)
    this.removeModalForMarketYield = this.removeModalForMarketYield.bind(this)
    this.popUpSupportText = this.popUpSupportText.bind(this)
  }

  componentWillUnmount() {
    const initialValue = document.body.style.zoom
    // Change zoom level on mount
    document.body.style.zoom = '100%'
    return () => {
      // Restore default value
      document.body.style.zoom = initialValue
    }
  }

  submitPriceValueToParent(floatValue) {
    this.setState({ inputValuePrice: floatValue }, () => {
      this.props.applyModalValue(this.state.inputValuePrice)
    })
  }

  submitPriceTargetFromYield(thePrice) {
    this.setState({ modalIsOnForNIYield: !this.state.modalIsOnForNIYield })
    this.setState({ inputValuePrice: thePrice })
  }

  submitPriceTargetFromMarketYield(thePrice) {
    this.setState({ modalIsOnForMarketYield: !this.state.modalIsOnForMarketYield })
    this.setState({ inputValuePrice: thePrice })
  }

  removeModalForYield() {
    this.setState({ modalIsOnForNIYield: !this.state.modalIsOnForNIYield })
  }

  removeModalForMarketYield() {
    this.setState({
      modalIsOnForMarketYield: !this.state.modalIsOnForMarketYield,
    })
  }

  popUpSupportText(theLabelText, theSupportText) {
    return (
      <>
        <Popup
          className="theYieldComponentOne"
          //style={{ margin: '0px', padding: '0px' }}
          trigger={
            <a 
              style={{
                cursor: 'pointer',
              }} 
            >
              {theLabelText}
            </a>
          }
          position="right center"
        >
          <div
            style={{
              backgroundColor: 'white',
              padding: '10px',
              fontBold: 'bolder',
            }}
          >
            NIY = Net Initial Yield (UK terminology){' '}
          </div>
          <div
            style={{
              backgroundColor: 'white',
              padding: '10px',
              fontSize: '12px',
            }}
          >
            Calculation = Contracted Rent / (Buy Price + Buy Costs).
          </div>
        </Popup>
      </>
    )
  }

  displayDay1Yield() {
    if (this.props.appStage > 7) {
      var modalYieldTitle = this.props.title.includes('Buy')
        ? 'Enter the target Entry NIY'
        : 'Enter the target Exit NIY'
      var modalMarketYieldTitle = this.props.title.includes('Buy')
        ? 'Enter the target Entry RY'
        : 'Enter the target Exit RY'
      var theFlexParent = isMobile ? 'flexParentMobile' : 'flexParent'
      var theFlexParentYield = isMobile ? 'flexParentYieldMobile' : 'flexParent'
      //var theLabelLeftText = isMobile ? 'labelLeftTextMobile' : 'labelLeftText'
      //var theSubInputTextSize = isMobile ? '12px' : '1.6vw'

      var relevantRentPa = this.props.day1RentPA
      var relevantERVpa = this.props.day1MarketRentPA

      var yieldComponentOne = isMobile
        ? 'theYieldComponentOneMobile'
        : 'theYieldComponentOne'

      if (this.props.title.includes('Sell')) {
          // Assign potential grown Day-1 rent
          relevantRentPa = getRelevantRentPAatExit(new Date(), this.props.holdPeriodInYears, this.props.theUnitInfo.leaseExpiry, this.props.rentReviewDate, this.props.day1RentPA,this.props.day1MarketRentPA, this.props.rentReviewHeadlinePercent)
        }

      return (
        <>
          <div className={theFlexParentYield}>
            <span 
            className={yieldComponentOne}
                style={{cursor: 'pointer'}}
            >
              {this.popUpSupportText('NIY', 'NIY support text')}
            </span>

            <span
              onClick={(e) => {
                this.setState({
                  modalIsOnForNIYield: !this.state.modalIsOnForNIYield,
                })
              }}
              className={yieldComponentOne}
              style={{cursor: 'pointer'}}
            >
              {Number(this.props.theYield).toFixed(2)}%
            </span>
            <span>
             {/* <img src={targetIcon} alt="targetIcon" height="12vw" style={{marginTop:'0.5vw'}} /> */}
            </span>

            {this.props.appStage > 9 && (
              <>
                <span className={yieldComponentOne}
                    style={{
                    /*fontSize: '1.6vw',*/
                    marginTop: '0.25vw',
                    marginLeft: '0vw',
                    cursor: 'pointer',
                  }} 
                >
                  {this.popUpSupportText('RY', 'Market or RY support text')}
                </span>
                <span className={yieldComponentOne}
                  onClick={(e) => {
                    this.setState({
                      modalIsOnForMarketYield: !this.state
                        .modalIsOnForMarketYield,
                    }) // [ 3 ]
                  }}
                    style={{
                    /* fontSize: '1.6vw',*/
                    marginTop: '0.25vw',
                    marginLeft: '0.3vw',
                    cursor: 'pointer',
                  }} 
                >
                  {Number(this.props.theMarketYield).toFixed(2)}%
                </span>
                <span>
                {/*<img src={targetIcon} alt="targetIcon" height="15px" style={{marginTop:'0.5vw'}} /> */}
                </span>
              </>
            )}
          </div>
          {this.state.modalIsOnForNIYield && (
            <Portal>
              <Backdrop />
              <ModalValueForTarget
                title={modalYieldTitle}
                handleCancelButton={this.removeModalForYield}
                applyModalValue={this.submitPriceTargetFromYield}
                rentPA={relevantRentPa}
                buyOrSellCosts = {this.state.inputValueCosts}
                buyCosts = {this.props.buyCosts}
              />

            </Portal>
          )}
          {this.state.modalIsOnForMarketYield && (
            <Portal>
              <Backdrop />
              <ModalValueForTarget
                title={modalMarketYieldTitle}
                handleCancelButton={this.removeModalForMarketYield}
                applyModalValue={this.submitPriceTargetFromMarketYield}
                rentPA={relevantERVpa}
                buyOrSellCosts = {this.state.inputValueCosts}
                buyCosts = {this.props.buyCosts}
              />
            </Portal>
          )}
        </>
      )
    }
  }

  render() {

    
    var theColumnForBuySell = isMobile
      ? 'columnForBuySellMobile'
      : 'columnForBuySell'
    var theComponentTitle = isMobile ? 'componentTitleMobile' : 'componentTitle'
    var theFlexParent = isMobile ? 'flexParentMobile' : 'flexParent'
    var theLabelLeftText = isMobile ? 'labelLeftTextMobile' : 'labelLeftText'
    var theInputTextSize = isMobile ? '1.0rem' : '1.5vw'
    var theSubInputTextSize = isMobile ? '1.0rem' : '1.25vw' // e.g. costs percentage
    var inputStyling = isMobile ? 'inputStylingMobile' : 'inputStyling'
    var theContainer = isMobile ? 'buySellContainerMobile' : 'buySellContainer'
    var theTitleItemLarge = isMobile ? 'titleItemLargeMobile' : 'titleItemLarge'

    return (
      <div>
        <div className={theColumnForBuySell}>

          <div className = {theFlexParent}>
            <div className={theComponentTitle}>{this.props.title}</div>
            <div className= {inputStyling}>
              <NumberFormat value={this.state.inputValuePrice} inputMode="decimal" placeholder="0" thousandSeparator={true} prefix={this.props.currencySymbol}
              onValueChange={(values) => {
                const { formattedValue, value, floatValue } = values
                this.setState({ inputValuePrice: floatValue }, () => {
                  this.props.applyModalValue(this.state.inputValuePrice)
                })
              }}
              style={{ marginLeft: '5px', marginRight: '5px', backgroundColor: 'solid white', fontSize: theInputTextSize, border: '0.5px solid white', borderRadius: '10px', color: 'black', textAlign: 'center',}}
              />
            </div>
          
          
            <Popup className={theLabelLeftText}
              trigger={
                <a style={{ marginLeft: '10px', padding: '0px', cursor: 'pointer',}}
                  className={theLabelLeftText} >
                    <BrowserView>
                    {this.props.labelCosts}
                    </BrowserView>
                </a>
              }
              position="bottom center"
            >
              <div
                style={{
                  backgroundColor: 'white',
                  padding: '10px',
                  fontBold: 'bolder',
                }}
              >
                Buy or Acquisition Costs{' '}
              </div>
              <div
                style={{
                  backgroundColor: 'white',
                  padding: '10px',
                  fontSize: '12px',
                }}
              >
                Calculated as a percentage of Buy Price.
              </div>
            </Popup>

            <BrowserView>
            <NumberFormat value={this.state.inputValueCosts} inputMode="decimal" thousandSeparator={true} 
            suffix="%" 
            placeholder="0.00%"
              onSubmit={(values) => {
                document.body.style.zoom = '100%'
              }}
              onValueChange={(values) => {
                const { formattedValue, value, floatValue } = values
                this.setState({ inputValueCosts: floatValue }, () => {
                  this.props.handleModalCosts(this.state.inputValueCosts)
                })
                // do something with floatValue
              }}
              style={{
                backgroundColor: 'solid white',
                fontSize: theSubInputTextSize,
                lineHeight: theSubInputTextSize,
                textAlign: 'center',
                // boxSizing: 'border-box',
                border: '0.5px solid white',
                borderRadius: '10px',
                marginTop: '2px',
                color: 'black',
                width: '45%',
                float: 'right',
              }}
            />
            </BrowserView>

            <MobileView>
            <NumberFormat value={this.state.inputValueCosts} inputMode="decimal" thousandSeparator={true} 
            suffix="% costs" 
            placeholder="0% costs"
              onSubmit={(values) => {
                document.body.style.zoom = '100%'
              }}
              onValueChange={(values) => {
                const { formattedValue, value, floatValue } = values
                this.setState({ inputValueCosts: floatValue }, () => {
                  this.props.handleModalCosts(this.state.inputValueCosts)
                })
                // do something with floatValue
              }}
              style={{
                backgroundColor: 'solid white',
                fontSize: theSubInputTextSize,
                lineHeight: theSubInputTextSize,
                textAlign: 'center',
                // boxSizing: 'border-box',
                border: '0.5px solid white',
                borderRadius: '10px',
                color: 'black',
                width: '80%',
                float: 'right',
              }}
            />
            </MobileView>



          </div>
            <>{this.displayDay1Yield()}</>
              {/* <div className="relativeDiv">
              <br></br>
              <div className="divTipContext">{this.props.labelTip}</div>
              </div> */}
          </div>
      </div>
    )
  }
}
export default BuySellBlock

var stylingObject = {
  input: {
    margin: '2px',
    padding: '5px',
  },
  outerDiv: {
    border: '0px',
  },
  addStyling: {
    border: '1px dashed white',
    margin: '40px',
    borderRadius: '20px',
    padding: '40px',
    lineHeight: '30px',
    fontSize: '20px',
    textAlign: 'center',
    fontWeight: 'bolder',
    cursor: 'pointer',
  },
  btnStyling: {
    backgroundColor: 'white',
    border: '1px solid grey',
    borderRadius: '5px',
    fontSize: '18px',
    margin: '15px',
    paddingLeft: '30px',
    paddingRight: '30px',
    color: '#38336E',
  },
  fieldStyling: {
    borderRadius: '20px',
  },
}

export function ChildPassed({ modalFinished }) {
  const [inputValue, setInputValue] = useState(0)

  return (
    <div className="theportal">
      <fieldset className="form-Fieldset" style={stylingObject.fieldStyling}>
        <label className="form-FieldLabel" style={stylingObject.fieldStyling}>
          <input
            //ref={this.input1}
            //value={this.state.inputValue}
            //onChange={(evt) => setInputValue(evt)}
            // onKeyDown={(evt) => this.handleKeyDown(evt)}
            type="number"
            name="theName"
            style={{ border: '0px', fontSize: '22px', textAlign: 'center' }}
          />
        </label>
      </fieldset>
      <br />
      <button onClick={modalFinished} style={stylingObject.btnStyling}>
        Applies
      </button>
      <button
        // onClick={this.reverseTheAppStage}
        style={stylingObject.btnStyling}
      >
        Cancel
      </button>
      <br />
    </div>
  )
}
