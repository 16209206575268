import React from 'react'
import AddBlock from './AddBlock'
import Modal from './Modal'
import Backdrop from './Backdrop'
import './../App.css'
import BuySellBlock from './BuySellBlock'
import HoldPeriodBlock from './HoldPeriodBlock'
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from 'react-device-detect'

class BuySellNarrative extends React.Component {
  constructor(props) {
    super(props)

    this.handlePurchaseModalValue = this.handlePurchaseModalValue.bind(this)
    this.handleBuyCosts = this.handleBuyCosts.bind(this)
    this.handleBuyYieldInput = this.handleBuyYieldInput.bind(this)
    this.handleSellModalValue = this.handleSellModalValue.bind(this)
    this.handleSellCosts = this.handleSellCosts.bind(this)
    this.handleSellYield = this.handleSellYield.bind(this)
    this.handleHoldPeriodModalValue = this.handleHoldPeriodModalValue.bind(this)
    this.handleSellYieldValue = this.handleSellYield.bind(this)
  }

  handlePurchaseModalValue(theValue) {
    //console.log('passing BSNarrative handlePPrice val upwards')
    this.props.handlePurchasePrice(theValue)
  }

  handleBuyCosts(theValue) {
    this.props.handlePurchaseCosts(theValue)
  }
  handleSellCosts(theValue) {
    this.props.handleSellCosts(theValue)
  }

  handleBuyYieldInput(theValue) {
    this.props.handleBuyYield(theValue)
  }

  handleSellModalValue(theValue) {
    this.props.handleSellPrice(theValue)
  }

  handleSellYield(theValue) {
    this.props.handleSellYield(theValue)
  }
  handleHoldPeriodModalValue(theValue) {
    this.props.handleHoldPeriod(theValue)
  }

  // handleKeyDown(event) {
  //   if (event.keyCode === 13) {
  //     this.submitValueToParent()
  //   } else if (event.keyCode === 27) {
  //     this.reverseTheAppStage()
  //   }
  // }

  buySellNarrativeStatus() {
    var theColumnEmpty = isMobile ? 'columnEmptyMobile' : 'columnEmpty'
    var theContainer = isMobile ? 'buySellContainerMobile' : 'buySellContainer'
    var theTitleItemLarge = isMobile ? 'titleItemLargeMobile' : 'titleItemLarge'
    var theCostsTitle = isMobile ? 'Costs' : 'Buy Costs'
    var theSellCostsTitle = isMobile ? 'Costs' : 'Sell Costs'
    var theBuyLabel = isMobile ? 'Buy' : 'Buy Price'
    var theSellLabel = isMobile ? 'Sell' : 'Sell Price'

    // Determine the Exit Rent to be passed to Exit BuySellBlock
    // i.e. contracted rent, reviewed rent or new rent
    // ======


    if (this.props.value == 0) {
      return (
        <div>
          <BrowserView>
            <div className={theContainer}>
            <div className={theColumnEmpty}></div>
            <div className={theColumnEmpty}></div> 
            <div className={theColumnEmpty}>
              <div onClick={() => this.props.changeAppStage()}>
              <AddBlock
                autoFocus
                flagAddress={this.props.flagAddress}
                style={{
                  background: 'url(' + this.props.flagAddress + ') no-repeat',
                  opacity: 0.25,
                }}
                text="+ Add your Buy Price"
              />
              </div>
            </div>
            <div className={theColumnEmpty}></div>
            <div className="bracketItem"></div>
          </div>
          </BrowserView>

          <MobileView>
            <div className={theColumnEmpty}>
              <div onClick={() => this.props.changeAppStage()}>
              <AddBlock
                autoFocus
                flagAddress={this.props.flagAddress}
                style={{
                  fontSize: '2.5rem',
                  background: 'url(' + this.props.flagAddress + ') no-repeat',
                  opacity: 0.25,
                }}
                text="+ Add your Buy Price"
              />
              </div>
            </div>
          </MobileView>
          

        </div>
      )

    } else if (this.props.value == 1) {
      return (
        <div>
        <BrowserView>
        <div className={theContainer}>
          <div className={theColumnEmpty}></div>
          <div className={theColumnEmpty}></div>
          <div className={theColumnEmpty}>
            <AddBlock text="+ Add your Purchase Price" />
          </div>
          <div className={theColumnEmpty}></div>
          <div className="bracketItem"></div>
          <Backdrop />
          <div>
            <div onClick={() => this.props.changeAppStage()} />
            <Modal
              title="Input your Target Buy Price"
              currencySymbol={this.props.currencySymbol}
              applyModalValue={this.handlePurchaseModalValue}
              handleReverseAppStage={this.props.handleReverseAppStage}
            />
          </div>
        </div>
        </BrowserView>

        <MobileView>
        <div className={theColumnEmpty}>
        <AddBlock text="+ Add your Purchase Price" />
        </div>
          <Backdrop />
          <div>
          <div onClick={() => this.props.changeAppStage()} />
          <Modal
            title="Input your Target Buy Price"
            currencySymbol={this.props.currencySymbol}
            applyModalValue={this.handlePurchaseModalValue}
            handleReverseAppStage={this.props.handleReverseAppStage}
          />
        </div>
        </MobileView>
        </div>

      )
    } else if (this.props.value == 2) {
      // Display Acquisition Price

      return (
        <div>
        <BrowserView>
        <div className={theContainer}>
          <div className={theTitleItemLarge}></div>
          <BuySellBlock
            title={theBuyLabel}
            currencySymbol={this.props.currencySymbol}
            price={this.props.entryPrice}
            appStage={this.props.appStage}
            labelCosts={theCostsTitle}
            labelTip=""
            applyModalValue={this.handlePurchaseModalValue}
            handleModalCosts={this.handleBuyCosts}
          />
          <div
            className={theColumnEmpty}
            onClick={() => this.props.changeAppStage()}
          >
            <AddBlock text="+ Add your Sell Price" />
          </div>
          <div className={theColumnEmpty}></div>
          {/* <div className="bracketItem"></div> */}
        </div>
        </BrowserView>

        <MobileView>
        <div className={theContainer}>
          <div className={theTitleItemLarge}></div>
          <BuySellBlock
            title={theBuyLabel}
            currencySymbol={this.props.currencySymbol}
            price={this.props.entryPrice}
            appStage={this.props.appStage}
            labelCosts={theCostsTitle}
            labelTip=""
            applyModalValue={this.handlePurchaseModalValue}
            handleModalCosts={this.handleBuyCosts}
          />
          <div
            className={theColumnEmpty}
            onClick={() => this.props.changeAppStage()}
          >
            <AddBlock text="+ Add your Sell Price" />
          </div>
          <div className={theColumnEmpty}></div>
          {/* <div className="bracketItem"></div> */}
        </div>
        </MobileView>

        </div>
      )
    } else if (this.props.value == 3) {
      // Modal for Sell Price

      return (
        <div className={theContainer}>
          <div className={theTitleItemLarge}></div>
          <BuySellBlock
            title={theBuyLabel}
            currencySymbol={this.props.currencySymbol}
            price={this.props.entryPrice}
            appStage={this.props.appStage}
            inputValueCosts={this.props.buyCosts}
            labelCosts={theCostsTitle}
            labelTip=""
            applyModalValue={this.handlePurchaseModalValue}
            handleModalCosts={this.handleBuyCosts}
          />
          <div className={theColumnEmpty}>
            <AddBlock text="+ Add your Sell Price" />
          </div>
          <div className={theColumnEmpty}></div>
          {/* <div className="bracketItem"></div> */}
          <Backdrop />
          <div>
            <Modal
              title="Input your Sell Price"
              currencySymbol={this.props.currencySymbol}
              applyModalValue={this.handleSellModalValue}
              handleReverseAppStage={this.props.handleReverseAppStage}
            />
          </div>
        </div>
      )
    } else if (this.props.value == 4) {
      // Display Sell Price
      return (
        <div className={theContainer}>
          <div className={theTitleItemLarge}></div>
          <BuySellBlock
            title={theBuyLabel}
            currencySymbol={this.props.currencySymbol}
            price={this.props.entryPrice}
            appStage={this.props.appStage}
            inputValueCosts={this.props.buyCosts}
            labelCosts={theCostsTitle}
            applyModalValue={this.handlePurchaseModalValue}
            handleModalCosts={this.handleBuyCosts}
          />
          <div
            className={theColumnEmpty}
            onClick={() => this.props.changeAppStage()}
          >
            <AddBlock text="+ Add your Hold Period" />
          </div>
          <BuySellBlock
            title={theSellLabel}
            currencySymbol={this.props.currencySymbol}
            price={this.props.sellPrice}
            appStage={this.props.appStage}
            inputValueCosts={this.props.sellCosts}
            applyModalValue={this.handleSellModalValue}
            handleModalCosts={this.handleSellCosts}
            labelCosts={theSellCostsTitle}
            labelTip=""
          />
          <div className="bracketItem"></div>
        </div>
      )
    } else if (this.props.value == 5) {
      // Display Modal for Hold Period
      return (
        <div className={theContainer}>
          <div className={theTitleItemLarge}></div>
          <BuySellBlock
            title={theBuyLabel}
            currencySymbol={this.props.currencySymbol}
            price={this.props.entryPrice}
            appStage={this.props.appStage}
            labelCosts={theCostsTitle}
            applyModalValue={this.handlePurchaseModalValue}
            handleModalCosts={this.handleBuyCosts}
          />
          <div className="columnEmpty">
            <AddBlock text="+ Add your Hold Period" />
          </div>
          <BuySellBlock
            title={theSellLabel}
            currencySymbol={this.props.currencySymbol}
            price={this.props.sellPrice}
            appStage={this.props.appStage}
            applyModalValue={this.handleSellModalValue}
            handleModalCosts={this.handleSellCosts}
            labelCosts={theSellCostsTitle}
            labelTip=""
          />
          <div className="bracketItem"></div>
          <Backdrop />
          <div>
            <Modal
              title="Input your Hold Period (years)"
              applyModalValue={this.handleHoldPeriodModalValue}
              handleReverseAppStage={this.props.handleReverseAppStage}
            />
          </div>
        </div>
      )
    } else if (this.props.value > 5) {
      // Display Hold Period
      return (
        <div className={theContainer}>
          <BrowserView>
          <div className={theTitleItemLarge}>
            Buy / Sell
          </div>
          </BrowserView>
          <BuySellBlock
            title={theBuyLabel}
            currencySymbol={this.props.currencySymbol}
            price={this.props.entryPrice}
            appStage={this.props.appStage}
            labelCosts={theCostsTitle}
            buyOrSellCosts={this.props.buyCosts}
            buyCosts={this.props.buyCosts}
            applyModalValue={this.handlePurchaseModalValue}
            handleModalCosts={this.handleBuyCosts}
            applyModalYield={this.handleBuyYieldInput}
            theYield={this.props.buyYield}
            theMarketYield={this.props.buyMarketYield}
            day1RentPA={this.props.day1RentPA}
            day1MarketRentPA={this.props.day1MarketRentPA}
          />
          <HoldPeriodBlock
            title="Hold Period"
            holdPeriodInYears={this.props.holdPeriodInYears}
            applyBlockValue={this.handleHoldPeriodModalValue}
          />
          <BuySellBlock
            title={theSellLabel}
            holdPeriodInYears={this.props.holdPeriodInYears}
            currencySymbol={this.props.currencySymbol}
            price={this.props.sellPrice}
            appStage={this.props.appStage}
            applyModalValue={this.handleSellModalValue}
            handleModalCosts={this.handleSellCosts}
            applyModalYield={this.handleSellYield}
            labelCosts={theSellCostsTitle}
            buyOrSellCosts={this.props.sellCosts}
            buyCosts={this.props.buyCosts}
            labelTip=""
            theYield={this.props.sellYield}
            theMarketYield={this.props.sellMarketYield}
            day1RentPA={this.props.day1RentPA}
            day1MarketRentPA={this.props.day1MarketRentPA}
            theUnitInfo={this.props.theUnitInfo}
            rentReviewDate={this.props.rentReviewDate}
            rentReviewHeadlinePercent={this.props.rentReviewHeadlinePercent}
            rentReviewPercentFee={this.props.rentReviewPercentFee}
          />
          <div className="bracketItem"></div>
        </div>
      )
    }
  }

  render() {
    return (
      // <div className="buySellContainer">{this.buySellNarrativeStatus()}</div>
      <>{this.buySellNarrativeStatus()}</>
    )
  }

}
export default BuySellNarrative
