import React, { useRef } from 'react'
import './../App.css'
import './../AppMobile.css'
import './../Calendar.css'
import NumberFormat from "react-number-format"
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from 'react-device-detect'
import { DateTime } from 'luxon'

var stylingObject = {
  input: {
    margin: '2px',
    padding: '5px',
  },
  outerDiv: {
    border: '0px',
  },
  addStyling: {
    border: '1px dashed white',
    margin: '40px',
    borderRadius: '20px',
    padding: '40px',
    lineHeight: '30px',
    fontSize: '20px',
    textAlign: 'center',
    fontWeight: 'bolder',
    cursor: 'pointer',
  },
  btnStyling: {
    backgroundColor: 'white',
    border: '1px solid grey',
    borderRadius: '5px',
    fontSize: '18px',
    margin: '15px',
    paddingLeft: '30px',
    paddingRight: '30px',
    color: '#38336E',
    width: '100px',
  },
  fieldStyling: {
    borderRadius: '20px',
  },
}

class ModalDebtAssumptions extends React.Component {
  constructor(props) {
    super(props)

    this.state = {

      theLTVinput: this.props.theLTVinput,
      theInterestRatePAinput: this.props.theInterestRatePAinput,
      theArrangementFeeinput: this.props.theArrangementFeeinput,
      theAmortisationPAinput: this.props.theAmortisationPAinput,
    }

    this.updateInputValue = this.updateInputValue.bind(this)
    this.handleCancelPressed = this.handleCancelPressed.bind(this)
    this.updateDebtValues = this.updateDebtValues.bind(this)
  }

  updateInputValue(evt) {
    const val = evt.target.value
    this.setState({ inputValue: val })
  }

  updateDebtValues() {
    this.props.submitValueToParent(this.state.theLTVinput,
      this.state.theInterestRatePAinput,
      this.state.theArrangementFeeinput)
  }

  handleCancelPressed() {
    this.props.handleReverseAppStage()
  }


  handleKeyDown(event) {
    if (event.keyCode === 13) {
      this.submitValueToParent()
    } else if (event.keyCode === 27) {
      this.reverseTheAppStage()
    }
  }

  theButtonsContent() {
    return (
      <>
        <button
          onClick={this.updateDebtValues}
          style={stylingObject.btnStyling}
        >
          Apply
        </button>
        <button
          onClick={this.props.handleCancelModal}
          style={stylingObject.btnStyling}
        >
          Cancel
        </button>
        <br />
      </>
    )
  }

  render() {

    var theFlexParent = isMobile ? 'flexParentMobile' : 'labelAndInput'
    var theLabelLeftText = isMobile ? 'labelLeftTextMobile' : 'labelLeftText'
    var theInputFontSize = isMobile ? '1.0rem' : '1.4vw'
    var theModal = isMobile ? 'modalMobile' : 'modal'
    let thePrefix = (this.props.title.includes("Hold")) ? '' : '£'
    let theSuffix = (this.props.title.includes("Hold")) ? ' years' : ''
    return (
      <div className={theModal}>
        <div className="backgroundEmoji"></div>
        <div>{this.props.title}</div>
        <br/>

        <div className='labelAndInput'>
            <div className={theLabelLeftText}>
                Loan-To-Value
            </div>
            <NumberFormat
              autoFocus suffix={"%"} placeholder={"0.00%"} inputMode="decimal"
              value={this.state.theLTVinput}
              onValueChange={(values) => {
                const { formattedValue, value, floatValue } = values
                this.setState({ theLTVinput: floatValue }, () => {
                })
              }}
            style={{ borderWidth :'0px', borderRadius: '10px', textAlign:'center', fontSize: theInputFontSize, width: '35%'}} />
        </div>
        
        <br/>
        <div className='labelAndInput'>
            <div className={theLabelLeftText}>
                Interest pa
            </div>
            <NumberFormat
            autoFocus suffix={"%"} placeholder={"0.00%"} inputMode="decimal"
            value={this.state.theInterestRatePAinput}
            onValueChange={(values) => {
              const { formattedValue, value, floatValue } = values
              this.setState({ theInterestRatePAinput: floatValue }, () => {
                //this.props.applyModalValue(this.state.inputValue)
              })
            }}
            style={{borderWidth: '0px', borderRadius: '10px', textAlign:'center', fontSize: theInputFontSize,width: '35%' }}
            />
        </div>
        <br/>
        <div className='labelAndInput'>
            <div className={theLabelLeftText}>
                Arrangmt. fee
            </div>
            <NumberFormat
            autoFocus suffix={"%"} placeholder={"0.00%"} inputMode="decimal"
            value={this.state.theArrangementFeeinput}
            onValueChange={(values) => {
              const { formattedValue, value, floatValue } = values
              this.setState({ theArrangementFeeinput: floatValue }, () => {
                //this.props.applyModalValue(this.state.inputValue)
              })
            }}
            style={{borderWidth: '0px', borderRadius: '10px', textAlign:'center', fontSize: theInputFontSize,width: '35%' }}
            />
        </div>
        <br/>
        {this.theButtonsContent()}
        <p></p>
      </div>
    )
  }
}

export default ModalDebtAssumptions
