import React, { useRef } from 'react'
import './../App.css'
import './../AppMobile.css'
import './../Calendar.css'
import NumberFormat from "react-number-format"
import ModalCalendar from "./ModalCalendar"
import Portal from './Portal'
import Backdrop from './Backdrop'
import {
  isMobile,
} from 'react-device-detect'
import { DateTime } from 'luxon'

var stylingObject = {
  input: {
    margin: '2px',
    padding: '5px',
  },
  outerDiv: {
    border: '0px',
  },
  addStyling: {
    border: '1px dashed white',
    margin: '40px',
    borderRadius: '20px',
    padding: '40px',
    lineHeight: '30px',
    fontSize: '20px',
    textAlign: 'center',
    fontWeight: 'bolder',
    cursor: 'pointer',
  },
  btnStyling: {
    backgroundColor: 'white',
    border: '1px solid grey',
    borderRadius: '5px',
    fontSize: '18px',
    margin: '15px',
    paddingLeft: '30px',
    paddingRight: '30px',
    color: '#38336E',
  },
  btnDeleteStyling: {
    backgroundColor: 'red',
    border: '1px solid grey',
    borderRadius: '5px',
    fontSize: '18px',
    margin: '15px',
    paddingLeft: '30px',
    paddingRight: '30px',
    color: 'white',
  },
  fieldStyling: {
    borderRadius: '20px',
  },
}

class ModalRentReview extends React.Component {
  constructor(props) {
    super(props)

    this.state = {

        marketRentLevelpa: this.props.marketRentLevelpa,
        areaNIA: this.props.areaNIA,
        calendarModalIsOn: false,
        rentReviewDate: new Date(this.props.rentReviewDate),
        rentReviewHeadlinePercent: this.props.rentReviewHeadlinePercent,
        rentReviewPercentFee: this.props.rentReviewPercentFee,
    }
    this.updateInputValue = this.updateInputValue.bind(this)
    this.submitValueToParent = this.submitValueToParent.bind(this)
    this.updateRentReviewInfo = this.updateRentReviewInfo.bind(this)
    this.removeRentReview = this.removeRentReview.bind(this)
    this.updateRentReviewDate = this.updateRentReviewDate.bind(this)
    this.openCalendarModal = this.openCalendarModal.bind(this)
    this.handleCancelDateModal = this.handleCancelDateModal.bind(this)
  }

  openCalendarModal() {
    this.setState({ calendarModalIsOn: true })
    // console.log('Open Calendar modal and send: ' + this.state.leaseExpiryDate)
    // var tempDate = Date(JSON.stringify(this.state.leaseExpiryDate))
    // console.log(JSON.stringify(tempDate))
  }

  handleCancelDateModal() {
    this.setState({ calendarModalIsOn: false})
  }

  updateInputValue(evt) {
    const val = evt.target.value
    this.setState({ inputValue: val })
  }

  updateRentReviewInfo() {
    var rentReviewInfo = {
        rentReviewDate: this.state.rentReviewDate,
        rentReviewHeadlinePercent: this.state.rentReviewHeadlinePercent,
        rentReviewPercentFee: this.state.rentReviewPercentFee,
    }
    this.props.updateRentReviewValues(rentReviewInfo)
  }

  updateRentReviewDate(appliedDate) {
      this.setState({ rentReviewDate: appliedDate })
      this.setState({ calendarModalIsOn: false})
  }

  removeRentReview() {
    var rentReviewInfo = {
      rentReviewDate: new Date(0),
      rentReviewHeadlinePercent: this.state.rentReviewHeadlinePercent,
      rentReviewPercentFee: this.state.rentReviewPercentFee,
    }
    this.props.updateRentReviewValues(rentReviewInfo)
  }

  submitValueToParent() {
    this.props.applyModalValue(this.state.inputValue)
  }

  handleKeyDown(event) {
    if (event.keyCode === 13) {
      this.submitValueToParent()
    } else if (event.keyCode === 27) {
      this.reverseTheAppStage()
    }
  }

  theButtonsContent() {
    return (
      <>
        
        {( this.state.rentReviewDate > 0 ) && (
          <button
          onClick={this.updateRentReviewInfo}
          style={stylingObject.btnStyling}
          >
          Apply
        </button>
        )}

        <button
          onClick={this.props.handleCancelModal}
          style={stylingObject.btnStyling}
        >
          Cancel
        </button>

        {( this.props.rentReviewDate > 0 ) && (
          <button
          onClick={this.removeRentReview}
          style={stylingObject.btnDeleteStyling}
          >
          Remove Review
        </button>
        )}  

        <br />
      </>
    )
  }

  render() {

    var theFlexParent = isMobile ? 'flexParentMobile' : 'labelAndInput'
    var theLabelLeftText = isMobile ? 'labelLeftTextMobile' : 'labelLeftText'
    var theLabelDateText = isMobile ? 'labelDateTextMobile' : 'labelDateText'
    var theModal = isMobile ? 'modalMobile' : 'modal'
    var theInputFontSize = isMobile ? '1.0rem' : '1.4vw'
    let thePrefix = (this.props.title.includes("Hold")) ? '' : '£'
    let theSuffix = (this.props.title.includes("Hold")) ? ' years' : ''
   // var theCurrentRentReviewDate = this.props.rentReviewDate

    var theCurrentRentReviewDate = new Date()
    theCurrentRentReviewDate.setHours(0,0,0,0,)

    if (this.state.rentReviewDate > 0) {
      theCurrentRentReviewDate = new Date(this.state.rentReviewDate)
      theCurrentRentReviewDate.setHours(0,0,0,0,)
    }

    var theDisplayedRentReviewDate = theCurrentRentReviewDate.toLocaleDateString('en-GB', {
      month: 'short', year: 'numeric', day: 'numeric',
    }).replace(/ /g, '-');

    var displayedRentPSU = 0
    var formatter = new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: this.props.currencyCode,
      maximumFractionDigits: 2,
    })
    if( (this.props.marketRentLevelpa > 0) && (this.props.areaNIA > 0) && (this.state.rentReviewDate > 0) ) {
      displayedRentPSU = this.state.marketRentLevelpa / this.state.areaNIA * this.state.rentReviewHeadlinePercent/100
      displayedRentPSU = "to " + formatter.format(displayedRentPSU) 
    }
    else if ((this.props.areaNIA == 0)) {
      displayedRentPSU = ""
    }

    return (
      <div className={theModal}>
        <div className="backgroundEmoji"></div>
        <div>{this.props.title}</div>
        <br/>

      {( this.state.rentReviewDate.getTime() == 0 ) && (
        
        <div
        onClick = {this.openCalendarModal} 
        className={theLabelDateText}
        >
            Tap to add Rent Review Date
        </div>
      )}

      {( this.state.rentReviewDate.getTime() > 0) && (
        <div
        onClick = {this.openCalendarModal} 
        className={theLabelDateText}
        >
            Review on {theDisplayedRentReviewDate} {displayedRentPSU}
        </div>
      )}

        <br/>

        <div className='labelAndInput'>
            <div className={theLabelLeftText}>
                Percent of ERV
            </div>
            <NumberFormat
            value={this.state.rentReviewHeadlinePercent}
            placeholder={"e.g. 95.00%"}
            suffix={"%"}
            onValueChange={(values) => {
              const { formattedValue, value, floatValue } = values
              this.setState({ rentReviewHeadlinePercent: floatValue }, () => {
                //this.props.applyModalValue(this.state.inputValue)
              })
            }}
            style={{borderWidth: '0px', borderRadius: '10px', textAlign:'center', fontSize: {theInputFontSize}, width: '42%' }}
            />
        </div>
        <br/>
       
        <div className='labelAndInput'>
            <div className={theLabelLeftText}>
                Fee cost
            </div>
            <NumberFormat
            value={this.state.rentReviewPercentFee}
            placeholder={"e.g. 1.00%"}
            suffix={"%"}
            onValueChange={(values) => {
              const { formattedValue, value, floatValue } = values
              this.setState({ rentReviewPercentFee: floatValue }, () => {
                //this.pro ps.applyModalValue(this.state.inputValue)
              })
            }}
            style={{borderWidth: '0px', borderRadius: '10px', textAlign:'center', fontSize: {theInputFontSize}, width: '42%' }}
            />
        </div>

      {(this.state.calendarModalIsOn==true) && (
      <Portal>
        <Backdrop/>
        <ModalCalendar
          autoFocus
          title="Select Rent Review Date"
          rentReviewDate={this.state.rentReviewDate}
          applyModalValue={this.updateRentReviewDate}
          handleCancelModal={this.handleCancelDateModal}
        />
      </Portal>
      )}

        <br/>
        {this.theButtonsContent()}
        <p></p>
      </div>
    )
  }
}

export default ModalRentReview