import React from 'react'
import './../App.css'
import NumberFormat from 'react-number-format'
import './../AppMobile.css'
import Portal from './Portal'
import Backdrop from './Backdrop'
import 'reactjs-popup/dist/index.css'
import Modal from './Modal'
import ModalRentReview from './ModalRentReview'
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from 'react-device-detect'

class RentERVBlock extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      marketRentLevelpa: this.props.day1MarketRentPA,
      day1NIA: this.props.day1NIA,

      rentReviewDate: this.props.rentReviewDate,
      rentModalIsOn: false,
      rentReviewModalIsOn: false,
      rentPerSquareUnit: 0,
    }

    this.updateRentReviewInfo = this.updateRentReviewInfo.bind(this)
    this.handleCancelModal = this.handleCancelModal.bind(this)
    this.updateRentReviewValues = this.updateRentReviewValues.bind(this)
  } 

  handleCancelModal() {
    this.setState({ rentReviewModalIsOn: false })
  }

  updateRentReviewInfo(floatValue) {
    this.setState({ marketRentLevelpa: floatValue }, () => {
      this.props.handleDay1MarketRent(floatValue)
    })
  }

  updateRentReviewValues(rentReviewInfo) {
    this.setState({ rentReviewModalIsOn: false})
    this.props.handleRentReviewValues(rentReviewInfo)
  }

  render() {
    var theColumnForIncome = isMobile
      ? 'columnForIncomeMobile'
      : 'columnForIncome'
    var theComponentTitle = isMobile ? 'componentTitleMobile' : 'componentTitle'
    var theSubInputTextSize = isMobile ? '1.0rem' : '1.5vw'
    var theAddItem = isMobile ? 'addItemMobile' : 'addItem'
    var theFlexParent = isMobile ? 'flexParentMobile' : 'flexParent'

    var formatter = new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: this.props.currencyCode,
      maximumFractionDigits: 2,
    })

    var theRentPerSqUnit = 0
    if (this.props.day1NIA > 0) {
      theRentPerSqUnit = this.props.day1MarketRentPA / this.props.day1NIA
    }
    theRentPerSqUnit = formatter.format(theRentPerSqUnit)

    var tempRentReviewDate = new Date(this.props.rentReviewDate)

    return (
      <div>
        <div className={theColumnForIncome}>

          <div className={theFlexParent}>
            <div className={theComponentTitle}>{this.props.title}</div>
            <div className="inputStyling">
              <NumberFormat autoFocus
              value={this.state.marketRentLevelpa} inputMode="decimal" thousandSeparator={true} prefix={this.props.currencySymbol}
              onValueChange={(values) => {
              const { formattedValue, value, floatValue } = values
                this.updateRentReviewInfo(floatValue)
              }}
              style={{
                width: '90%',
                backgroundColor: 'solid white',
                fontSize: theSubInputTextSize,
                textAlign: 'center',
                boxSizing: 'border-box',
                border: '0.5px solid white',
                borderRadius: '7px',
                color: 'black',
                marginLeft: '10px',
                marginRight: '10px',
              }}
            />
            </div>

            {!(tempRentReviewDate.getTime() > 0) && (
            <div style={{
                width: '80%',
                backgroundColor: 'solid white',
                fontSize: theSubInputTextSize,
                textAlign: 'center',
                boxSizing: 'border-box',
                border: '0.5px dashed white',
                borderRadius: '10px',
                color: 'white',
                marginLeft: '15px',

                cursor:'pointer'}}
                onClick={(e) => {
                const rect = e.target.getBoundingClientRect()
                this.setState({
                  left: rect.x + rect.width / 2,
                  top: rect.y + window.scrollY,
                })
                this.setState({ rentReviewModalIsOn: !this.state.rentReviewModalIsOn }) // [ 3 ]
              }}
              className={theAddItem}
            >
              + Add Rent Review
            </div>
          )}

          <div className="groupedItems">
            {this.props.day1NIA > 0 &&
            (
              <div 
              
              style={{
                //display: 'flex',
                //overflow: 'auto',
                //boxSizing: 'border-box',
                //flexDirection: 'column',
                backgroundColor: 'solid white',
                fontSize: theSubInputTextSize,
                color: 'white',
                width: '70%',
                marginLeft: '5px',
              }}
              
              >
                {theRentPerSqUnit + 'psf'}
              </div>
            )}

            {tempRentReviewDate.getTime() > 0 && (
              <>
                {this.displayRentReviewDate()}
              </>
            )}    
          </div>

          </div>

          

          {(this.state.rentReviewModalIsOn==true) && (
            <Portal>
              <Backdrop/>
              <ModalRentReview
                autoFocus
                title="Rent Review"
                marketRentLevelpa = {this.state.marketRentLevelpa}
                areaNIA = {this.state.day1NIA}
                currencySymbol = {this.props.currencySymbol}
                currencyCode = {this.props.currencyCode}
                rentReviewDate={this.props.rentReviewDate}
                rentReviewHeadlinePercent={this.props.rentReviewHeadlinePercent}
                rentReviewPercentFee={this.props.rentReviewPercentFee}
                updateRentReviewValues={this.updateRentReviewValues}
                applyModalValue={this.updateLeaseExpiryDate}
                handleCancelModal={this.handleCancelModal}
              />
            </Portal>
            )}

        </div>
      </div>
    )
  }

  displayRentReviewDate() {
    var theSubInputTextSize = isMobile ? '1.0rem' : '1.4vw'
    var theLabelDateText = isMobile ? 'labelDateTextMobile' : 'labelDateText'
    var options = {
        year: "numeric",
        month: "short",
    }

    return (
      <>
        <div className={theLabelDateText} //"labelCenterText"
        style={{
        cursor:'pointer',
      fontSize: {theSubInputTextSize},}}
        onClick={(e) => {
          const rect = e.target.getBoundingClientRect()
          this.setState({
            left: rect.x + rect.width / 2,
            top: rect.y + window.scrollY,
          })
          this.setState({ rentReviewModalIsOn: !this.state.rentReviewModalIsOn }) // [ 3 ]
        }}
        >
          RR:{this.props.rentReviewDate.toLocaleDateString("en", options) }</div>
      </>
    )
  }
}
export default RentERVBlock
