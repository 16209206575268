import BuySellNarrative from './BuySellNarrative'
import IncomeNarrative from './IncomeNarrative'
import ReturnsNarrative from './ReturnsNarrative'
import DebtNarrative from './DebtNarrative'
import { read, writeFileXLSX }  from "xlsx"
import React from 'react'
import { getAllInfoByISO } from 'iso-country-currency'
import { updateQtrlyTimingInfo, getExitDateFor, getTimedRentAtExit } from './Helpers'

class MainBoard extends React.Component {
  constructor(props) {
    super(props)
    // const worker = new Worker('worker-file.js')
    this.state = {
      appStage: this.props.paramAppStage ? this.props.paramAppStage : 0,
      buyPrice: this.props.paramBuyPrice ? this.props.paramBuyPrice : 0,
      buyCosts: this.props.paramBuyCosts ? this.props.paramBuyCosts : 0,
      buyYield: this.props.paramEntryNIY ? this.props.paramEntryNIY : 0,
      buyMarketYield: this.props.paramEntryRY ? this.props.paramEntryRY : 0,
      sellPrice: this.props.paramSellPrice ? this.props.paramSellPrice : 0,
      sellCosts: this.props.paramSellCosts ? this.props.paramSellCosts : 0,
      sellYield: this.props.paramExitNIY ? this.props.paramExitNIY : 0,
      sellMarketYield: this.props.paramExitRY ? this.props.paramExitRY : 0,
      day1RentPA: this.props.paramPassingRentpa ? this.props.paramPassingRentpa : 0,
      
      seniorLTV: this.props.paramSeniorLTV ? this.props.paramSeniorLTV : 0,
      seniorInterestRatePA: this.props.paramSeniorInterestRatePA ? this.props.paramSeniorInterestRatePA : 0,
      seniorAmortisationPA: this.props.paramSeniorAmortisationPA ? this.props.paramSeniorAmortisationPA : 0,
      seniorArrangementFee: this.props.paramSeniorArrangementFee ? this.props.paramSeniorArrangementFee : 0,

      rentReviewDate: this.props.paramRentReviewTimeStamp ? new Date(Number(this.props.paramRentReviewTimeStamp)) : 0,
      rentReviewHeadlinePercent: this.props.paramRentReviewHeadlinePercent ? this.props.paramRentReviewHeadlinePercent : 95,
      rentReviewPercentFee: this.props.paramRentReviewPercentFee ? this.props.paramRentReviewPercentFee : 5,

      day1MarketRentPA: this.props.paramMarketRentpa ? this.props.paramMarketRentpa : 0,
      day1NIA: this.props.paramArea ? this.props.paramArea : 0,
      holdPeriodYears: this.props.paramHoldPeriod ? this.props.paramHoldPeriod : 0,
      countryName: '',
      countryCodeISO3: '',
      countryCodeISO2: '',
      currencyCode: 'GBP',
      currencySymbol: '£',
      countryTicker: '',
      regionName:'',
      flagAddress: '',
      theMetrics: {
        theIRR: 0,
        theEM: 0,
        theProfit: 0,
        theCoC: 0,
        thePeak: 0,
      },
      theUnitInfo: {
        id: 1,
        // qtrlyCF: [1.1,2.2,3.3],
        
        leaseExpiry: this.props.paramLeaseExpiryTimeStamp ? new Date(Number(this.props.paramLeaseExpiryTimeStamp)) : 0,
        leaseExpiryDilaps: 1,
        leasingVoidMths: this.props.paramVoidMonths ? this.props.paramVoidMonths : 0,
        leasingVoidBizCostsPSUPA: this.props.paramVoidBizRatesPSUpa ? this.props.paramVoidBizRatesPSUpa : 0,
        leasingVoidSCCostsPSUPA: this.props.paramVoidSCcostsPSUpa ? this.props.paramVoidSCcostsPSUpa : 0,
        leasingRentFreeMonths: this.props.paramNewLeaseRentFree ? this.props.paramNewLeaseRentFree : 0,
        
        leadingDays: this.props.paramLeadingDays ? this.props.paramLeadingDays : 0,
        leadingMths: this.props.paramLeadingMths ? this.props.paramLeadingMths : 0,
        startQtr: this.props.paramStartQtr ? this.props.paramStartQtr : 1500,
        startQtrDate: this.props.paramStartQtrDateTimeStamp ? new Date(Number(this.props.paramStartQtrDateTimeStamp)) : new Date(),
        
        trailingDays: this.props.paramTrailingDays ? this.props.paramTrailingDays : 0,
        trailingMths: this.props.paramTrailingMths ? this.props.paramTrailingMths : 0,
        endQtr: this.props.paramEndQtr ? this.props.paramEndQtr : 3000,
        endQtrDate: this.props.paramEndQtrDateTimeStamp ? new Date(Number(this.props.paramEndQtrDateTimeStamp)) : new Date(),

      },
    }

    this.handleAppStage = this.handleAppStage.bind(this)
    this.handleAppStageToNewLeaseEvent = this.handleAppStageToNewLeaseEvent.bind(this)
    this.handleReverseAppStage = this.handleReverseAppStage.bind(this)

    this.handlePurchasePrice = this.handlePurchasePrice.bind(this)
    this.handlePurchaseCosts = this.handlePurchaseCosts.bind(this)
    this.handleBuyYield = this.handleBuyYield.bind(this)
    this.handleSellPrice = this.handleSellPrice.bind(this)
    this.handleSellCosts = this.handleSellCosts.bind(this)
    this.handleSellYield = this.handleSellYield.bind(this)
    this.handleModalValue = this.handleModalValue.bind(this)
    this.exampleExcelFile = this.exampleExcelFile.bind(this)
    this.handleHoldPeriod = this.handleHoldPeriod.bind(this)
    this.handleDay1PassingRent = this.handleDay1PassingRent.bind(this)
    this.handleDay1MarketRent = this.handleDay1MarketRent.bind(this)
    this.handleDay1NIA = this.handleDay1NIA.bind(this)
    this.handleRentReviewValues = this.handleRentReviewValues.bind(this)
    this.handleMetrics = this.handleMetrics.bind(this)
    this.handleReleasingValues = this.handleReleasingValues.bind(this)
    this.handleDebtAssumptions = this.handleDebtAssumptions.bind(this)
  }

  componentDidMount() {
    const axios = require("axios").default
    axios
      .get("https://ipapi.co/json")
      .then((response) => {
        this.setState({countryName: response.data.country_name})
        this.setState({countryCodeISO3: response.data.country_code_iso3})
        this.setState({currencyCode: response.data.currency})
        this.setState({currencySymbol: getAllInfoByISO(response.data.country).symbol})
        this.setState({countryCodeISO2: response.data.country})
        this.setState({countryTicker: response.data.country_code_iso3})
        this.setState({regionName: response.data.region})
        this.setState({flagAddress: 'https://countryflagsapi.com/png/'+response.data.country_code_iso3})

        //console.log(getAllInfoByISO(response.data.country).symbol)
        //console.log(JSON.stringify(response))
      })
      .catch((error) => {
      })
  }

  handleAppStage() {
    //let currentStage = this.state.appStage
    this.setState({ appStage: this.state.appStage + 1 }, () => {
      console.log('STAGE ' + this.state.appStage)
    })
  }

  handleAppStageToNewLeaseEvent(dateValue) {

    this.setState({ appStage: this.state.appStage + 1 }, () => {
      console.log('STAGE ' + this.state.appStage)
    })

    // set-up initial UnitInfo after add Release event
    let newDate = new Date()
    newDate.setHours(0,0,0,0)
    let endHoldingDate = getExitDateFor(newDate, this.state.holdPeriodYears)
    endHoldingDate.setHours(0,0,0,0)
    let newLeasingDate = new Date(dateValue)
    newLeasingDate.setHours(0,0,0,0)

    newLeasingDate = new Date(newLeasingDate.setMonth(newLeasingDate.getMonth() + Number(this.state.theUnitInfo.leasingVoidMths) + Number(this.state.theUnitInfo.leasingRentFreeMonths)))

    let releasingInfo = updateQtrlyTimingInfo(newDate, newLeasingDate, endHoldingDate)

    var updatedUnitInfo = {
      id: 1,
      leaseExpiry: dateValue,
      leaseExpiryDilaps: this.state.theUnitInfo.leaseExpiryDilaps,
      leasingVoidMths: this.state.theUnitInfo.leasingVoidMths,
      leasingVoidBizCostsPSUPA: this.state.theUnitInfo.leasingVoidBizCostsPSUPA,
      leasingVoidSCCostsPSUPA: this.state.theUnitInfo.leasingVoidSCCostsPSUPA,
      leasingRentFreeMonths: this.state.theUnitInfo.leasingRentFreeMonths,

      startQtrDate: releasingInfo.startQtrDate,
      startQtr: releasingInfo.startQtr,
      leadingDays: releasingInfo.leadingDays,
      leadingMths: releasingInfo.leadingMths,

      endQtrDate: releasingInfo.endQtrDate,
      endQtr: releasingInfo.endQtr,
      trailingDays: releasingInfo.trailingDays,
      trailingMths: releasingInfo.trailingMths,
    }
    this.setState({ theUnitInfo: updatedUnitInfo })

    this.handleExitYield(this.state.day1RentPA, this.state.sellPrice, this.state.buyCosts)

  }

  handleReverseAppStage() {
    this.setState({ appStage: this.state.appStage - 1 })
  }

  handlePurchasePrice(newPrice) {
    if (this.state.appStage == 1) {
      this.setState({ appStage: this.state.appStage + 1 })
    }

    this.setState({ buyPrice: newPrice })

    var theRent = (this.state.day1RentPA > 0) ? this.state.day1RentPA : 0
    var theMarketRent = (this.state.day1MarketRentPA > 0) ?  this.state.day1MarketRentPA : 0
    var theBuyCosts = (this.state.buyCosts > 0) ? this.state.buyCosts : 0

    this.handleEntryYield(theRent, newPrice, theBuyCosts)
    this.handleEntryMarketYield(theMarketRent, newPrice, theBuyCosts)
  }

  handlePurchaseCosts(newCosts) {
    this.setState({ buyCosts: Number(newCosts).toFixed(2) })
    if (!isNaN(newCosts)) {
      this.handleEntryYield(this.state.day1RentPA, this.state.buyPrice, newCosts)
      this.handleEntryMarketYield(this.state.day1MarketRentPA, this.state.buyPrice, newCosts)
      this.handleExitYield(this.state.day1RentPA, this.state.sellPrice, newCosts)
      this.handleExitMarketYield(this.state.day1MarketRentPA, this.state.sellPrice, newCosts)
    }
    else {
      this.handleEntryYield(this.state.day1RentPA, this.state.buyPrice, 0)
      this.handleEntryMarketYield(this.state.day1MarketRentPA, this.state.buyPrice, 0)
      this.handleExitYield(this.state.day1RentPA, this.state.sellPrice, 0)
      this.handleExitMarketYield(this.state.day1MarketRentPA, this.state.sellPrice, 0)
    }
  }

  handleSellCosts(newCosts) {
    this.setState({ sellCosts: Number(newCosts).toFixed(2) })
    if (!isNaN(this.state.buyCosts)) {
      this.handleExitYield(this.state.day1RentPA, this.state.sellPrice, this.state.buyCosts)
      this.handleExitMarketYield(this.state.day1MarketRentPA, this.state.sellPrice, this.state.buyCosts)
    }
    else {
      this.handleExitYield(this.state.day1RentPA, this.state.sellPrice, 0)
      this.handleExitMarketYield(this.state.day1MarketRentPA, this.state.sellPrice, 0)
    }
  }

  handleSellPrice(newPrice) {
    if (this.state.appStage == 3) {
      this.setState({ appStage: this.state.appStage + 1 })
    }
    this.setState({ sellPrice: newPrice })

    var theRent = (this.state.day1RentPA > 0) ? this.state.day1RentPA : 0
    var theMarketRent = (this.state.day1MarketRentPA > 0) ?  this.state.day1MarketRentPA : 0
    var theNextBuyerCosts = (this.state.buyCosts > 0) ? this.state.buyCosts : 0

    this.handleExitYield(theRent, newPrice, theNextBuyerCosts)
    this.handleExitMarketYield(theMarketRent, newPrice, theNextBuyerCosts)
  }

  handleBuyYield(newYield) {
    this.setState({ buyYield: Number(newYield).toFixed(2) })
    // this.setState({ buyYield: Number(newYield).toFixed(2) }, () => {
    // console.log(this.state.buyYield, ' = latest Buy Yield')
    // })
  }

  handleSellYield(newYield) {
    this.setState({ sellYield: Number(newYield).toFixed(2) })
    // , () => {
    //   console.log(this.state.sellYield, ' = latest Buy Yield')
    // })
    //this.setState({ sellPrice: this.state.day1RentPA / newYield / (1+this.state.sellCosts) })
  }

  handleHoldPeriod(theYears) {
    this.setState({ holdPeriodYears: parseInt(theYears)})
    if (this.state.appStage == 5) {
      this.setState({ appStage: this.state.appStage + 1 })
    }

    // Update ReleasingInfo to Released info extends for entire hold period
    if (this.state.appStage > 1 && theYears > 0) {
      
      let newDate = new Date()
      newDate.setHours(0,0,0,0)
      let endHoldingDate = getExitDateFor(newDate, parseInt(theYears)) // ESSENTIAL CHANGE IS HERE
      endHoldingDate.setHours(0,0,0,0)
      let newLeasingDate = new Date(this.state.theUnitInfo.leaseExpiry)
      newLeasingDate.setHours(0,0,0,0)
   
      newLeasingDate = new Date(newLeasingDate.setMonth(newLeasingDate.getMonth() + Number(this.state.theUnitInfo.leasingVoidMths) + Number(this.state.theUnitInfo.leasingRentFreeMonths)))
     
      let releasingInfo = updateQtrlyTimingInfo(newDate, newLeasingDate, endHoldingDate)

      var updatedUnitInfo_Update = {
        id: this.state.theUnitInfo.id,
        leaseExpiry: this.state.theUnitInfo.leaseExpiry,
        leaseExpiryDilaps: this.state.theUnitInfo.leaseExpiryDilaps,
        // these are updated:
        leasingVoidMths: this.state.theUnitInfo.leasingVoidMths,
        leasingVoidBizCostsPSUPA: this.state.theUnitInfo.leasingVoidBizCostsPSUPA,
        leasingVoidSCCostsPSUPA: this.state.theUnitInfo.leasingVoidSCCostsPSUPA,
        leasingRentFreeMonths: this.state.theUnitInfo.leasingRentFreeMonths,

        startQtrDate: releasingInfo.startQtrDate,
        startQtr: releasingInfo.startQtr,
        leadingDays: releasingInfo.leadingDays,
        leadingMths: releasingInfo.leadingMths,

        endQtrDate: releasingInfo.endQtrDate,
        endQtr: releasingInfo.endQtr,
        trailingDays: releasingInfo.trailingDays,
        trailingMths: releasingInfo.trailingMths,
      }

      this.setState({ theUnitInfo: updatedUnitInfo_Update } , () => {
        this.handleExitYield(this.state.day1RentPA, this.state.sellPrice, this.state.buyCosts)
      })
      
    }
  }

  handleDay1PassingRent(theRent) {
    if (this.state.appStage == 7) {
      this.setState({ appStage: this.state.appStage + 1 })
    }
    //this.setState({ day1RentPA: theRent })
    this.setState({ day1RentPA: theRent } , () => {
      this.handleEntryYield(theRent, this.state.buyPrice, this.state.buyCosts)
      this.handleExitYield(theRent, this.state.sellPrice, this.state.buyCosts)
    })
  }

  handleDay1MarketRent(theMarketRent) {
    if (this.state.appStage == 9) {
      this.setState({ appStage: this.state.appStage + 1 })
    }

    this.setState({ day1MarketRentPA: theMarketRent } , () => {
      this.handleEntryMarketYield(theMarketRent, this.state.buyPrice, this.state.buyCosts)
      this.handleExitMarketYield(theMarketRent, this.state.sellPrice, this.state.buyCosts)
      this.handleExitYield( this.state.day1RentPA, this.state.sellPrice, this.state.buyCosts)
    })
  }

  handleEntryYield(theRent, thePrice, theCosts) {
    var theBuyYield = theRent / (thePrice * (1+(theCosts/100))) * 100
    this.setState({ buyYield: theBuyYield })
  }

  handleEntryMarketYield(theRent, thePrice, theCosts) {
    var theBuyMarketYield = theRent / (thePrice * (1+(theCosts/100))) * 100
    this.setState({ buyMarketYield: theBuyMarketYield })
  }

  handleExitYield(theRent, thePrice, theCosts) {

    var theTimedExitRent = theRent
    if (this.state.appStage > 10 ) { // Determine if Passing or Rent Review Rent applies at time of Exit
      theTimedExitRent = getTimedRentAtExit(this.state.theUnitInfo, this.state.holdPeriodYears, this.state.day1RentPA, 
                                              this.state.day1MarketRentPA,
                                              this.state.rentReviewDate,
                                              this.state.rentReviewHeadlinePercent) 
    }
    
    var theYield = theTimedExitRent / (thePrice * (1+(theCosts/100))) * 100
    if (!isNaN(theYield)) {
      this.setState({ sellYield: theYield })
    }
    else {
      this.setState({ sellYield: 0 })
    }
  }

  handleExitMarketYield(theRent, thePrice, theCosts) {  
    var theMarketYield = theRent / (thePrice * (1+(theCosts/100))) * 100
    this.setState({ sellMarketYield: theMarketYield })
  }

  // Debt items
  handleDebtAssumptions(seniorLTV, seniorInterestRatePA, seniorArrangementFee) {
    this.setState({ seniorLTV: seniorLTV })
    this.setState({ seniorInterestRatePA: seniorInterestRatePA })
    this.setState({ seniorArrangementFee: seniorArrangementFee })
    console.log("((((( ltv: " + seniorLTV + " rate: " + seniorInterestRatePA + " amort: " + seniorArrangementFee)
  }
  // Re Income Narrative > Passing Rent p.a. block

  handleDay1NIA (theArea) {
    this.setState({ day1NIA: theArea })
  }

  // Re Income Narrative > Market Rent p.a. block
  handleRentReviewValues (theRentReviewInfo) {
    this.setState({ rentReviewDate: theRentReviewInfo.rentReviewDate})
    this.setState({ rentReviewHeadlinePercent: theRentReviewInfo.rentReviewHeadlinePercent})
    this.setState({ rentReviewPercentFee: theRentReviewInfo.rentReviewPercentFee } , () => {

      this.handleExitYield(this.state.day1RentPA, this.state.sellPrice, this.state.buyCosts)
       })

    //If time is before exit, update Sell NIY
  }

  handleMetrics(theMetrics){
    this.setState({ theMetrics: theMetrics})
  }

  handleReleasingValues(theInfo) {
    var updatedUnitInfo = {
      id: 1,
      leaseExpiry: theInfo.leaseExpiry,
      leaseExpiryDilaps: theInfo.leaseExpiryDilaps,
      leasingVoidMths: theInfo.leasingVoidMths,
      leasingVoidBizCostsPSUPA: theInfo.leasingVoidBizCostsPSUPA,
      leasingVoidSCCostsPSUPA: theInfo.leasingVoidSCCostsPSUPA,
      leasingRentFreeMonths: theInfo.leasingRentFreeMonths,

      startQtrDate: theInfo.startQtrDate,
      startQtr: theInfo.startQtr,
      leadingDays: theInfo.leadingDays,
      leadingMths: theInfo.leadingMths,

      endQtrDate: theInfo.endQtrDate,
      endQtr: theInfo.endQtr,
      trailingDays: theInfo.trailingDays,
      trailingMths: theInfo.trailingMths,
    }
   
    this.setState({ theUnitInfo: updatedUnitInfo } , () => {
        this.handleExitYield(this.state.day1RentPA, this.state.sellPrice, this.state.buyCosts)
         })
    
  }

  handleModalValue(newValue) {
    if (this.state.appStage == 1) {
      let currentStage = this.state.appStage
      this.setState({ appStage: this.state.appStage + 1 })
      let thePrice = this.state.buyPrice
    } else if (this.state.appStage == 3) {
      let currentStage = this.state.appStage
      this.setState({ appStage: this.state.appStage + 1 })
    } else if (this.state.appStage == 5) {
      let currentStage = this.state.appStage
      this.setState({ appStage: this.state.appStage + 1 })
    } else if (this.state.appStage > 5) {
      let currentStage = this.state.appStage
      this.setState({ appStage: this.state.appStage + 1 })
    }
  }

  exampleExcelFile() {
    console.log('pressed')
    

    var XLSX = require("xlsx")
    var workbooks = XLSX.utils.book_new()

    var range = {s: {r: 0, c: 0},
                e: {r: 10, c: 10}}

    var worksheet = {}
    var cell = {f: 'A2 + A3'}
    var cellRef = XLSX.utils.encode_cell({r:9, c:9})
    worksheet[cellRef]= cell
    worksheet['!ref'] = XLSX.utils.encode_range(range)

    worksheet = XLSX.utils.aoa_to_sheet([
      ["FindIRR.com Report"],
      ["IRR", "8.6%"],
      ["Profit", "£150,000"],
      ["Multiple", "1.34x"],
      ["Peak", "£150,000"],
      [],
      ["Buy:", "£150", , "NIY"],
      ["Sell:", "£170", , "NIY"],
    ])

    XLSX.utils.book_append_sheet(workbooks, worksheet, "Sheet2")
    workbooks.SheetNames.push('test')
    workbooks.Sheets.test = worksheet
    // workbook.Sheets.findIRR = worksheet
    
    XLSX.writeFile(workbooks, "findirrspread.xlsx")
  }

  render() {
    return (
      <div>
        {/* Deal Type Title */}
        {this.state.appStage >= 12 &&
        <div className="titleWrapper">
          <div className="crossNarrativeContainer">
            <div></div>
          <div className="titleStrategy"></div>
          </div>
        </div>
        }

        <IncomeNarrative
          countryName={this.state.countryName}
          countryCodeISO3={this.state.countryCodeISO3}
          regionName={this.state.regionName}
          currencyCode={this.state.currencyCode}
          currencySymbol={this.state.currencySymbol}
          value={this.state.appStage}
          day1RentPA={this.state.day1RentPA}
          day1NIA={this.state.day1NIA}
          handleDay1NIA={this.handleDay1NIA}
          changeAppStage={this.handleAppStage}
          handleAppStageToNewLeaseEvent={this.handleAppStageToNewLeaseEvent}
          handleDay1PassingRent={this.handleDay1PassingRent}
          day1MarketRentPA={this.state.day1MarketRentPA}
          handleDay1MarketRent={this.handleDay1MarketRent}
          theUnitInfo={this.state.theUnitInfo}
          handleReleasingValues={this.handleReleasingValues}
          handleRentReviewValues={this.handleRentReviewValues}
          holdPeriodYears={this.state.holdPeriodYears}
          rentReviewDate={this.state.rentReviewDate}
          rentReviewHeadlinePercent={this.state.rentReviewHeadlinePercent}
          rentReviewPercentFee={this.state.rentReviewPercentFee}
        />
        <DebtNarrative
          value={this.state.appStage}
          currencySymbol={this.state.currencySymbol}
          seniorLTV={this.state.seniorLTV}
          seniorArrangementFee={this.state.seniorArrangementFee}
          seniorInterestRatePA={this.state.seniorInterestRatePA}
          seniorAmortisationPA={this.state.seniorAmortisationPA}
          handleDebtAssumptions={this.handleDebtAssumptions}
        />
        <BuySellNarrative
          countryName={this.state.countryName}
          countryCodeISO3={this.state.countryCodeISO3}
          regionName={this.state.regionName}
          currencyCode={this.state.currencyCode}
          currencySymbol={this.state.currencySymbol}
          flagAddress={this.state.flagAddress}
          value={this.state.appStage}
          appStage={this.state.appStage}
          entryPrice={this.state.buyPrice}
          buyCosts={this.state.buyCosts}
          buyYield={this.state.buyYield}
          buyMarketYield={this.state.buyMarketYield}
          sellPrice={this.state.sellPrice}
          sellCosts={this.state.sellCosts}
          sellYield={this.state.sellYield}
          sellMarketYield={this.state.sellMarketYield}
          holdPeriodInYears={this.state.holdPeriodYears}
          day1RentPA={this.state.day1RentPA}
          changeAppStage={this.handleAppStage}
          handleReverseAppStage={this.handleReverseAppStage}
          handlePurchasePrice={this.handlePurchasePrice}
          handlePurchaseCosts={this.handlePurchaseCosts}
          handleBuyYield={this.handleBuyYield}
          handleSellPrice={this.handleSellPrice}
          handleSellCosts={this.handleSellCosts}
          handleSellYield={this.handleSellYield}
          handleHoldPeriod={this.handleHoldPeriod}
          day1MarketRentPA={this.state.day1MarketRentPA}
          theUnitInfo={this.state.theUnitInfo}
          rentReviewDate={this.state.rentReviewDate}
          rentReviewHeadlinePercent={this.state.rentReviewHeadlinePercent}
          rentReviewPercentFee={this.state.rentReviewPercentFee}
        />
        <ReturnsNarrative
          countryName={this.state.countryName}
          countryCodeISO3={this.state.countryCodeISO3}
          regionName={this.state.regionName}
          currencyCode={this.state.currencyCode}
          currencySymbol={this.state.currencySymbol}
          day1RentPA={this.state.day1RentPA}
          appStage={this.state.appStage}
          buyPrice={this.state.buyPrice}
          buyCosts={this.state.buyCosts}
          buyYield={this.state.buyYield}
          buyMarketYield={this.state.buyMarketYield}
          sellPrice={this.state.sellPrice}
          sellCosts={this.state.sellCosts}
          sellYield={this.state.sellYield}
          sellMarketYield={this.state.sellMarketYield}
          holdPeriodYears={this.state.holdPeriodYears}
          day1NIA={this.state.day1NIA}
          day1MarketRentPA={this.state.day1MarketRentPA}
          rentReviewDate={this.state.rentReviewDate}
          rentReviewHeadlinePercent={this.state.rentReviewHeadlinePercent}
          rentReviewPercentFee={this.state.rentReviewPercentFee}

          seniorLTV={this.state.seniorLTV}
          seniorArrangementFee={this.state.seniorArrangementFee}
          seniorInterestRatePA={this.state.seniorInterestRatePA}

          theMetrics={this.state.theMetrics}
          handleMetrics={this.handleMetrics}
          theUnitInfo={this.state.theUnitInfo}
          handleUnitInfo={this.handleUnitInfo}
        />
        {/*<img className="countryFlag" src={this.state.flagAddress}></img> */}
        {/*<div className="backgroundSideEmoji">🏢</div> */}
      </div>
    )
  }
}

export default MainBoard
