import './App.css'
import React from 'react'
import DetectCountry from './components/DetectCountry'
import MainBoard from './components/MainBoard'
import coffeeLogo from './images/Buy-DashCalc-A-Coffee.png'
import dashflowLogo from './images/FindIRR-Dashflow-Logo2.png'
import bookmarkLogo from './images/Bookmark-today.png'
//import { DealSettingsProvider } from './components/DealSettingsContext'
import {
  BrowserView,
  MobileView,
  isMobile,
} from 'react-device-detect'
import { useState, useEffect } from 'react'
import { BrowserRouter as Router, Link, useLocation } from 'react-router-dom'
import { queryAllByAltText } from '@testing-library/react'

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

function QueryParamsExample() {
  return (
    <Router>
      <QueryParamsDemo />
    </Router>
  )
}

function useQuery() {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

function QueryParamsDemo() {
  let query = useQuery()

  return (
    <div>
      <MainBoard
        paramBuyPrice={query.get('buyPrice')}
        paramBuyCosts={query.get('buyCosts')}
        paramSellPrice={query.get('sellPrice')}
        paramSellCosts={query.get('sellCosts')}
        paramHoldPeriod={query.get('holdPeriod')}
        paramPassingRentpa={query.get('passingRentpa')}
        paramEntryNIY={query.get('entryNIY')}
        paramEntryRY={query.get('entryRY')}
        paramMarketRentpa={query.get('marketRentpa')}
        paramExitNIY={query.get('exitNIY')}
        paramExitRY={query.get('exitRY')}
        paramArea={query.get('area')}

        paramSeniorLTV={query.get('seniorLTV')}
        paramSeniorInterestRatePA={query.get('seniorInterestRatePA')}
        paramSeniorAmortisationPA={query.get('seniorAmortisationPA')}
        paramSeniorArrangementFee={query.get('seniorArrangementFee')}

        paramRentReviewTimeStamp={query.get('rentReviewTimeStamp')}
        paramRentReviewHeadlinePercent={query.get('rentReviewHeadlinePercent')}
        paramRentReviewPercentFee={query.get('rentReviewPercentFee')}

        paramLeaseExpiryTimeStamp={query.get('leaseExpiryTimeStamp')}
        paramMonthsToExpiry={query.get('monthsToExpiry')} // LeaseExpiry
        paramVoidMonths={query.get('voidMonths')}
        paramVoidBizRatesPSUpa={query.get('voidBizRatesPSUpa')}
        paramVoidSCcostsPSUpa={query.get('voidSCcostsPSUpa')}
        paramNewLeaseRentFree={query.get('newLeaseRentFree')}
        paramAppStage={query.get('appStage')}

        paramLeadingDays={query.get('leadingDays')}
        paramLeadingMths={query.get('leadingMths')}
        paramStartQtr={query.get('startQtr')}
        paramStartQtrDateTimeStamp={query.get('startQtrDateTimeStamp')}
        
        paramTrailingDays={query.get('trailingDays')}
        paramTrailingMths={query.get('trailingMths')}
        paramEndQtr={query.get('endQtr')}
        paramEndQtrDateTimeStamp={query.get('endQtrDateTimeStamp')}

      />
    </div>
  )
}

function App() {
  const width = 250

  const handleClick = () => {
    window.open('https://www.buymeacoffee.com/findirr')
  }

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  )

  // useEffect(() => {
  //   function handleResize() {
  //     setWindowDimensions(getWindowDimensions())
  //   }

  //   window.addEventListener('resize', handleResize)
  //   return () => window.removeEventListener('resize', handleResize)
  // }, [])

  const [finishStatus, setfinishStatus] = useState(false);

const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!finishStatus) {
        if (window.confirm("Do you want to go back and exit this app?")) {
            setfinishStatus(true)
            // your logic
            //props.history.push("/");
            window.history.back()

            //window.history.pushState({name: "browserBack"}, "on browser back click", window.location.href);
        } else {
            window.history.pushState(null, null, window.location.pathname);
            setfinishStatus(false)
        }
    }
}

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);  
    };
  }, []);

  const handleBookmarkClick = () => {
    var pageTitle = document.title
    var pageURL = document.location
    try {
      // Internet Explorer solution
      eval('window.external.AddFa-vorite(pageURL, pageTitle)'.replace(/-/g, ''))
    } catch (e) {
      try {
        // Mozilla Firefox solution
        window.sidebar.addPanel(pageTitle, pageURL, '')
      } catch (e) {
        // Opera solution
        // if (typeof(opera)=="object") {
        //   a.rel="sidebar";
        //   a.title=pageTitle;
        //   a.url=pageURL;
        //   return true;
        // } else {
        // The rest browsers (i.e Chrome, Safari)
        alert(
          'Press ' +
            (navigator.userAgent.toLowerCase().indexOf('mac') != -1
              ? 'Cmd'
              : 'Ctrl') +
            '+D to bookmark this page.',
        )
        // }
      }
    }
    return false
  }

  const CoffeeLogoComponent = () => {
    if (!isMobile) {
      return 'height="40vh"'
    } else {
      return 'height="40vh"'
    }
  }

  var theDivHeader = isMobile ? 'divHeaderMobile' : 'divHeader'

      if ( isMobile )  { 
      return  <> 
            <MobileView>

        
      <div className="App">
            <div className={theDivHeader}>
              <MobileView>
                <span style={{paddingTop: '3px', paddingLeft: '5px'}}>
                  <a style={{ fontSize: '16px', fontWeight: 'bold', }} onClick={()=> window.open("https://www.dashflowcre.com/findirr", "_blank")}>FindIRR </a>
                  <a style={{ fontSize: '12px', fontWeight: 'normal', }} onClick={()=> window.open("https://www.dashflowcre.com", "_blank")}>for real estate. From the creators of </a>
                  <a style={{ fontSize: '12px', fontWeight: 'normal', textDecorationLine: 'underline' }} onClick={()=> window.open("https://www.dashflowcre.com", "_blank")}>/~Dashflow.</a>
                  <a style={{ fontSize: '12px', fontWeight: 'normal', }} onClick={()=> window.open("https://www.dashflowcre.com", "_blank")}> </a>
                  <a style={{ fontSize: '12px', fontWeight: 'normal', textDecorationLine: 'underline'}} onClick={()=> window.open("https://dashflowcre.com/website-terms-conditions/", "_blank")}> T&Cs</a>
                 {/* <img src={dashflowLogo} style={{cursor: 'pointer', paddingLeft: '5px'}} alt="DashflowLogo" height="34px" onClick={()=> window.open("https://www.dashflowcre.com/findirr", "_blank")}/>
                  <div cursor='pointer' style={{ fontSize: '11px', paddingBottom: '12px', paddingLeft: '4px', color: '#4c94ed'}}>
                  Here are <a style={{cursor: 'pointer', fontWeight: 'normal', textDecorationLine: 'underline'}} onClick={()=> window.open("https://dashflowcre.com/website-terms-conditions/", "_blank")}>T&Cs</a>
                  . We take your <a style={{cursor: 'pointer', fontWeight: 'normal', textDecorationLine: 'underline'}} onClick={()=> window.open("https://dashflowcre.com/privacy-policy", "_blank")}>privacy</a> seriously. v0.5.1. More <a style={{cursor: 'pointer', fontWeight: 'normal', textDecorationLine: 'underline'}} onClick={()=> window.open("https://dashflowcre.com/findirr", "_blank")}>info</a>.
                  </div> */}
                </span>
              </MobileView>

              <BrowserView>
              <span>
                <img src={dashflowLogo} style={{cursor: 'pointer', paddingLeft: '5px'}} alt="DashflowLogo" height="34px" onClick={()=> window.open("https://www.dashflowcre.com/findirr", "_blank")}/>
                <div cursor='pointer' style={{ fontSize: '11px', paddingBottom: '12px', paddingLeft: '4px', color: '#4c94ed'}}>
                  Remaining on this site means you accept these <a style={{cursor: 'pointer', fontWeight: 'bolder', textDecorationLine: 'underline'}} onClick={()=> window.open("https://dashflowcre.com/website-terms-conditions/", "_blank")}>T&Cs</a>
                  . We take your <a style={{cursor: 'pointer', fontWeight: 'bolder', textDecorationLine: 'underline'}} onClick={()=> window.open("https://dashflowcre.com/privacy-policy", "_blank")}>privacy</a> seriously. Beta version 0.5.1. Learn about FindIRR <a style={{cursor: 'pointer', fontWeight: 'bolder', textDecorationLine: 'underline'}} onClick={()=> window.open("https://dashflowcre.com/findirr", "_blank")}>here</a>
                </div>
              </span>
              </BrowserView>
            </div>

            <QueryParamsExample />
          </div>
      </MobileView>
        </> }
      else { return <>
      <BrowserView>
      <div className="App">
            <div className="divHeader">
              <span>
                
                <img src={dashflowLogo} style={{cursor: 'pointer', paddingLeft: '5px'}} alt="DashflowLogo"  height="30px" onClick={()=> window.open("https://www.dashflowcre.com/findirr", "_blank")}/>
                <div cursor='pointer' style={{ fontSize: '11px', paddingBottom: '12px', paddingLeft: '4px', color: '#4c94ed'}}>
                  Remaining on this site means you accept these <a style={{cursor: 'pointer', fontWeight: 'bolder', textDecorationLine: 'underline'}} onClick={()=> window.open("https://dashflowcre.com/website-terms-conditions/", "_blank")}>T&Cs</a>
                  . We take your <a style={{cursor: 'pointer', fontWeight: 'bolder', textDecorationLine: 'underline'}} onClick={()=> window.open("https://dashflowcre.com/privacy-policy", "_blank")}>privacy</a> seriously. Beta version 0.5.1. Learn about FindIRR <a style={{cursor: 'pointer', fontWeight: 'bolder', textDecorationLine: 'underline'}} onClick={()=> window.open("https://dashflowcre.com/findirr", "_blank")}>here</a>
                </div>
              </span>
            </div>

            <QueryParamsExample />
            <div className="divFooter">
              <img
                onClick={handleClick}
                src={coffeeLogo}
                height="75vh"
                style={{ cursor: 'pointer' }}
              />
            </div>

            {!isMobile && windowDimensions.width > 525 && (
              <div className="bookmarker">
                <img
                  onClick={handleBookmarkClick}
                  src={bookmarkLogo}
                  alt="bookmarkLogo"
                  height="30px"
                  style={{ cursor: 'pointer' }}
                />
              </div>
            )}
          </div>
      </BrowserView>
        
        </>
      } 



}

export default App