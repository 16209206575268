import React, { useRef } from 'react'
import './../App.css'
import './../AppMobile.css'
import { theDomains } from './zNotes.js'
import emailjs from '@emailjs/browser'
import { isMobile } from 'react-device-detect'
import { DateTime } from 'luxon'
import emojiemail from './../images/emojiemail.png'
import emojiforbidden from  './../images/emojiforbidden.png' 

var stylingObject = {
  input: {
    margin: '2px',
    padding: '5px',
  },
  outerDiv: {
    border: '0px',
  },
  addStyling: {
    border: '1px dashed white',
    margin: '40px',
    borderRadius: '20px',
    padding: '40px',
    lineHeight: '30px',
    fontSize: '20px',
    textAlign: 'center',
    fontWeight: 'bolder',
    cursor: 'pointer',
  },
  btnStyling: {
    backgroundColor: 'white',
    border: '1px solid grey',
    borderRadius: '5px',
    fontSize: '18px',
    margin: '15px',
    paddingLeft: '30px',
    paddingRight: '30px',
    color: '#38336E',
  },
  fieldStyling: {
    borderRadius: '20px',
  },
}

class ModalEmail extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      toEmail: '',
      emailCorrect: false,
      emailNotPermitted: false,
      emailSent: false,
      emailSending: false,

      serviceID: 'service_8z8q7sv',
      templateID: 'template_2zatm8p',
      templateID2: 'template_iwv3v4m', 

      from_name: 'FindIRR Metrics',
      from_username: '',
      to_name: '',
      to_email: '',
      message: this.composeMessage(),
      reply_to: 'hello@findirr.com',
      sign_off: 'Kind regards,',
      text_for_message: this.props.text_for_message,
      irr_comment: this.props.irr_comment,
    }
    this.handleOnChange = this.handleOnChange.bind(this)
    this.submitValueToParent = this.submitValueToParent.bind(this)

    
  }

  handleOnChange(evt) {
    const val = evt.target.value
    this.setState({ toEmail: val })
    this.setState({ from_username: val})
    this.setState({ to_name: val})
    this.setState({ to_email: val})
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    const theMatch = theDomains.find((element) => {
      if (val.includes(element)) {
        return true
      } else {
        return false
      }
    })

    if (re.test(val)) {
      this.setState({ emailCorrect: true })
    } else {
      this.setState({ emailCorrect: false }) // invalid email, maybe show an error to the user.
    }

    if (theMatch) {
      this.setState({ emailNotPermitted: true })
    } else {
      this.setState({ emailNotPermitted: false })
    }
  }

  
  composeMessage() {

    var formatter = new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: this.props.currencyCode,
      maximumFractionDigits: 0,
    })

    var formatter2D = new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: this.props.currencyCode,
      maximumFractionDigits: 2,
    })

    var expiryDate = this.props.theUnitInfo.leaseExpiry.toLocaleString(
      DateTime.DATE_SHORT,
    )
    var fBuyPrice = formatter.format(this.props.buyPrice)
    var fBuyCosts = formatter.format(this.props.buyCosts)
    var fSellPrice = formatter.format(this.props.sellPrice)
    var fSellCosts = formatter.format(this.props.sellCosts)
    var fHoldPeriod = this.props.holdPeriodYears + ' years'
    var fPassingRentPA = formatter.format(this.props.day1RentPA)
    var fMarketingRentPA = formatter.format(this.props.day1MarketRentPA)
    var fAreaNIA = this.props.day1NIA
    var fVoidCostsBiz = formatter2D.format(this.props.theUnitInfo.leasingVoidBizCostsPSUPA) + 'psf'
    var fVoidCostsSC = formatter2D.format(this.props.theUnitInfo.leasingVoidSCCostsPSUPA) + 'psf'
    var fNewLeaseRentFree = this.props.theUnitInfo.leasingRentFreeMonths + ' months'
  
    var theMetricsText = 'Your deal pricing assumptions: <br><br>'
                      && 'Buy price: ' && fBuyPrice && '<br>'
                      && 'Buy costs: ' && fBuyCosts && '<br>'
                      && 'Sell price: ' && fSellPrice && '<br>'
                      && 'Sell costs: ' && fSellCosts && '<br><br>'

                      && 'Hold period: ' && fHoldPeriod && '<br><br>'

                      && 'Passing rent: ' && fPassingRentPA && '<br>'
                      && 'Market rent: ' && fMarketingRentPA && '<br>'
                      && 'Area (NIA): ' && fAreaNIA && '<br><br>'

                      && 'Releasing assumptions:' && '<br>'
                      && 'Lease expiry: ' && expiryDate && '<br>'
                      && 'Void months: ' && this.props.theUnitInfo.leasingVoidMths && ' months<br>'
                      && 'Void costs (business rates): ' && fVoidCostsBiz && '<br>'
                      && 'Void costs (service charges): ' && fVoidCostsSC && '<br>'
                      && 'Rent Free incentive for New Lease: ' && fNewLeaseRentFree && '<br><br>'

                      && 'The calculated returns are: <br>' 
                      && this.props.theReturnComment

    return theMetricsText
  }

  submitValueToParent() {

    this.setState({ emailSending: true })
    emailjs.send(this.state.serviceID, this.state.templateID, this.state, '61hr_5N3-qNGxclYJ').then(
        function (response) {
          console.log('SUCCESS!', response.status, response.text)
          //this.setState({ emailSent: true })
        },
        function (error) {
          console.log('FAILED...', error)
          //this.setState({ emailSending: false })
        },
     )

    setTimeout(function() { //Start the timer
      emailjs.send(this.state.serviceID, this.state.templateID2, this.state, '61hr_5N3-qNGxclYJ') //After 1 second, set render to true
      console.log('email send attempt')
    }.bind(this), 1100)

  }

  handleKeyDown(event) {
    if (event.keyCode === 13) {
      this.submitValueToParent()
    } else if (event.keyCode === 27) {
      this.props.handleCancelModal()
    }
  }

  theButtonsContent() {
    return (
      <>
        {this.state.emailSending && <div>Please check your inbox (and spam folder)...</div>}
        {this.state.emailCorrect &&
          !this.state.emailNotPermitted &&
          !this.state.emailSending &&
          !this.state.emailSent && (
            <button
              onClick={this.submitValueToParent}
              style={stylingObject.btnStyling}
            >
              Send
            </button>
          )}
        
        {this.state.emailSending && 
        <button
        onClick={this.props.handleCancelModal}
        style={stylingObject.btnStyling}
      >
        Close
      </button>
        }
        {!this.state.emailSending && 
        <button
          onClick={this.props.handleCancelModal}
          style={stylingObject.btnStyling}
        >
          Cancel
        </button>
  }
        <br />
      </>
    )
  }

  render() {
    var theFlexParent = isMobile ? 'flexParentMobile' : 'labelAndInput'
    var theLabelLeftText = isMobile ? 'labelLeftTextMobile' : 'labelLeftText'
    var theLabelCenterText = isMobile ? 'labelCenterTextMobile' : 'labelCenterText'
    var theSubInputTextSize = isMobile ? '1.0rem' : '1.5rem'
    var theModal = isMobile ? 'modalMobile' : 'modal'
    return (
      <div className={theModal}>
        <div>{this.props.title}</div>


        {this.state.emailNotPermitted != true && !this.state.emailSending && (
          <div style={{ fontSize: {theSubInputTextSize} }}>
            <br />
            Enter email to share report and link.
          </div>
        )}

        {this.state.emailNotPermitted == true && !this.state.emailSending && (
          <div style={{ fontSize: {theSubInputTextSize}, fontWeight: 'bolder' }}>
            <br />
            Please enter a work email.
          </div>
        )}

        {/* {this.state.emailCorrect == false && !this.state.emailSending &&
          this.state.emailNotPermitted == false && (
            // <div className="backgroundEmoji">💬</div>
          )} */}
        {this.state.emailCorrect && this.state.toEmail != '' && !this.state.emailSending && (
          <img
            className="backgroundEmoji"
            height="90vw"
            src={emojiemail}
          />
          //<div className="backgroundEmoji">📨</div>
        )}
        {this.state.emailNotPermitted == true && !this.state.emailSending && (
          <img className="backgroundEmoji"
          height="90vw"
          src={emojiforbidden}
          />
         // <div className="backgroundEmoji">🚫</div>
        )}

        <div className="labelSmallTextNoBorder"></div>
        {!this.state.emailSending &&
        <div className={theFlexParent}>
          <input
            style={{
              textAlign: 'center',
              width: '100%',
              fontSize: theSubInputTextSize,
              lineHeight: '1.5rem',
              borderRadius: '5px',
              padding: '5px',
              borderWidth: '0px',
            }}
            type="email"
            value={this.state.toEmail}
            onChange={this.handleOnChange}
          />
        </div>
  }
        {/* {this.state.emailNotPermitted == false && this.state.emailCorrect == false && (
          <div style={{fontSize: '1.4vw'}}>
          <br/>
          Please enter an email format. 
        </div>
        )} */}

        <br />
        {this.theButtonsContent()}
        <p></p>
      </div>
    )
  }
}

export default ModalEmail
