import Calendar from 'react-calendar'
import React, { useRef } from 'react'
import './../App.css'
import './../AppMobile.css'
import './../Calendar.css'
import NumberFormat from "react-number-format"
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from 'react-device-detect'
import { DateTime } from 'luxon'

var stylingObject = {
  input: {
    margin: '2px',
    padding: '5px',
  },
  outerDiv: {
    border: '0px',
  },
  addStyling: {
    border: '1px dashed white',
    margin: '40px',
    borderRadius: '20px',
    padding: '40px',
    lineHeight: '30px',
    fontSize: '20px',
    textAlign: 'center',
    fontWeight: 'bolder',
    cursor: 'pointer',
  },
  btnStyling: {
    backgroundColor: 'white',
    border: '1px solid grey',
    borderRadius: '5px',
    fontSize: '18px',
    margin: '15px',
    paddingLeft: '30px',
    paddingRight: '30px',
    color: '#38336E',
  },
  fieldStyling: {
    borderRadius: '20px',
  },
}

class ModalCalendar extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      inputValue: this.props.leaseExpiryDate,
    }
    this.updateInputValue = this.updateInputValue.bind(this)
    this.submitValueToParent = this.submitValueToParent.bind(this)
    this.handleCancelPressed = this.handleCancelPressed.bind(this)
  }

  updateInputValue(theDate) {
    console.log('Cal change: ' + JSON.stringify(theDate))
    this.setState({ inputValue: theDate })
  }

  handleCancelPressed() {
    this.props.handleCancelModal()
  }

  submitValueToParent() {
    this.props.applyModalValue(this.state.inputValue)
  }

  handleKeyDown(event) {
    if (event.keyCode === 13) {
      this.submitValueToParent()
    } else if (event.keyCode === 27) {
      this.reverseTheAppStage()
    }
  }

  theButtonsContent() {
    return (
      <>
        <button
          onClick={this.submitValueToParent}
          style={stylingObject.btnStyling}
        >
          Apply
        </button>
        <button
          onClick={this.props.handleCancelModal}
          style={stylingObject.btnStyling}
        >
          Cancel
        </button>
        <br />
      </>
    )
  }

  render() {

    var theModal = isMobile ? 'modalMobile' : 'calendarmodal'
    var theLabelSmallTextNoBorder = isMobile ? 'labelSmallTextNoBorderMobile' : 'labelSmallTextNoBorder'
    let thePrefix = (this.props.title.includes("Hold")) ? '' : '£'
    let theSuffix = (this.props.title.includes("Hold")) ? ' years' : ''
    return (
      <div className={theModal}>
        {/* <div className="backgroundEmoji">⏳</div> */}
        <div>{this.props.title}</div>
        <div className={theLabelSmallTextNoBorder}>
            Tap the Month to see other months, years, decades.
        </div>
        <div style={{textAlign:'center'}}>
        <Calendar 
          
          onChange={this.updateInputValue}
          value={this.state.inputValue}
          style={{textAlign:'center'}} />
        </div>
        <br/>
        {this.theButtonsContent()}
        <p></p>
      </div>
    )
  }
}

export default ModalCalendar
