import React from 'react'
import './../App.css'
import NumberFormat from 'react-number-format'
import AddBlock from './AddBlock'
import './../AppMobile.css'
import Popup from 'reactjs-popup'
import Portal from './Portal'
import Backdrop from './Backdrop'
import 'reactjs-popup/dist/index.css'
import Modal from './Modal'
import ModalGeneral from './ModalGeneral'
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from 'react-device-detect'

class RentBlock extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      rentLevelpa: this.props.day1RentPA,
      theDay1NIA: this.props.day1NIA,
      rentModalIsOn: false,
      rentPerSquareUnit: 0, // (this.props.day1NIA == 0) ? 0 : Number(this.props.day1RentPA / this.props.day1NIA).toFixed(2),
    }

    this.submitTargetRentToParent = this.submitTargetRentToParent.bind(this)
    this.handleCancelModal = this.handleCancelModal.bind(this)
    this.applyModalAreaAndRentPSU = this.applyModalAreaAndRentPSU.bind(this)
    this.updateRentAndRentPSU = this.updateRentAndRentPSU.bind(this)
  }

  submitTargetRentToParent(theRentPerSqUnit) {
    this.setState({ rentModalIsOn: !this.state.rentModalIsOn })
    this.setState({ rentLevelpa: theRentPerSqUnit * this.state.theDay1NIA })
    this.props.applyModalValue(theRentPerSqUnit * this.state.theDay1NIA)

    // Set this to IncomeNarrative...
  }

  handleCancelModal() {
    this.setState({ rentModalIsOn: !this.state.rentModalIsOn })
  }

  updateRentAndRentPSU(floatValue) {
    this.setState({ rentLevelpa: floatValue }, () => {
      this.props.handleDay1PassingRentPA(floatValue) //this.state.rentLevelpa)
      if (this.props.day1NIA > 0) {
        this.setState({
          rentPerSquareUnit: Number(
            this.rentLevelpa / this.state.theDay1NIA,
          ).toFixed(2),
        })
      }
    })
  }

  applyModalAreaAndRentPSU(floatValue) {
    this.setState({ rentModalIsOn: !this.state.rentModalIsOn })
    this.setState({ theDay1NIA: floatValue }, () => {
      this.props.handleDay1NIA(floatValue) //this.state.rentLevelpa)
      if (this.state.theDay1NIA > 0) {
        this.setState({
          rentPerSquareUnit: Number(
            this.rentLevelpa / this.state.theDay1NIA,
          ).toFixed(2),
        })
      }
    })
  }

  applyModalRentAndRentPSU(floatValue) {
    this.updateAreaAndRentPSU(floatValue)
    this.setState({ rentModalIsOn: !this.state.rentModalIsOn })
  }

  render() {
    var theColumnForIncome = isMobile
      ? 'columnForIncomeMobile'
      : 'columnForIncome'
    var theComponentTitle = isMobile ? 'componentTitleMobile' : 'componentTitle'
    var theSubInputTextSize = isMobile ? '1.0rem' : '1.5vw'
    var theAddItemTextSize =  window.innerWidth < 1081 ? '5px' : '30px';
    var theAddItem = isMobile ? 'addItemMobile' : 'addItem'
    var theFlexParent = isMobile ? 'flexParentMobile' : 'flexParent'
    var theGroupedItems = isMobile ? 'groupedItems' : 'groupedItems'
    var theRentTitle = isMobile ? 'Rent pa' : this.props.title

    return (
      <div>
        <div className={theColumnForIncome}>

          <div className={theFlexParent}>
            <div className={theComponentTitle}>{theRentTitle}</div>
            <div className="inputStyling">
              <NumberFormat
              autoFocus value={this.state.rentLevelpa} inputMode="decimal" thousandSeparator={true} prefix={this.props.currencySymbol}
                onValueChange={(values) => {
                  const { formattedValue, value, floatValue } = values
                    this.updateRentAndRentPSU(floatValue)
                  }}
                style={{
                  width: '90%',
                  backgroundColor: 'solid white',
                  fontSize: theSubInputTextSize,
                  textAlign: 'center',
                  boxSizing: 'border-box',
                  border: '0.5px solid white',
                  borderRadius: '7px',
                  color: 'black',
                  marginLeft: '10px',
                  marginRight: '10px',
                }}
              />
            </div>

            {!(this.props.day1NIA > 0) && (
            <div style={{
                width: '80%',
                backgroundColor: 'solid white',
                fontSize: theSubInputTextSize,
                textAlign: 'center',
                boxSizing: 'border-box',
                border: '0.5px dashed white',
                borderRadius: '10px',
                color: 'white',
                marginLeft: '15px',

              cursor:'pointer'}}
              onClick={(e) => {
                const rect = e.target.getBoundingClientRect()
                this.setState({
                  left: rect.x + rect.width / 2,
                  top: rect.y + window.scrollY,
                })
                this.setState({ rentModalIsOn: !this.state.rentModalIsOn }) // [ 3 ]
              }}
              className={theAddItem}
            >
              + Add Area (sq.ft.)
            </div>
          )}

          {this.state.theDay1NIA > 0 && (
            <div className={theGroupedItems}>
              {this.displayDay1NIAArea(this.props.currencyCode)}
            </div>
          )}

          </div>

          {(this.state.rentModalIsOn==true) && (
            <Portal>
              <Backdrop/>
              <ModalGeneral
                autoFocus
                title="Input the Net Internal Area"
                currencySymbol=''
                applyModalValue={this.applyModalAreaAndRentPSU}
                submitValueToParent={this.applyModalAreaAndRentPSU}
                handleCancelModal={this.handleCancelModal}
              />
            </Portal>
          )}
          

          
        </div>
      </div>
    )
  }

  displayDay1NIAArea(propsCurrencyCode) {
    var theColumnForBuySell = isMobile
      ? 'columnForBuySellMobile'
      : 'columnForBuySell'
    var theComponentTitle = isMobile ? 'componentTitleMobile' : 'componentTitle'
    var theFlexParent = isMobile ? 'flexParentMobile' : 'flexParent'
    var theLabelLeftText = isMobile ? 'labelLeftTextMobile' : 'labelLeftText'
    var theLabelCenterText = isMobile
      ? 'labelCenterTextMobile'
      : 'labelCenterText'
    var theInputTextSize = isMobile ? '9px' : '1.6vw'
    var theSubInputTextSize = isMobile ? '1.0rem' : '1.4vw'
    var theContainer = isMobile ? 'buySellContainerMobile' : 'buySellContainer'
    var theTitleItemLarge = isMobile ? 'titleItemLargeMobile' : 'titleItemLarge'

    var formatter = new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: propsCurrencyCode,
      maximumFractionDigits: 2,
    })

    var theRentPerSqUnit = 0
    if (this.props.day1NIA > 0) {
      theRentPerSqUnit = this.props.day1RentPA / this.props.day1NIA
    }
    theRentPerSqUnit = formatter.format(theRentPerSqUnit)

    return (
      <>
        <div 
        style={{
          //display: 'flex',
          //overflow: 'auto',
          //boxSizing: 'border-box',
          //flexDirection: 'column',
          backgroundColor: 'solid white',
          fontSize: theSubInputTextSize,
          color: 'white',
          width: '70%',
          marginLeft: '5px',
        }}
        
        >{theRentPerSqUnit + ' psf'}</div>
        
        <NumberFormat
          value={this.state.theDay1NIA}
          inputMode="decimal"
          thousandSeparator={true}
          suffix=" sqft"
          placeholder="0"
          onSubmit={(values) => {
            document.body.style.zoom = '100%'
          }}
          onValueChange={(values) => {
            const { formattedValue, value, floatValue } = values
            this.setState({ theDay1NIA: floatValue }, () => {
              this.props.handleDay1NIA(floatValue)
              this.state.rentPerSquareUnit =
                this.props.day1NIA == 0
                  ? 0
                  : Number(this.props.day1RentPA / this.props.day1NIA).toFixed(
                      2,
                    )
            })
          }}
          style={{
            //display: 'flex',
            //overflow: 'auto',
            //boxSizing: 'border-box',
            //flexDirection: 'column',
            backgroundColor: 'solid white',
            fontSize: theSubInputTextSize,
            lineHeight: theSubInputTextSize,
            textAlign: 'center',
            border: '0.5px solid white',
            borderRadius: '10px',
            color: 'black',
            width: '70%',
            //float: 'center',
            margin: '0px',
          }}
        />
      </>
    )
  }
}
export default RentBlock
