import React, { useRef } from 'react'
import './../App.css'
import './../AppMobile.css'
import NumberFormat from 'react-number-format'
import { isMobile } from 'react-device-detect'
import emojitarget from './../images/emojitarget.png'

var stylingObject = {
  input: {
    margin: '2px',
    padding: '5px',
  },
  outerDiv: {
    border: '0px',
  },
  addStyling: {
    border: '1px dashed white',
    margin: '40px',
    borderRadius: '20px',
    padding: '40px',
    lineHeight: '30px',
    fontSize: '20px',
    textAlign: 'center',
    fontWeight: 'bolder',
    cursor: 'pointer',
  },
  btnStyling: {
    backgroundColor: 'white',
    border: '1px solid grey',
    borderRadius: '5px',
    fontSize: '18px',
    margin: '15px',
    paddingLeft: '30px',
    paddingRight: '30px',
    color: '#38336E',
  },
  fieldStyling: {
    borderRadius: '20px',
  },
}

class ModalValueForTarget extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      inputValue: '',
      theRent: (!isNaN(this.props.rentPA)) ? this.props.rentPA : 0,
      theCosts: (!isNaN(this.props.buyOrSellCosts)) ? this.props.buyOrSellCosts : 0,
    }
    this.updateInputValue = this.updateInputValue.bind(this)
    this.submitPriceResultToParent = this.submitPriceResultToParent.bind(this)
  }

  updateInputValue(evt) {
    const val = evt.target.value
    this.setState({ inputValue: val })
  }

  submitPriceResultToParent() {

    let thePrice = this.state.theRent / (this.state.inputValue / 100) / (1 + this.props.buyCosts / 100)
    thePrice = thePrice.toFixed(0)
    this.props.applyModalValue(thePrice)
  }

  handleKeyDown(event) {
    if (event.keyCode === 13) {
      //console.log('Enter key pressed')
      this.submitPriceResultToParent()
    } else if (event.keyCode === 27) {
      this.reverseTheAppStage()
    }
  }

  render() {
    
    var theModal = isMobile ? 'modalMobile' : 'modal'

    let theSuffix ='%'
    
    return (
      <div className={theModal}>
        {/* <div className="backgroundEmoji">🎯</div> */}
        <img
            className="backgroundEmoji"
            height="90vw"
            src={emojitarget}
          />
        <p>{this.props.title}</p>
        <fieldset className="form-Fieldset" style={stylingObject.fieldStyling}>
          <label className="form-FieldLabel" style={stylingObject.fieldStyling}>
            <NumberFormat
              value={this.state.inputValue}
              onValueChange={(values) => {
                const { formattedValue, value, floatValue } = values
                this.setState({ inputValue: floatValue }, () => {
                  //this.props.applyModalValue(this.state.inputValue)
                })
                // do something with floatValue
              }}
              onKeyDown={(evt) => this.handleKeyDown(evt)}
              type= {"Decimal"}
              inputMode={"Decimal"}
              suffix={theSuffix}
              placeholder={"0.00%"}
              style={{ border: '0px', fontSize: '22px', textAlign: 'center' }}
            />
          </label>
        </fieldset>

        <br />
        <button
          onClick={this.submitPriceResultToParent}
          style={stylingObject.btnStyling}
        >
          Apply
        </button>
        <button
          onClick={this.props.handleCancelButton}
          style={stylingObject.btnStyling}
        >
          Cancel
        </button>
        <br />
        <p></p>
      </div>
    )
  }
}
export default ModalValueForTarget
