import React from 'react'
import Returns from './Returns'
import './../App.css'
import './../AppMobile.css'
import Modal from './Modal'
import Backdrop from './Backdrop'
import ModalEmail from './ModalEmail'
import Portal from './Portal'
import { DateTime } from 'luxon'
import { getExitDateFor, getBaseRentToLEXqtrlyArray, calculateQtrlyArrayFromInfo, updateQtrlyTimingInfo } from './Helpers'

import ReactRotatingText from 'react-rotating-text'
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from 'react-device-detect'
import { CenturyView } from 'react-calendar'

class ReturnsNarrative extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      theComment: "Hello! Let's cash flow your idea.",
      LOW_RATE: 0.0001,
      HIGH_RATE: 5.0,
      MAX_ITERATION: 20,
      PRECISION_REQ: 0.0000001,
      IRR: 0,
      emailModalIsOn: 0,
      textToCopy: '',
      textForMessage: '',
      irr_comment: '',
      leaseExpiry: this.props.theUnitInfo.leaseExpiry,
      latestCF: [0],
    }
    this.handleOpenEmailModal = this.handleOpenEmailModal.bind(this)
    this.handleCancelEmailModal = this.handleCancelEmailModal.bind(this)
    this.composeMessage = this.composeMessage.bind(this)
    this.getTextToCopy = this.getTextToCopy.bind(this)
    this.getReturnCommentsForEmail = this.getReturnCommentsForEmail.bind(this)
  }

  getTextToCopy() {

    console.log('getTextToCopy seniorLTV ', this.props.seniorLTV)

    let debtText = (this.props.seniorLTV > 0 ? (
      '&seniorLTV='+this.props.seniorLTV
      +'&seniorInterestRatePA='+this.props.seniorInterestRatePA
      +'&seniorArrangementFee='+this.props.seniorArrangementFee
    ) : '')

    let rentReviewText = (this.props.rentReviewDate > 0 ? (
      '&rentReviewTimeStamp='+this.props.rentReviewDate.getTime()
      +'&rentReviewHeadlinePercent='+this.props.rentReviewHeadlinePercent
      +'&rentReviewPercentFee='+this.props.rentReviewPercentFee
    ) : '')
    
    return 'http://www.findirr.com/?buyPrice='+this.props.buyPrice
    +'&sellPrice='+this.props.sellPrice
    +'&buyCosts='+this.props.buyCosts
    +'&sellCosts='+this.props.sellCosts
    +'&holdPeriod='+this.props.holdPeriodYears
    +'&passingRentpa='+this.props.day1RentPA
    +'&entryNIY='+this.props.buyYield
    +'&entryRY='+this.props.buyMarketYield
    +'&marketRentpa='+this.props.day1MarketRentPA
    +'&exitNIY='+this.props.sellYield
    +'&exitRY='+this.props.sellMarketYield
    +'&area='+this.props.day1NIA
    +debtText
    +rentReviewText
    +'&leaseExpiryTimeStamp='+this.props.theUnitInfo.leaseExpiry.getTime()
    +'&voidMonths=' + this.props.theUnitInfo.leasingVoidMths
    +'&voidBizRatesPSUpa='+ this.props.theUnitInfo.leasingVoidBizCostsPSUPA
    +'&voidSCcostsPSUpa='+ this.props.theUnitInfo.leasingVoidSCCostsPSUPA
    +'&newLeaseRentFree='+ this.props.theUnitInfo.leasingRentFreeMonths
    +'&appStage='+ this.props.appStage

    +'&leadingDays='+ this.props.theUnitInfo.leadingDays
    +'&leadingMths='+ this.props.theUnitInfo.leadingMths
    +'&startQtr='+ this.props.theUnitInfo.startQtr
    +'&startQtrDateTimeStamp='+ this.props.theUnitInfo.startQtrDate.getTime()
    +'&trailingDays='+ this.props.theUnitInfo.trailingDays
    +'&trailingMths='+ this.props.theUnitInfo.trailingMths
    +'&endQtr='+ this.props.theUnitInfo.endQtr
    +'&endQtrDateTimeStamp='+ this.props.theUnitInfo.endQtrDate.getTime()

  }

  handleOpenEmailModal() {
    this.setState({ emailModalIsOn: true })
  }

  handleCancelEmailModal() {
    this.setState({ emailModalIsOn: 0 })
  }

  returnProfitComment() {
    if (
      this.props.appStage > 2 &&
      this.props.buyPrice > 0 &&
      this.props.sellPrice > 0
    ) {
      var theSellPrice = this.props.sellPrice
      var profit = 0
      var accumulative = 0
      var peak = 0
      var multiple = 0

      var theLabelCenterText = isMobile ? "labelCenterTextMobile" : "labelCenterText";


      var formatter = new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: this.props.currencyCode, //'GBP',
        maximumFractionDigits: 0,
      })

      if (isNaN(profit)) {
        return <></>
      }
      var rentpa = this.props.day1RentPA
      if (isNaN(rentpa) || rentpa == '') {
        rentpa = 0
      }
      var holdPeriod = this.props.holdPeriodYears
      if (isNaN(holdPeriod)) {
        holdPeriod = 0
      }

      var latestReturns = 0

      /* Releasing cash flow */
      var qtrlyReleasingCF = []

      if (this.props.appStage > 10) {
          qtrlyReleasingCF = calculateQtrlyArrayFromInfo( this.props.theUnitInfo.leadingDays, this.props.theUnitInfo.leadingMths, this.props.theUnitInfo.startQtr,
          this.props.theUnitInfo.trailingDays, this.props.theUnitInfo.trailingMths, this.props.theUnitInfo.endQtr,
          this.props.holdPeriodYears, this.props.day1MarketRentPA)
      }

      /* Calculate current income */
      
      let endHoldingDate = getExitDateFor(new Date(), this.props.holdPeriodYears)
      // Until at Stage 12, expiryDate should be holdPeriod.
      var expiryDate = new Date(this.props.theUnitInfo.leaseExpiry)
      if ( this.props.appStage < 12 ) {
        expiryDate = getExitDateFor(new Date(), this.props.holdPeriodYears)
      }
      var rentReviewDate = new Date(this.props.rentReviewDate)
      let rentReviewHeadlinePercent = this.props.rentReviewHeadlinePercent / 100
      let rentReviewPercentFee = this.props.rentReviewPercentFee / 100

      expiryDate.setHours(0,0,0,0)
      rentReviewDate.setHours(0,0,0,0)
      endHoldingDate.setHours(0,0,0,0)
      let day1Date = new Date()
      day1Date.setHours(0,0,0,0)

      // Adjust Contracted Expiry Date if there is a Rent Review Date
      var earlierOfExpiryOrRentReviewDate = (this.props.rentReviewDate > 0 && this.props.rentReviewDate > day1Date) ? rentReviewDate : expiryDate
      var qtrlyContractedReviewedCF = []
      var contractedReviewedIncomeInfo = ""

      if (this.props.rentReviewDate > 0 && this.props.rentReviewDate > day1Date && this.props.rentReviewDate <= expiryDate) {
        contractedReviewedIncomeInfo = updateQtrlyTimingInfo(day1Date, this.props.rentReviewDate, expiryDate)
        qtrlyContractedReviewedCF = calculateQtrlyArrayFromInfo(contractedReviewedIncomeInfo.leadingDays, contractedReviewedIncomeInfo.leadingMths, contractedReviewedIncomeInfo.startQtr,
          contractedReviewedIncomeInfo.trailingDays, contractedReviewedIncomeInfo.trailingMths, contractedReviewedIncomeInfo.endQtr,
          this.props.holdPeriodYears, this.props.day1MarketRentPA * rentReviewHeadlinePercent)
      }
    
      console.log("qtrlyContractedReviewCF: " + qtrlyContractedReviewedCF)
      
      let currentContractedIncomeInfo = updateQtrlyTimingInfo(day1Date,day1Date, earlierOfExpiryOrRentReviewDate)

      var qtrlyContractedCF = []
      qtrlyContractedCF = calculateQtrlyArrayFromInfo(0,3,1,
        currentContractedIncomeInfo.trailingDays, currentContractedIncomeInfo.trailingMths, currentContractedIncomeInfo.endQtr,
        this.props.holdPeriodYears, this.props.day1RentPA)

        console.log("qtrlyContracted___CF: " + qtrlyContractedCF)
      // Organise void costs
      var qtrlyVoidCF = []
      if (this.props.day1NIA > 0 && this.props.appStage > 11) {
        expiryDate = new Date(this.props.theUnitInfo.leaseExpiry)
        let voidCostsPSU = -Number(this.props.theUnitInfo.leasingVoidBizCostsPSUPA) - Number(this.props.theUnitInfo.leasingVoidSCCostsPSUPA)
        let newLeasingDate = new Date(expiryDate)
        newLeasingDate.setMonth(newLeasingDate.getMonth() + Number(this.props.theUnitInfo.leasingVoidMths))
        let voidCostingInfo = updateQtrlyTimingInfo(day1Date, expiryDate, newLeasingDate)
        qtrlyVoidCF = calculateQtrlyArrayFromInfo(voidCostingInfo.leadingDays, voidCostingInfo.leadingMths, voidCostingInfo.startQtr,
          voidCostingInfo.trailingDays, voidCostingInfo.trailingMths, voidCostingInfo.endQtr, this.props.holdPeriodYears, voidCostsPSU * this.props.day1NIA)  
        }

      var qtrlyCF = []
      var theBuyCosts = !isNaN(this.props.buyCosts) ? this.props.buyCosts / 100 : 0
      var theSellCosts = !isNaN(this.props.sellCosts) ? this.props.sellCosts / 100 : 0

      /* Prepare Day-1 Array Item*/
      qtrlyCF[0] = -this.props.buyPrice * (1 + theBuyCosts) + 
                  (this.props.seniorLTV > 0 ? this.props.seniorLTV/100 * this.props.buyPrice * (1-this.props.seniorArrangementFee/100) : 0)
      peak = qtrlyCF[0]
      accumulative = qtrlyCF[0]
      profit = qtrlyCF[0]

      /* Profit operates as an Accumulative during the loop */
      for (var i = 1; i < this.props.holdPeriodYears * 4 + 1; i++) {
        if (i == this.props.holdPeriodYears * 4) {
            qtrlyCF[i] = theSellPrice * (1 - theSellCosts) - 
            (this.props.seniorLTV > 0 ? this.props.seniorLTV/100 * this.props.buyPrice : 0)
          if (!isNaN(qtrlyContractedCF[i-1])) {
            qtrlyCF[i] = qtrlyCF[i] + qtrlyContractedCF[i-1] 
          }
          if (!isNaN(qtrlyContractedReviewedCF[i-1])) {
            qtrlyCF[i] = qtrlyCF[i] + qtrlyContractedReviewedCF[i-1]
          }
          if (!isNaN(qtrlyReleasingCF[i-1])) {
            qtrlyCF[i] = qtrlyCF[i] + qtrlyReleasingCF[i-1] 
          }
          if (!isNaN(qtrlyVoidCF[i-1])) {
            qtrlyCF[i] = qtrlyCF[i] + qtrlyVoidCF[i-1] 
          }
          if(this.props.seniorLTV > 0) {
            qtrlyCF[i] = qtrlyCF[i] - 
              (this.props.seniorLTV > 0 ? this.props.seniorLTV/100 * this.props.buyPrice * this.props.seniorInterestRatePA/100/4 : 0)
          }
          //console.log("price:"+theSellPrice+" SellCosts: " + theSellCosts + " Rentpa: " + rentpa)
        } else {
          qtrlyCF[i] = 0
          if (!isNaN(qtrlyContractedCF[i-1])) {
            qtrlyCF[i] = qtrlyCF[i] + qtrlyContractedCF[i-1]
          }
          if (!isNaN(qtrlyContractedReviewedCF[i-1])) {
            qtrlyCF[i] = qtrlyCF[i] + qtrlyContractedReviewedCF[i-1]
          }
          if (!isNaN(qtrlyReleasingCF[i-1])) {
            qtrlyCF[i] = qtrlyCF[i] + qtrlyReleasingCF[i-1] 
          }
          if (!isNaN(qtrlyVoidCF[i-1])) {
            qtrlyCF[i] = qtrlyCF[i] + qtrlyVoidCF[i-1]
          }
          if(this.props.seniorLTV > 0) {
            qtrlyCF[i] = qtrlyCF[i] - 
              (this.props.seniorLTV > 0 ? this.props.seniorLTV/100 * this.props.buyPrice * this.props.seniorInterestRatePA/100/4 : 0)
          }
        }

        accumulative = accumulative + qtrlyCF[i]

        if (peak > accumulative) {
          peak = accumulative
        }
        profit = profit + qtrlyCF[i]
      }

      this.state.latestCF = qtrlyCF


      if (profit > 0) {
        multiple = profit / -peak + 1
        multiple = multiple.toFixed(2)
      }

      if ( this.props.appStage == 4 && this.props.holdPeriodYears == 0 ) {
        profit = this.props.sellPrice * (1+theBuyCosts) - this.props.buyPrice * (1+theSellCosts)
        multiple = Number((this.props.sellPrice * (1+theSellCosts)) / (this.props.buyPrice * (1+theBuyCosts))).toFixed(2)
      }


      var theContainerForMetrics = isMobile ? 'returnNarrativeMobile' : 'buySellContainer'
      var theColumnForMetric = isMobile ? 'columnForMetricMobile' : 'columnForMetric'

      if (profit > 0 && this.props.appStage > 11) {
        var latestReturns = this.theReturnsFor(qtrlyCF)
        return (
          <>
            <BrowserView>
            <div className={theContainerForMetrics}>
              <BrowserView>
              <div className="titleItemLarge">Metrics</div>
              </BrowserView>
              <div className={theColumnForMetric}>{latestReturns}% IRR</div>
              <div className={theColumnForMetric}>{formatter.format(profit)} Profit</div>
              <div className={theColumnForMetric}>{multiple}x E.M.</div>
            </div>
            </BrowserView>

            <MobileView>
           
            <div className="returnNarrativeMobilePinnedToTop">
              
                <div className={theColumnForMetric}>{latestReturns}% IRR</div>
                <div className={theColumnForMetric}>{formatter.format(profit)} Profit</div>
                <div className={theColumnForMetric}>{multiple}x E.M.</div>
              
            </div>
        
            </MobileView>

          </>
        )
      } else if (profit > 0 && this.props.appStage >= 6) {
        var latestReturns = this.theReturnsFor(qtrlyCF)
        return (
          <>
          <div className={theLabelCenterText}>
                IRR is {latestReturns}%. Profit is {formatter.format(profit)}{' '}
                and {multiple}x Equity Multiple.
          </div>
          </>
        )
      } else if (profit > 0 && this.props.appStage == 4) {
        return (
          <div className={theLabelCenterText}>
            Profit is {formatter.format(profit)} and {multiple}x Equity
            Multiple.
          </div>
        )
      } else if (profit == 0 && this.props.appStage == 4) {
        <div className={theLabelCenterText}>
        return <>Profit is {formatter.format(profit)}.</>
        </div>
      } else if (profit == 0 && isNaN(this.props.holdPeriodYears)) {
        return (
        <div className={theLabelCenterText}>
        <>Profit is {formatter.format(profit)}.</>
        </div>
        )
      } else if (!isNaN(profit) && this.props.appStage < 12) {
        return (
        <>
          <div className={theLabelCenterText}>
          Loss is {formatter.format(profit)}.
          </div>
        </>
        )
      } else if (!isNaN(profit) && this.props.appStage >= 12) {
        return (
          <>
          <div className="buySellContainer">
            <div className="titleItemLarge">Metrics</div>
            <div className="columnForMetric"></div>
            <div className="columnForMetric">Loss is {formatter.format(profit)}.</div>
            <div className="columnForMetric"></div>
          </div>
          </>
        )
      }

      // TODO: show peak euqity when buy Costs have been added
    }
  }

  getReturnCommentsForEmail() {

    
    if (
      this.props.appStage > 2 &&
      this.props.buyPrice > 0 &&
      this.props.sellPrice > 0
    ) {
      var theSellPrice = this.props.sellPrice
      var profit = 0
      var accumulative = 0
      var peak = 0
      var multiple = 0

      var formatter = new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: this.props.currencyCode, //'GBP',
        maximumFractionDigits: 0,
      })

      if (isNaN(profit)) {
        return <></>
      }
      var rentpa = this.props.day1RentPA
      if (isNaN(rentpa) || rentpa == '') {
        rentpa = 0
      }
      var holdPeriod = this.props.holdPeriodYears
      if (isNaN(holdPeriod)) {
        holdPeriod = 0
      }

      var latestReturns = 0

      /* Releasing cash flow */
      var qtrlyReleasingCF = []

      if (this.props.appStage > 10) {
          qtrlyReleasingCF = calculateQtrlyArrayFromInfo( this.props.theUnitInfo.leadingDays, this.props.theUnitInfo.leadingMths, this.props.theUnitInfo.startQtr,
          this.props.theUnitInfo.trailingDays, this.props.theUnitInfo.trailingMths, this.props.theUnitInfo.endQtr,
          this.props.holdPeriodYears, this.props.day1MarketRentPA)
      }

      /* Calculate current income */
      
      let endHoldingDate = getExitDateFor(new Date(), this.props.holdPeriodYears)
      // Until at Stage 12, expiryDate should be holdPeriod.
      var expiryDate = new Date(this.props.theUnitInfo.leaseExpiry)
      if ( this.props.appStage < 12 ) {
        expiryDate = getExitDateFor(new Date(), this.props.holdPeriodYears)
      }
      var rentReviewDate = new Date(this.props.rentReviewDate)
      let rentReviewHeadlinePercent = this.props.rentReviewHeadlinePercent / 100
      let rentReviewPercentFee = this.props.rentReviewPercentFee / 100

      expiryDate.setHours(0,0,0,0)
      rentReviewDate.setHours(0,0,0,0)
      endHoldingDate.setHours(0,0,0,0)
      let day1Date = new Date()
      day1Date.setHours(0,0,0,0)

      // Adjust Contracted Expiry Date if there is a Rent Review Date
      var earlierOfExpiryOrRentReviewDate = (this.props.rentReviewDate > 0 && this.props.rentReviewDate > day1Date) ? rentReviewDate : expiryDate
      var qtrlyContractedReviewedCF = []
      var contractedReviewedIncomeInfo = ""

      if (this.props.rentReviewDate > 0 && this.props.rentReviewDate > day1Date && this.props.rentReviewDate <= expiryDate) {
        contractedReviewedIncomeInfo = updateQtrlyTimingInfo(day1Date, this.props.rentReviewDate, expiryDate)
        qtrlyContractedReviewedCF = calculateQtrlyArrayFromInfo(contractedReviewedIncomeInfo.leadingDays, contractedReviewedIncomeInfo.leadingMths, contractedReviewedIncomeInfo.startQtr,
          contractedReviewedIncomeInfo.trailingDays, contractedReviewedIncomeInfo.trailingMths, contractedReviewedIncomeInfo.endQtr,
          this.props.holdPeriodYears, this.props.day1MarketRentPA * rentReviewHeadlinePercent)
      }

      let currentContractedIncomeInfo = updateQtrlyTimingInfo(day1Date,day1Date, earlierOfExpiryOrRentReviewDate)

      var qtrlyContractedCF = []
      qtrlyContractedCF = calculateQtrlyArrayFromInfo(0,3,1,
        currentContractedIncomeInfo.trailingDays, currentContractedIncomeInfo.trailingMths, currentContractedIncomeInfo.endQtr,
        this.props.holdPeriodYears, this.props.day1RentPA)

      // Organise void costs
      var qtrlyVoidCF = []
      if (this.props.day1NIA > 0 && this.props.appStage > 11) {
        expiryDate = new Date(this.props.theUnitInfo.leaseExpiry)
        let voidCostsPSU = -Number(this.props.theUnitInfo.leasingVoidBizCostsPSUPA) - Number(this.props.theUnitInfo.leasingVoidSCCostsPSUPA)
        let newLeasingDate = new Date(expiryDate)
        newLeasingDate.setMonth(newLeasingDate.getMonth() + Number(this.props.theUnitInfo.leasingVoidMths))
        let voidCostingInfo = updateQtrlyTimingInfo(day1Date, expiryDate, newLeasingDate)
        qtrlyVoidCF = calculateQtrlyArrayFromInfo(voidCostingInfo.leadingDays, voidCostingInfo.leadingMths, voidCostingInfo.startQtr,
          voidCostingInfo.trailingDays, voidCostingInfo.trailingMths, voidCostingInfo.endQtr, this.props.holdPeriodYears, voidCostsPSU * this.props.day1NIA)  
        }

      var qtrlyCF = []
      var theBuyCosts = !isNaN(this.props.buyCosts) ? this.props.buyCosts / 100 : 0
      var theSellCosts = !isNaN(this.props.sellCosts) ? this.props.sellCosts / 100 : 0

      /* Prepare Day-1 Array Item*/
      qtrlyCF[0] = -this.props.buyPrice * (1 + theBuyCosts) +
          (this.props.seniorLTV > 0 ? this.props.seniorLTV/100 * this.props.buyPrice * (1-this.props.seniorArrangementFee/100) : 0)

      peak = qtrlyCF[0]
      accumulative = qtrlyCF[0]
      profit = qtrlyCF[0]

      /* Profit operates as an Accumulative during the loop */
      for (var i = 1; i < this.props.holdPeriodYears * 4 + 1; i++) {
        if (i == this.props.holdPeriodYears * 4) {
            qtrlyCF[i] = theSellPrice * (1 - theSellCosts) - 
            (this.props.seniorLTV > 0 ? this.props.seniorLTV/100 * this.props.buyPrice : 0)
          if (!isNaN(qtrlyContractedCF[i-1])) {
            qtrlyCF[i] = qtrlyCF[i] + qtrlyContractedCF[i-1]
          }
          if (!isNaN(qtrlyContractedReviewedCF[i-1])) {
            qtrlyCF[i] = qtrlyCF[i] + qtrlyContractedReviewedCF[i-1]
          }
          if (!isNaN(qtrlyReleasingCF[i-1])) {
            qtrlyCF[i] = qtrlyCF[i] + qtrlyReleasingCF[i-1]
          }
          if (!isNaN(qtrlyVoidCF[i-1])) {
            qtrlyCF[i] = qtrlyCF[i] + qtrlyVoidCF[i-1]
          }
          if(this.props.seniorLTV > 0) {
            qtrlyCF[i] = qtrlyCF[i] - 
              (this.props.seniorLTV > 0 ? this.props.seniorLTV/100 * this.props.buyPrice * this.props.seniorInterestRatePA/100/4 : 0)
          }

          //console.log("price:"+theSellPrice+" SellCosts: " + theSellCosts + " Rentpa: " + rentpa)
        } else {
          qtrlyCF[i] = 0
          if (!isNaN(qtrlyContractedCF[i-1])) {
            qtrlyCF[i] = qtrlyCF[i] + qtrlyContractedCF[i-1]
          }
          if (!isNaN(qtrlyContractedReviewedCF[i-1])) {
            qtrlyCF[i] = qtrlyCF[i] + qtrlyContractedReviewedCF[i-1]
          }
          if (!isNaN(qtrlyReleasingCF[i-1])) {
            qtrlyCF[i] = qtrlyCF[i] + qtrlyReleasingCF[i-1]
          }
          if (!isNaN(qtrlyVoidCF[i-1])) {
            qtrlyCF[i] = qtrlyCF[i] + qtrlyVoidCF[i-1]
          }
          if(this.props.seniorLTV > 0) {
            qtrlyCF[i] = qtrlyCF[i] - 
              (this.props.seniorLTV > 0 ? this.props.seniorLTV/100 * this.props.buyPrice * this.props.seniorInterestRatePA/100/4 : 0)
          }
        }

        accumulative = accumulative + qtrlyCF[i]

        if (peak > accumulative) {
          peak = accumulative
        }
        profit = profit + qtrlyCF[i]
      }

      console.log("EmailTotaC: " + qtrlyCF)
      
      if (profit > 0) {
        multiple = profit / -peak + 1
        multiple = multiple.toFixed(2)
      }

      var latestReturns = this.theReturnsFor(qtrlyCF)
      let returnsComment = "IRR is " + latestReturns + "%. Profit is " + formatter.format(profit)
      + " and " + multiple + "x Equity Multiple."
      let irr_comment_temp = latestReturns + '% IRR'

      this.setState({ irr_comment: irr_comment_temp}, () => {
        this.composeMessage(returnsComment, irr_comment_temp)
      })
    }
  }

  composeMessage(returnsComment, irr_comment) {

    var formatter = new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: this.props.currencyCode,
      maximumFractionDigits: 0,
    })

    var formatter2D = new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: this.props.currencyCode,
      maximumFractionDigits: 2,
    })

    let displayExpiryDate = this.props.theUnitInfo.leaseExpiry.toLocaleDateString('en-GB', {
      month: 'short', year: 'numeric', day: 'numeric',
    }).replace(/ /g, '-');

    var fBuyPrice = formatter.format(this.props.buyPrice)
    var fBuyCosts = Number(this.props.buyCosts).toFixed(2) + '%'
    let fEntryNIY = Number(this.props.buyYield).toFixed(2) + '%'
    let fEntryRY = Number(this.props.buyMarketYield).toFixed(2) + '%'
    var fSellPrice = formatter.format(this.props.sellPrice)
    var fSellCosts = Number(this.props.sellCosts).toFixed(2) + '%'
    let fExitNIY = Number(this.props.sellYield).toFixed(2) + '%'
    let fExitRY = Number(this.props.sellMarketYield).toFixed(2) + '%'

    let fSeniorLTV = Number(this.props.seniorLTV).toFixed(2) + '%'
    let fSeniorArrangementFee = Number(this.props.seniorArrangementFee).toFixed(2) + '%'
    let fSeniorInterestRatePA = Number(this.props.seniorInterestRatePA).toFixed(2) + '%'

    var fHoldPeriod = this.props.holdPeriodYears + ' years'
    var fPassingRentPA = formatter.format(this.props.day1RentPA)
    var fMarketingRentPA = formatter.format(this.props.day1MarketRentPA)
    var fAreaNIA = this.props.day1NIA + ' sq ft'
    var fVoidCostsBiz = formatter2D.format(this.props.theUnitInfo.leasingVoidBizCostsPSUPA) + 'psf'
    var fVoidCostsSC = formatter2D.format(this.props.theUnitInfo.leasingVoidSCCostsPSUPA) + 'psf'
    var fNewLeaseRentFree = this.props.theUnitInfo.leasingRentFreeMonths + ' months'
  
    var theMetricsText = 'Your investment scenario is as follows:<br><br>'
                      + returnsComment + '<br><br>'
                      + 'The investment assumptions are: <br><br>'
                      + 'Buy price: ' + fBuyPrice + ' ('+ fEntryNIY + ' NIY / ' + fEntryRY + ' RY)<br>'
                      + 'Buy costs: ' + fBuyCosts + '<br>'
                      + 'Sell price: ' + fSellPrice + ' ('+ fExitNIY + ' NIY / ' + fExitRY + ' RY)<br>'
                      + 'Sell costs: ' + fSellCosts + '<br><br>'
                      + 'Hold period: ' + fHoldPeriod + '<br><br>'
    

    if (this.props.seniorLTV > 0 ) {
      theMetricsText = theMetricsText +'Debt geared at ' + fSeniorLTV + ' LTV with all-in cost of ' + fSeniorInterestRatePA + ' interest pa (arrangement fee ' + fSeniorArrangementFee + ')<br><br>'
    }
    else {
      theMetricsText = theMetricsText + 'No debt. All cash. (tap Add Debt in FindIRR.com for loan-to-value assumptions)<br><br>'
    }
                  
    if ( this.props.day1NIA > 0 ) {
        theMetricsText = theMetricsText
                      + 'Area (NIA): ' + fAreaNIA + '<br><br>'
                      + 'Passing rent pa: '  + fPassingRentPA + ' (' + formatter2D.format(this.props.day1RentPA / this.props.day1NIA) + ')<br>'
                      + 'Market rent pa: ' + fMarketingRentPA + ' (' + formatter2D.format(this.props.day1MarketRentPA / this.props.day1NIA)+ ')<br><br>'
    }
    else {
                      theMetricsText = theMetricsText + '(No area added)<br><br>'

                      + 'Passing rent pa: ' + fPassingRentPA + '<br>'
                      + 'Market rent pa: ' + fMarketingRentPA + '<br><br>'
    }

    // Add Rent Review info
    if (this.props.rentReviewDate > 0 ) {

      let rentReviewedAmountPA = this.props.day1MarketRentPA * this.props.rentReviewHeadlinePercent/100
      let rentReviewedAmountPSU = rentReviewedAmountPA / this.props.day1NIA
      let displayRentReviewDate = this.props.rentReviewDate.toLocaleDateString('en-GB', {
        month: 'short', year: 'numeric', day: 'numeric',
      }).replace(/ /g, '-');
                      theMetricsText = theMetricsText
                      + 'Rent Review date: ' + displayRentReviewDate + '<br>'
                      + 'Rent Review amount: ' + formatter.format(rentReviewedAmountPA)
      
        // If Area provided, show reviewed amount PSU in brackets:
                      if (this.props.day1NIA > 0) {
                        
                        theMetricsText = theMetricsText
                        + ' (' + formatter2D.format(rentReviewedAmountPSU) + ')<br>'
                      } else {
                        theMetricsText = theMetricsText
                        + '<br>' 
        }
                      theMetricsText = theMetricsText
                      + 'Reviewed to % of market rent: ' + this.props.rentReviewHeadlinePercent + '%<br><br>'       
    }
    
    theMetricsText = theMetricsText
                      + 'Releasing assumptions:' + '<br>'
                      + 'Lease expiry: ' + displayExpiryDate + '<br>'
                      + 'Void months: ' + this.props.theUnitInfo.leasingVoidMths + ' months<br>'
                      + 'Void costs (business rates): ' + fVoidCostsBiz + '<br>'
                      + 'Void costs (service charges): ' + fVoidCostsSC + '<br>'
                      + 'Rent Free incentive for New Lease: ' + fNewLeaseRentFree

    theMetricsText = theMetricsText + '<br><br>To revisit this deal, go to this link: ' + this.getTextToCopy()

    this.setState({ textForMessage: theMetricsText}, () => {
        this.handleOpenEmailModal()
    })
  }

  returnBlockWithRotatingComments(thePassedComments) {
    var theComponentTitle = isMobile ? 'componentTitleMobile' : 'componentTitle'
    var theContainer = isMobile
      ? 'crossNarrativeContainerMobile'
      : 'crossNarrativeContainer'
    return (
      <>
        <div className={theContainer}>
          <div className="titleItemLarge"></div>
          <div className="columnEmpty">
            <div className={theComponentTitle}>
              <ReactRotatingText
                eraseMode="erase"
                // typingInterval = '30'
                items={thePassedComments}
              />
            </div>
          </div>
          <div className="columnEmpty"></div>
        </div>
      </>
    )
  }

  returnBlockReturnsAndRotatingComments(theReturnComment, thePassedComments) {
    var theComponentTitle = isMobile ? 'componentTitleMobile' : 'componentTitle'
    var theLabelCenterText = isMobile ? 'labelCenterTextMobile' : 'labelCenterText'
    var theLabelShareText = isMobile ? 'labelShareTextMobile' : 'labelCenterText'
    var theContainer = isMobile
      ? 'crossNarrativeContainerMobile'
      : 'crossNarrativeContainer'
    var theAlternativeContainer = theContainer
    if (this.props.appStage > 11) {
      theAlternativeContainer = 'buySellContainer'
    }

    return (
      <>
        <div className={theAlternativeContainer}>
          {this.props.appStage < 12 && <div className="titleItemLarge"></div>}
          <div>{theReturnComment}</div>
          <div className="columnEmpty"></div>
        </div>
       
        {this.props.appStage == 12 && (
          <BrowserView>
          <div className={theContainer}>
            <div className="titleItemLarge"></div>
            <div className={theLabelShareText}
              onClick={this.getReturnCommentsForEmail}
              style={{ cursor: 'pointer',  fontWeight: 'bold', color: 'lightgreen'}}
            >
              Click HERE for report and private shareable link  👉 📧
            </div>
          </div>
          </BrowserView>
        )}

        {this.props.appStage == 12 && (
          <MobileView> 
           <div className="shareReportMobilePinnedToBottom">
           <div></div>
           <div className="columnForSharingMobile"
           onClick={this.getReturnCommentsForEmail}
           style={{ cursor: 'pointer'}}
           >Share Report & Link</div>
           <div></div>
           </div>
           </MobileView>
        )}
        {/*
        <BrowserView>
        <br/>
        {this.props.appStage == 12 && (
          <div className={theContainer}>
            <div className="titleItemLarge"></div>
            <div className={theLabelCenterText}
              onClick={() => {navigator.clipboard.writeText(this.getTextToCopy())}}
              style={{ cursor: 'pointer' }}
            >
              🚀 Click for shareable link 🔗<br/><br/>
            </div>
          </div>
        )}
        </BrowserView> */}

        {this.state.emailModalIsOn == true && (
          <Portal>
            <Backdrop />
            <ModalEmail
              autoFocus
              title="Get metrics"
              handleCancelModal={this.handleCancelEmailModal}
              currencyCode = {this.props.currencyCode}
              buyPrice = {this.props.buyPrice}
              buyCosts = {this.props.buyCosts}
              sellPrice = {this.props.sellPrice}
              sellCosts = {this.props.sellCosts}
              holdPeriodYears = {this.props.holdPeriodYears}
              passingRentPA = {this.props.passingRentPA}
              marketRentPA = {this.props.marketRentPA}
              day1NIA = {this.props.day1NIA}
              theUnitInfo = {this.props.theUnitInfo}
              text_for_message = {this.state.textForMessage}
              irr_comment={this.state.irr_comment}
            />
          </Portal>
        )}

        <div className={theContainer}>
          <div className="titleItemLarge"></div>
          <div className={theLabelCenterText}>
            <ReactRotatingText eraseMode="erase" items={thePassedComments} />
          </div>
        </div>

        <BrowserView>
        <br/>
        {this.props.appStage > 11 && (
          <div className={theContainer}>
            <div className="titleItemLarge"></div>
            <div style={{fontSize:'9px', textAlign: 'center', color: '#3264A0'}}>
            Wondering what's under the hood? Here's the Qtrly Net DCF line: {JSON.stringify(this.state.latestCF)}
            </div>
            <div className="bracketItem"></div>
          </div>
        )}
        </BrowserView>
      </>
    )
  }

  returnsNarrativeStatus() {

    if (this.props.appStage == 0) {
      return this.returnBlockWithRotatingComments([
        '',
        'Hello ' + this.props.regionName + '!',
        'Price your single tenant investment' +
          ' (' +
          this.props.currencySymbol +
          ' ' +
          this.props.currencyCode +
          ')...',
        'Start here 👆',
        'Be guided each step of the way...',
        'Make your appraisal life easier.',
        'To kick-off, just click the above...',
        'Enter your price in ' +
          this.props.currencyCode +
          ' (' +
          this.props.currencySymbol +
          ')',
        'Forget complex and costly software.',
        'No need to pay for a single tenant IRR...',
        'For more complex deals, check out Dashflow for CRE!',
      ])
    } else if (this.props.appStage == 2) {
      return this.returnBlockWithRotatingComments([
        '',
        "OK... Now let's add a Sell Price",
        'This will start displaying Profit and Multiple results',
        'You know the drill... click 👆',
        'You can always change the value later.',
        'For further info, go to DashflowCRE.com/FindIRR',
      ])
    } else if (this.props.appStage == 4) {
      var theProfitComment = this.returnProfitComment
      return this.returnBlockReturnsAndRotatingComments(
        this.returnProfitComment(),
        [
          '',
          'Add Hold Period to view IRR 👆',
          'Base cases often involve 5-year holds',
          'Typical closed-ended funds last 8-12 years',
          'Evergreen funds consider longer periods.',
          '',
          'You got this... just click to Add 👆',
          'For more info, go to DashflowCRE.com/FindIRR',
        ],
      )
    } else if (this.props.appStage == 6) {
      return this.returnBlockReturnsAndRotatingComments(
        this.returnProfitComment(),
        [
          '',
          "Your 'Return stack' is shaping up...!",
          'Add Rent then get entry & exit yields',
          '',
          "Just click 'Add rent' above 👆...",
          '..to produce cash-on-cash .',
          'For further info, go to DashflowCRE.com/FindIRR',
        ],
      )
    } else if (this.props.appStage == 8) {
      return this.returnBlockReturnsAndRotatingComments(
        this.returnProfitComment(),
        [
          '',
          'Now add a Market Rent',
          'This will help display market or reversionary yields',
          'Your Dashboard is taking shape.',
          'Just Add Market Rent above 👆...',
          '',
          'For further info, go to DashflowCRE.com/FindIRR',
        ],
      )
    } else if (this.props.appStage == 10) {
      return this.returnBlockReturnsAndRotatingComments(
        this.returnProfitComment(),
        [
          "Now let's clarify when the current lease will end...",
          'Do this by adding a new leasing event.',
          "Just click 'Add New Lease Event' above 👆...",
          '',
          'The main Dashboard is nearly ready.',
          'How are you finding this Beta Version for your simple deals?',
          'Let us know your thoughts at hello@findirr.com',
          '',
          'Got an idea to make this even better...?',
          "Click on 'Buy us a Coffee' and share some feedback.",
          '',
          'For further info, go to DashflowCRE.com/FindIRR',
        ],
      )
    } else if (this.props.appStage == 12) {
      return this.returnBlockReturnsAndRotatingComments(
        this.returnProfitComment(),
        [
          'The Dashboard is now in good shape...',
          'You can share your scenario via email.',
          '',
          'Tap Void months to add some Void cost detail.',
          '',
          "Click to email and share investment assumptions...",
          'You also get a private link to revisit numbers instantly.',
          'Sign-up to hear about FindIRR upgrades at DashflowCRE.com/FindIRR',
          'If this approach suits you...',
          'Send your recommendation for the next top feature.',
          '',
          'Need more complex multi-tenanted projects...?',
          '...check out DashflowCRE.com',
          '',
        ],
      )
    } else if (this.props.appStage >= 14) {
      return this.returnBlockReturnsAndRotatingComments(
        this.returnProfitComment(),
        [
          '',
          '',
          'If you need multi-tenanted scenarios...',
          'Check out DashflowCRE.com',
          '',
          "We've got demos too...",
          'Public sessions twice a month and bespoke for in-house teams',
        ],
      )
    }
  }

  theReturnsFor(qtrlyValues) {

    var oldValue = 0.0
    var newValue = 0.0
    var oldguessRate = this.state.LOW_RATE
    var newguessRate = this.state.LOW_RATE
    var guessRate = this.state.LOW_RATE
    var lowGuessRate = this.state.LOW_RATE
    var highGuessRate = this.state.HIGH_RATE
    var npv = 0.0
    var denom = 0.0

    for (let i = 0; i < this.state.MAX_ITERATION; i++) {
      npv = 0.0

      for (let j = 0; j < qtrlyValues.length; j++) {
        denom = Math.pow(Math.pow(1 + guessRate, 1 / 4), j)
        npv = npv + qtrlyValues[j] / denom
      }

      /* Stop checking once the required precision is achieved */
      if (npv > 0 && npv < this.state.PRECISION_REQ) {
        break
      }

      if (oldValue == 0) {
        oldValue = npv
      } else {
        oldValue = newValue
      }

      newValue = npv
      if (i > 0) {
        if (oldValue < newValue) {
          if (oldValue < 0 && newValue < 0) {
            highGuessRate = newguessRate
          } else {
            lowGuessRate = newguessRate
          }
        } else {
          if (oldValue > 0 && newValue > 0) {
            lowGuessRate = newguessRate
          } else {
            highGuessRate = newguessRate
          }
        }
      }

      oldguessRate = guessRate
      guessRate = (lowGuessRate + highGuessRate) / 2
      newguessRate = guessRate
    }

    // console.log("IRR guess is:" + guessRate)
    guessRate = guessRate * 100
    guessRate = guessRate.toFixed(1)
    return guessRate
  }

  render() {
    return (
      <div>
        <div>{this.returnsNarrativeStatus()}</div>
      </div>
    )
  }
}
export default ReturnsNarrative
