import { DateTime, Interval } from "luxon"
import React from 'react'


// Used by ModalValueForTarget

export function getRelevantRentPAatExit(day1Date, holdingPeriodInYears, leaseExpiryDate, 
                                            rentReviewDate, day1RentPA, marketRentPA, rentReviewHeadlinePercentage) {
    var relevantRentPAatExit = 0

    let theDay1Date = new Date(day1Date)
    let theLeaseExpiryDate = new Date(leaseExpiryDate)
    let theRentReviewDate = new Date(rentReviewDate)
  
    let exitDate = getExitDateFor(theDay1Date, holdingPeriodInYears)

    if (theLeaseExpiryDate > 0 && theLeaseExpiryDate  <= exitDate){
        relevantRentPAatExit = marketRentPA
    }
    else if (theRentReviewDate < exitDate && theRentReviewDate > 0) {
        relevantRentPAatExit = rentReviewHeadlinePercentage/100 * marketRentPA
    }
    else {
        relevantRentPAatExit = day1RentPA
    }

    return relevantRentPAatExit
}


// Used by MainBoard

export function getTimedRentAtExit(theUnitInfo, holdPeriodYears, passingRentPA, marketRentPA, rentReviewDate, rentReviewHeadlinePercent) {

    if(isNaN(holdPeriodYears) || holdPeriodYears == 0) { //|| isNaN(passingRentPA) || isNaN(marketRentPA) ) {
        return 0
    }
    var theTimedExitRent = 0 
    var exitDate = getExitDateFor(new Date(), holdPeriodYears)
    let comparedExpiryDate = new Date(theUnitInfo.leaseExpiry)
    let comparedReviewDate = new Date(rentReviewDate)

    if (comparedExpiryDate < exitDate) {
        theTimedExitRent = marketRentPA
        return theTimedExitRent
    }
    else if (comparedReviewDate < exitDate && comparedReviewDate > 0) {
        theTimedExitRent = marketRentPA * (rentReviewHeadlinePercent / 100)
        return theTimedExitRent
    }
    else {
        theTimedExitRent = passingRentPA
    }
    return theTimedExitRent
}

// ==================


export function getBaseRentToLEXqtrlyArray(day1Date, leaseExpiryDate, baseRentPA) {

    var qtrlyArray = [0] // Time-zero represents no quarterly rent.  I.e. no advance rent in the closing balance.

    qtrlyArray.push(1)
    qtrlyArray.push(100)
    //var duration = Interval.fromDateTimes(day1Date, leaseExpiryDate)

    // return duration
    const date1 = new Date(day1Date)
    const date2 = new Date(leaseExpiryDate)

    const date1ISO = date1.toISOString()
    const date2ISO = date2.toISOString()

    //const date1 = Date.toISOString(day1Date)
    //const date2 = Date.toISOString(leaseExpiryDate)

    const luxonDate1 = DateTime.fromISO(date1)
    const luxonDate2 = DateTime.fromISO(date2)

    return Interval.fromDateTimes(luxonDate1, luxonDate2)
}

export function getQtrlyRentBetweenDates(qtrlyTZeroDate, firstDate, secondDate, rentPerAnnum) {

    const durations = getQtrlyDurationObject(firstDate, secondDate)


}

/* HELPER FUNCTIONS WORKED OUT */

/* This is for a constant stream from any date until the end of the Hold Period */
export function getQtrlyArrayForNewStream(tZeroDate, targetDate, newValuePA, holdPeriodInYears) {

    let firstQtr = 0
    let secondQtr = 1

    let startingQtr = getQuarterNumber(tZeroDate, targetDate)

    // Add Years x 12 months to quarter date.

    //let date1 = newDate(targetDate)

}

export function getQuarterNumber(tZeroDate, targetDate) {
    const durations = getQtrlyDurationObject(tZeroDate, targetDate)
    
    let theQuarter = Math.floor(durations.months / 3)

    return theQuarter
}


/*
Helper.js - 
Update releasingIncome_qtrly
1. Get Quarter number for Lease Expiry + void period + rent free
2. Get duration between LexVRF until earliest of end of Hold-Period-Date or EoQtrNoDate.
3. Apply Market Rent to LexVRF-to-EoQdate
4. Apply
2. Get duration between LexV-plus-end-of-Qtr until end of Hold Period
2. Get days between Lease Expiry and end-of-date-for-Qtr-Number
3. Get months between end-of-date-for-Qtr-Number until Qtr for End-Of-Period



Update releasingOPEX_qtrly
1. Get Qtr no. for Lease Expiry
2. Get days between Lease Expiry and end-of-date-for-next-Qtr.



*/

export function calculateQtrlyArrayFromInfo (leadingDays, leadingMths, startQtr, 
                                            trailingDays, trailingMths, endQtr,
                                            holdPeriodYears, valuePerAnnum) {

    var qtrlyArrayForCF = []

    //console.log( startQtr + " StartQtr - " + leadingMths + " LeadingMths ")
    for (var i = 0; i < Math.min(holdPeriodYears * 4, endQtr+1); i++) {

        let nominalValueForQtr = 0

        if (i >= startQtr && i < endQtr) {
            nominalValueForQtr = Math.round(valuePerAnnum / 12 * 3)
        }
        else if (i == startQtr - 1) {
            // console.log("leading months: " + leadingMths)
            nominalValueForQtr = Math.round((valuePerAnnum / 12 * leadingMths) + (valuePerAnnum / 365 * leadingDays))
        }
        else if (i == endQtr) {
            nominalValueForQtr = Math.round((valuePerAnnum / 12 * trailingMths) + (valuePerAnnum / 365 * trailingDays))
        }

        if (isNaN(nominalValueForQtr)) {
            qtrlyArrayForCF[i] = -0.001
        }
        else {
            qtrlyArrayForCF[i] = nominalValueForQtr    
        }
    }

    return qtrlyArrayForCF
}


export function updateQtrlyTimingInfo (date_Day1, date_Start, date_End) {

    // console.log("++ passed values to updateQtrlyTimingInfo: ")
    // console.log("   ++ "+ date_Day1)
    // console.log("   ++ "+ date_Start)
    // console.log("   ++ "+ date_End) 
    let diffDurations = getQtrlyDurationObject(date_Day1, date_Start)

    let startQuarter = diffDurations.theQuarters + 1
    //OK: console.log("++ startQuarter: " + startQuarter)

    // add (3 months x startQuarter) to date_Day1... to get specific Quarter date for leading days calc
    let copyDate_Day1 = new Date(date_Day1.getTime())
    let start_Quarter_Date = getSpecificQuarterDate(copyDate_Day1, diffDurations.theQuarters + 1)
    //OK: console.log("++ startQtr Date: " + start_Quarter_Date)

    // get leadingDays to start Qtr Date
    let copyDate_Start = new Date(date_Start.getTime())
    let leadingDays = dateIntervalObject(copyDate_Start, start_Quarter_Date)
    //OK console.log("++ leading Days to QtrDate: " + leadingDays.days)
    //OK console.log("++ leading Mths to QtrDate: " + leadingDays.months)

    // get end Quarter number
    let diffDurations2 = getQtrlyDurationObject(date_Day1, date_End)
    let endQuarter = diffDurations2.theQuarters
    //OK: console.log("++ endQuarter: " + endQuarter)

    // add (3 mths x endQuarter) to date_Day1... to get specific Quarter End Date for trailing days calc
    // note: unlike leading Days, theQuarters are passed instead of 'theQuarters + 1'
    
    copyDate_Day1 = new Date(date_Day1.getTime())
    let end_Quarter_Date = getSpecificQuarterDate(copyDate_Day1, diffDurations2.theQuarters)
    // OK console.log("++ endQtr Date: " + end_Quarter_Date)
    // OK console.log("++ end Date: " + date_End)
    // get trailingDays to end Qtr Date
    let trailingDays = dateIntervalObject(end_Quarter_Date, date_End)
    // OK console.log("++ trailing Days to QtrDate: " + trailingDays.days)
    // OK console.log("++ trailing Months to QtrDate: " + trailingDays.months)

    const qtrlyTimings = {
        startQtrDate: start_Quarter_Date,
        startQtr: startQuarter,
        leadingDays: leadingDays.days,
        leadingMths: leadingDays.months,

        endQtrDate: end_Quarter_Date,
        endQtr: endQuarter,
        trailingDays: trailingDays.days,
        trailingMths: trailingDays.months,
    }

    return qtrlyTimings
}

export function getSpecificQuarterDate(date_Day1, numberOfQuarters) {
    const specific_Quarter_Date = new Date(date_Day1.setMonth(date_Day1.getMonth() + (numberOfQuarters * 3)))
    return specific_Quarter_Date
}


export function differenceBtw(firstDate, secondDate) {

    let day2 = secondDate
    let day1 = firstDate 

    let diffDurations = getQtrlyDurationObject(firstDate, secondDate)
    // OK: console.log("diffDuration qtrs:" +  diffDurations.theQuarters + " Total Mths: " + diffDurations.theMonths + " days: " + Math.round(diffDurations.theDays))
    return diffDurations
}

export function getQtrlyDurationObject(firstDate, secondDate) {

    const diffObject = dateIntervalObject(firstDate, secondDate)

    const yearsToQuarters = diffObject.years * 4
    const monthsToQuarters = Math.floor(diffObject.months / 3)
    const quarters = yearsToQuarters + monthsToQuarters

    let daysToMonths = 0
    if (diffObject.days > 0) {
        daysToMonths = 1
    }

    const months = diffObject.months % 3

    const durations = {
        theQuarters: quarters,
        theMonths: months,
        theDays: diffObject.days,
    }
    // OK console.log('*** durations: ', durations)
    return durations
}

export function dateIntervalObject(firstDate, secondDate) {

    let day1 = new Date(firstDate.getTime())
    let day2 = new Date(secondDate.getTime())

    const date1 = new DateTime.fromJSDate(day1) // e.g. console.log format: 1658390676901
    const date2 = new DateTime.fromJSDate(day2) // e.g. console.log format: 1708761435300
   
    const diff = date2.diff(date1, ['years', 'months', 'days']).toObject()
    // OK: console.log('*** diff object: ', diff)
    return diff
}

export function getExitDateFor(date_Day1, holdingYears) {

    let copyDate = new Date(date_Day1)
    const exit_Date = new Date(copyDate.setMonth(copyDate.getMonth() + (holdingYears * 12)))
    //OK: console.log("d1: " + copyDate + " holdingYrs: "+holdingYears + " exit_date: " + exit_Date)
    return exit_Date
}


