import React from 'react'

class Returns extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      theQtrlyValues: this.props.qtrlyValues,
      LOW_RATE: 0.0001,
      HIGH_RATE: 5.0,
      MAX_ITERATION: 50,
      PRECISION_REQ: 0.00001,
    }
  }

  theReturnsFor(qtrlyValues) {
    var oldValue = 0.0
    var newValue = 0.0
    var oldguessRate = this.state.LOW_RATE
    var newguessRate = this.state.LOW_RATE
    var guessRate = this.state.LOW_RATE
    var lowGuessRate = this.state.LOW_RATE
    var highGuessRate = this.state.HIGH_RATE
    var npv = 0.0
    var denom = 0.0

    for (let i = 0; i < this.state.MAX_ITERATION; i++) {
      npv = 0.0

      for (let j = 0; j < qtrlyValues.length; j++) {
        denom = Math.pow(Math.pow(1 + guessRate, 1 / 4), j)
        npv = npv + qtrlyValues[j] / denom
      }

      /* Stop checking once the required precision is achieved */
      if (npv > 0 && npv < this.state.PRECISION_REQ) {
        break
      }

      if (oldValue == 0) {
        oldValue = npv
      } else {
        oldValue = newValue
      }

      newValue = npv
      if (i > 0) {
        if (oldValue < newValue) {
          if (oldValue < 0 && newValue < 0) {
            highGuessRate = newguessRate
          } else {
            lowGuessRate = newguessRate
          }
        } else {
          if (oldValue > 0 && newValue > 0) {
            lowGuessRate = newguessRate
          } else {
            highGuessRate = newguessRate
          }
        }
      }

      oldguessRate = guessRate
      guessRate = (lowGuessRate + highGuessRate) / 2
      newguessRate = guessRate
    }

    
    return guessRate
  }

  render() {
    return (
      <div>
        <div>{this.theReturnsFor()}</div>
      </div>
    )
  }
}
export default Returns
