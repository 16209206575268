import React from 'react'
import NumberFormat from "react-number-format"
import './../App.css'
import './../AppMobile.css'
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from 'react-device-detect'

class HoldPeriodBlock extends React.Component {
  render() {

    var theColumnForBuySell = isMobile ? 'columnForBuySellMobile' : 'columnForBuySell'
    var theComponentTitle = isMobile ? 'componentTitleMobile' : 'componentTitle'
    var theSubInputTextSize = isMobile ? '1.0rem' : '1.5vw'
    var theFlexParent = isMobile ? 'flexParentMobile' : 'flexParent';

    

    return (
      <div>
        <div className={theColumnForBuySell}>

        <div className={theFlexParent}>
          <div className={theComponentTitle}>{this.props.title}</div>
          <div className="inputStyling">
          <NumberFormat defaultValue={this.props.holdPeriodInYears} inputMode="decimal" suffix=" years"
            onValueChange={(values) => {
              const {formattedValue, value, floatValue} = values;
              this.setState({inputValuePrice: floatValue}, () => {
                this.props.applyBlockValue(this.state.inputValuePrice)
              })
              // do something with floatValue
            }}
            style={{
              width: '100%',
              backgroundColor: 'solid white', //'transparent',
              fontSize: theSubInputTextSize,
              textAlign: 'center',
              boxSizing: 'border-box',
              border: '0.5px solid white',
              borderRadius: '10px',
              color: 'black',
              marginLeft: '10px',
              
            }}
            />
          </div>
        </div>

        <BrowserView>
          <div className="relativeDiv">
              <br></br>
            <div className="divTipContext">{this.props.labelTip}</div>
          </div>
          </BrowserView>
        </div>
      </div>
    )
  }
}
export default HoldPeriodBlock
