import React from 'react'
import './../App.css'
import NumberFormat from 'react-number-format'
import AddBlock from './AddBlock'
import './../AppMobile.css'
import Popup from 'reactjs-popup'
import Portal from './Portal'
import Backdrop from './Backdrop'
import 'reactjs-popup/dist/index.css'
import ModalDebtAssumptions from './ModalDebtAssumptions'
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from 'react-device-detect'

class LoanBlock extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      seniorLTVinput: this.props.seniorLTV,
      seniorArrangementFeeInput: this.props.seniorArrangementFee,
      seniorInterestRatePAInput: this.props.seniorInterestRatePA,
      seniorAmortisationPAInput: this.props.seniorAmortisationPA,
      loanModalIsOn: false,

      apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    }

    this.handleCancelModal = this.handleCancelModal.bind(this)
    this.applyModalDebtAssumptions = this.applyModalDebtAssumptions.bind(this)

/*
    this.submitTargetRentToParent = this.submitTargetRentToParent.bind(this)
    this.applyModalAreaAndRentPSU = this.applyModalAreaAndRentPSU.bind(this)
    this.updateRentAndRentPSU = this.updateRentAndRentPSU.bind(this)
 */
}
/*
  submitTargetRentToParent(theRentPerSqUnit) {
    this.setState({ loanModalIsOn: !this.state.loanModalIsOn })
    this.setState({ rentLevelpa: theRentPerSqUnit * this.state.theDay1NIA })
    this.props.applyModalValue(theRentPerSqUnit * this.state.theDay1NIA)
  }
/*/
  handleCancelModal() {
    this.setState({ loanModalIsOn: !this.state.loanModalIsOn })
  }
  /*

  updateRentAndRentPSU(floatValue) {
    this.setState({ rentLevelpa: floatValue }, () => {
      this.props.handleDay1PassingRentPA(floatValue) //this.state.rentLevelpa)
      if (this.props.day1NIA > 0) {
        this.setState({
          rentPerSquareUnit: Number(
            this.rentLevelpa / this.state.theDay1NIA,
          ).toFixed(2),
        })
      }
    })
  }
*/
  applyModalDebtAssumptions(ltvValue, intRateValue, arrFeeValue) {
    this.setState({ loanModalIsOn: !this.state.loanModalIsOn })

    this.setState({ seniorLTVinput: ltvValue})
    this.setState({ seniorInterestRatePAInput: intRateValue})
    this.setState({ seniorArrangementFeeInput: arrFeeValue})

    this.props.applyModalDebtAssumptions(ltvValue, intRateValue, arrFeeValue)
    console.log('sending LTV from loan block to parent: ' + ltvValue)
  }

  updateDebtAssumptions(ltvValue, intRateValue, arrFeeValue) {
    this.setState({ seniorLTVinput: ltvValue})
    this.setState({ seniorInterestRatePAInput: intRateValue})
    this.setState({ seniorArrangementFeeInput: arrFeeValue})
  
    this.props.applyModalDebtAssumptions(ltvValue, intRateValue, arrFeeValue)
  }

  applyModalRentAndRentPSU(floatValue) {
    this.updateAreaAndRentPSU(floatValue)
    this.setState({ loanModalIsOn: !this.state.loanModalIsOn })
  }

  render() {
    var theColumnForDebt = isMobile
      ? ((this.state.seniorLTVinput > 0) ? 'columnForLoanBlockMobileOn' : 'columnForLoanBlockMobile')
      : 'columnForDebt'
    var theComponentTitle = isMobile ? 'componentTitleMobile' : 'componentTitle'
    var theSubInputTextSize = isMobile ? '1.0rem' : '1.5vw'
    var theAddItem = isMobile ? 'addItemMobile' : 'addItem'
    var theFlexParent = isMobile ? 'flexParentMobile' : 'flexParent'
    var theGroupedItems = isMobile ? 'groupedItems' : 'groupedItems'
    var theDebtTitle = (this.state.seniorLTVinput > 0) ? 'Debt' : `No Debt. All cash.`
    var theDebtTitleFontColour = (this.state.seniorLTVinput > 0) ? 'white' : 'grey'

    //console.log('this is the api REACT_APP_GOOGLE_MAPS_API_KEY: ', process.env.REACT_APP_GOOGLE_MAPS_API_KEY)

    return (
      <div>
        <div className={theColumnForDebt}>
          <div className={theFlexParent}>
            <MobileView>
              <div style={{display: 'flex', whiteSpace: 'nowrap',}}>
              <div style={{color:theDebtTitleFontColour}}className={theComponentTitle}>{theDebtTitle}</div>
            {/* {(this.state.seniorLTVinput > 0) && (
              <div style={{color:'grey'}}className={theComponentTitle}>{this.props.currencySymbol}24MM</div>
            )} */}
              </div>
            </MobileView>
            {(this.state.seniorLTVinput > 0) && (
            <div>
            <div className="inputStyling" style={{display: 'flex', whiteSpace: 'nowrap', justifyContent:'center'}}>
              <NumberFormat
                autoFocus value={this.state.seniorLTVinput} inputMode="decimal" suffix={'% LTV'}
                onValueChange={(values) => {
                  const { formattedValue, value, floatValue } = values
                    this.updateDebtAssumptions(floatValue, this.state.seniorInterestRatePAInput, this.state.seniorAmortisationPAInput)
                  }}
                style={{
                  width: '30%',
                  backgroundColor: 'solid white',
                  fontSize: theSubInputTextSize,
                  textAlign: 'center',
                  boxSizing: 'border-box',
                  border: '0.5px solid white', 
                  borderRadius: '7px',
                  color: 'black',
                }}
              />

              <BrowserView>
              <span>&nbsp;&nbsp;</span>
              </BrowserView>

              <NumberFormat
                autoFocus value={this.state.seniorInterestRatePAInput} inputMode="decimal" suffix={'% rate'}
                onValueChange={(values) => {
                const { formattedValue, value, floatValue } = values
                  this.updateDebtAssumptions(this.state.seniorLTVinput, floatValue, this.state.seniorArrangementFeeInput)
              }}
                style={{
                width: '30%',
                backgroundColor: 'solid white',
                fontSize: theSubInputTextSize,
                textAlign: 'center',
                boxSizing: 'border-box',
                border: '0.5px solid white',
                borderRadius: '7px',
                color: 'black',
                marginLeft: '5px',
                }}
              />
              <BrowserView>
              <span>&nbsp;&nbsp;</span>
              </BrowserView>

              <NumberFormat
                autoFocus value={this.state.seniorArrangementFeeInput} inputMode="decimal" suffix={'% fee'}
                onValueChange={(values) => {
                  const { formattedValue, value, floatValue } = values
                  this.updateDebtAssumptions(this.state.seniorLTVinput, this.state.seniorInterestRatePAInput, floatValue)
                  }}
                  style={{
                  width: '30%',
                  backgroundColor: 'solid white',
                  fontSize: theSubInputTextSize,
                  textAlign: 'center',
                  boxSizing: 'border-box',
                  border: '0.5px solid white',
                  borderRadius: '7px',
                  color: 'black',
                  marginLeft: '5px',
                }}
              />
            </div>
            </div>
            )}
            {!(this.state.seniorLTVinput > 0) && (
            <div style={{
                display: 'flex',
                whiteSpace: 'nowrap',
                justifyContent: 'center',
                width: '30%',
                backgroundColor: 'solid white',
                fontSize: theSubInputTextSize,
                textAlign: 'center',
                boxSizing: 'border-box',
                border: '0.5px dashed white',
                borderRadius: '10px',
                color: 'white',
                marginLeft: '15px',

              cursor:'pointer'}}
              onClick={(e) => {
                const rect = e.target.getBoundingClientRect()
                this.setState({
                  left: rect.x + rect.width / 2,
                  top: rect.y + window.scrollY,
                })
                this.setState({ loanModalIsOn: !this.state.loanModalIsOn }) // [ 3 ]
              }}
              className={theAddItem}
            >
              + Add Debt
            </div>
            )}
            {/* embed google map api into this frame: 
              <div>
                {this.returnJSXFrameMap(46.414382, 10.013988)}  
              </div>
              */}
          </div>

          {(this.state.loanModalIsOn==true) && (
            <Portal>
              <Backdrop/>
              <ModalDebtAssumptions
                autoFocus
                title="Input your Debt Assumptions"
                currencySymbol={this.props.currencySymbol}
                applyModalValue={this.applyModalDebtAssumptions}
                submitValueToParent={this.applyModalDebtAssumptions}
                handleCancelModal={this.handleCancelModal}
              />
            </Portal>
          )}
          
        </div>
      </div>
    )
  }

  /* returnJSXFrameMap(lat, long) {
    //write the JSX for the map here
    return (
      <div>
        <iframe
          src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDQTwL1133cKZYv2VvpeI7wLE2CPae_ovM&q=Eiffel+Tower,Paris+France`}
          width="100%"
          height="100%"
          style={{ border: 0 }}
          allowFullScreen=""
          aria-hidden="false"
          tabIndex="0"
        ></iframe>
      </div>
    )
  } */

}
export default LoanBlock
