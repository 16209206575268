import React, { useRef } from 'react'
import './../App.css'
import './../AppMobile.css'
import './../Calendar.css'
import NumberFormat from "react-number-format"
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from 'react-device-detect'
import { DateTime } from 'luxon'

var stylingObject = {
  input: {
    margin: '2px',
    padding: '5px',
  },
  outerDiv: {
    border: '0px',
  },
  addStyling: {
    border: '1px dashed white',
    margin: '40px',
    borderRadius: '20px',
    padding: '40px',
    lineHeight: '30px',
    fontSize: '20px',
    textAlign: 'center',
    fontWeight: 'bolder',
    cursor: 'pointer',
  },
  btnStyling: {
    backgroundColor: 'white',
    border: '1px solid grey',
    borderRadius: '5px',
    fontSize: '18px',
    margin: '15px',
    paddingLeft: '30px',
    paddingRight: '30px',
    color: '#38336E',
  },
  fieldStyling: {
    borderRadius: '20px',
  },
}

class ModalReleasingValues extends React.Component {
  constructor(props) {
    super(props)

    this.state = {

      leasingVoidMths: this.props.theUnitInfo.leasingVoidMths,
      leasingVoidBizCostsPSUPA: this.props.theUnitInfo.leasingVoidBizCostsPSUPA,
      leasingVoidSCCostsPSUPA: this.props.theUnitInfo.leasingVoidSCCostsPSUPA,
      leasingRentFreeMonths: this.props.theUnitInfo.leasingRentFreeMonths,
    }

    this.updateInputValue = this.updateInputValue.bind(this)
    this.submitValueToParent = this.submitValueToParent.bind(this)
    this.handleCancelPressed = this.handleCancelPressed.bind(this)
    this.updateReleasingValues = this.updateReleasingValues.bind(this)
  }

  updateInputValue(evt) {
    const val = evt.target.value
    this.setState({ inputValue: val })
  }

  updateReleasingValues() {
    // const unitsInfo = Object.entries(this.props.theUnitInfo)
    // unitsInfo.leasingVoidMths = this.state.leasingVoidMths
    
    var updatedUnitInfo = {
      id: this.props.theUnitInfo.id,
      leaseExpiry: this.props.theUnitInfo.leaseExpiry,
      leaseExpiryDilaps: this.props.theUnitInfo.leaseExpiryDilaps,
      // these are updated:
      leasingVoidMths: this.state.leasingVoidMths,
      leasingVoidBizCostsPSUPA: this.state.leasingVoidBizCostsPSUPA,
      leasingVoidSCCostsPSUPA: this.state.leasingVoidSCCostsPSUPA,
      leasingRentFreeMonths: this.state.leasingRentFreeMonths,
    }
    this.props.handleReleasingValues(updatedUnitInfo)
  }

  handleCancelPressed() {
    this.props.handleReverseAppStage()
  }

  submitValueToParent() {
    this.props.applyModalValue(this.state.inputValue)
  }

  handleKeyDown(event) {
    if (event.keyCode === 13) {
      this.submitValueToParent()
    } else if (event.keyCode === 27) {
      this.reverseTheAppStage()
    }
  }

  theButtonsContent() {
    return (
      <>
        <button
          onClick={this.updateReleasingValues}
          style={stylingObject.btnStyling}
        >
          Apply
        </button>
        <button
          onClick={this.props.handleCancelModal}
          style={stylingObject.btnStyling}
        >
          Cancel
        </button>
        <br />
      </>
    )
  }

  render() {

    var theFlexParent = isMobile ? 'flexParentMobile' : 'labelAndInput'
    var theLabelLeftText = isMobile ? 'labelLeftTextMobile' : 'labelLeftText'
    var theModal = isMobile ? 'modalMobile' : 'modal'
    let thePrefix = (this.props.title.includes("Hold")) ? '' : '£'
    let theSuffix = (this.props.title.includes("Hold")) ? ' years' : ''
    let inputFontSize = isMobile ? '1.0rem' : '1.4vw'
    return (
      <div className={theModal}>
        <div className="backgroundEmoji"></div>
        <div>{this.props.title}</div>
        <br/>

        <div className='labelAndInput'>
            <div className={theLabelLeftText}>
                Void period
            </div>
            <NumberFormat
              suffix={" mths"}
              value={this.state.leasingVoidMths}
              placeholder={"e.g. 6 mths"}
              onValueChange={(values) => {
                const { formattedValue, value, floatValue } = values
                this.setState({ leasingVoidMths: floatValue }, () => {
                  //this.props.applyModalValue(this.state.inputValue)
                })
              }}
            style={{ borderWidth :'0px', borderRadius: '10px', textAlign:'center', fontSize: inputFontSize,width: '42%'}} />
        </div>
        <br/>

        <div className='labelAndInput'>
        {(this.props.day1NIA == 0) && (
            <div className={theLabelLeftText}>
                (Tip: add Area to then apply Void costs)
            </div> )}
        {(this.props.day1NIA > 0) && (
            <div className={theLabelLeftText}>
                ↳ Void rates costs
            </div> )}
            {(this.props.day1NIA > 0) && (
            <NumberFormat
            value={this.state.leasingVoidBizCostsPSUPA}
            placeholder={"e.g."+this.props.currencySymbol+"12.50psf"}
            prefix={this.props.currencySymbol}
            suffix={" psf"}
            onValueChange={(values) => {
              const { formattedValue, value, floatValue } = values
              this.setState({ leasingVoidBizCostsPSUPA: floatValue }, () => {
                //this.props.applyModalValue(this.state.inputValue)
              })
            }}
            style={{borderWidth: '0px', borderRadius: '10px', width: '32%', textAlign:'center', fontSize: inputFontSize }} />
            )}
        </div>
        <br/>

        <div className='labelAndInput'>
        {(this.props.day1NIA > 0) && (
            <div className={theLabelLeftText}>
            ↳ Void S/C costs
            </div> )}
            {(this.props.day1NIA > 0) && (
            <NumberFormat 
             value={this.state.leasingVoidSCCostsPSUPA}
             placeholder={"e.g."+this.props.currencySymbol+"3.25psf"}
             prefix={this.props.currencySymbol}
             suffix={" psf"}
             onValueChange={(values) => {
              const { formattedValue, value, floatValue } = values
              this.setState({ leasingVoidSCCostsPSUPA: floatValue }, () => {
                //this.props.applyModalValue(this.state.inputValue)
              })
            }}
             style={{borderWidth: '0px', borderRadius: '10px', width: '32%', textAlign:'center', fontSize: inputFontSize }}/>
            )}
        </div>
        <br/>
        <div className='labelAndInput'>
            <div className={theLabelLeftText}>
                New Lease Rent Free
            </div>
            <NumberFormat
            value={this.state.leasingRentFreeMonths}
            placeholder={"e.g. 3 mths"}
            suffix={" mths"}
            onValueChange={(values) => {
              const { formattedValue, value, floatValue } = values
              this.setState({ leasingRentFreeMonths: floatValue }, () => {
                //this.props.applyModalValue(this.state.inputValue)
              })
            }}
            style={{borderWidth: '0px', borderRadius: '10px', textAlign:'center', fontSize: inputFontSize,width: '42%' }}
            />
        </div>
        <br/>
        {this.theButtonsContent()}
        <p></p>
      </div>
    )
  }
}

export default ModalReleasingValues
