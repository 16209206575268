import React from "react"
import {useState} from "react"
import './../App.css'
import './../AppMobile.css'
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from 'react-device-detect'

import { useSpring, animated } from 'react-spring'

var stylingObject = {
  // input: {  
  //   margin: "2px",
  //   padding: "5px",
  // },
  outerDiv: {
    border: "0px",
  },
  addStyling: {
    border: "1px dashed white",
    borderRadius: "20px",
    textAlign: "center",
    fontWeight: "bolder",
    cursor: "pointer",
  },
  '&::before': {
    content: "",
    display: 'block',
    position: 'absolute',
    zIndex: '-1',
    height: '80%',
    width: '80%',
    top: '40px',
    right: '0',
    left: '0',
    background: '#000000',
    mixBlendMode: 'color-dodge',
    filter: 'blur(30px)',
    opacity: '0.5',
  }
}

function AddBlock(props) {

  var theAddBlock = isMobile ? 'addBlockMobile' : 'addBlock'

  if (props.type == "tiny") {
    theAddBlock = 'addBlockTiny'
  }

  // const springProps = useSpring({ to: { opacity: 1 }, from: { opacity: 0 } })

  const [flip, set] = useState(false)

  const springProps = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0.35 },
    reset: true,
    reverse: flip,
    delay: 1250,
    // config: config.molasses,
    onRest: () => set(!flip),
  })

  const [state, toggle] = useState(true)
  const {x} = useSpring({
    from: { x: 0 },
    x: state ? 1 : 0,
    config: { duration: 1200 },
    delay: 500,
  })

  return (
    <>
    <div>
      <animated.div 
      style={{
        opacity: x.to({ range: [0, 1], output: [0.3, 1] }),
        scale: x.to({
          range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75, 1],
          output: [1, 0.97, 0.9, 1.1, 0.9, 1.1, 1.03, 1],
        }),
      }}>
        <div className={theAddBlock} 
        style={stylingObject.addStyling}
        >
          {props.text}
        </div>
        </animated.div>
      </div>
    </>
  )
}

export default AddBlock;
