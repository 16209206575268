import React from 'react'
import AddBlock from './AddBlock'
import RentBlock from './RentBlock'
import RentERVBlock from './RentERVBlock'
import LoanBlock from './LoanBlock'
import LeasingEventBlock from './LeasingEventBlock'
import Modal from './Modal'
import Backdrop from './Backdrop'
import './../App.css'
import './../AppMobile.css'
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from 'react-device-detect'

class IncomeNarrative extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      monthsToLeaseExpiry: 0,
      passedLeaseExpiry: new Date(),
    }

    this.handleDay1PassingRentPA = this.handleDay1PassingRentPA.bind(this)
    this.handleLeasingEventAddTiming = this.handleLeasingEventAddTiming.bind(this)
  }

  handleDay1PassingRentPA(theValue) {
    this.props.handleDay1PassingRent(theValue)
  }

  handleLeasingEventAddTiming(theValue) {
    if (theValue > 0) {
      var tempDate = new Date()
      tempDate.setMonth(tempDate.getMonth() + theValue)
      this.setState({ passedLeaseExpiry: tempDate })
      
      this.props.handleAppStageToNewLeaseEvent(tempDate)
      //this.props.changeAppStage()

    } else {
      this.props.handleReverseAppStage()
    }
  }

  incomeNarrativeStatus() {
    var theColumnEmpty = isMobile ? 'columnEmptyMobile' : 'columnEmpty'
    var theContainer = isMobile ? 'buySellContainerMobile' : 'buySellContainer'
    var theTitleItemLarge = isMobile ? 'titleItemLargeMobile' : 'titleItemLarge'

    if (this.props.value < 6) {
      return (
        <>
          <BrowserView>
          <div className="column titleItemLarge"></div>
          </BrowserView>
          <div className="column columnEmpty"></div>
          <div className="column columnEmpty"></div>
          <div className="column columnEmpty"></div>
          <div className="column bracketItem"></div>
        </>
      )
    } else if (this.props.value == 6) {
      // Display Add Passing Rent block

      return (
        <>
        <BrowserView>
        <div className={theContainer}>
          <div className="titleItemLarge">
            <span></span>
          </div>
          <div className="column columnEmpty"></div>
          <div
            className="columnEmpty"
            onClick={() => this.props.changeAppStage()}
          >
            <AddBlock text="+ Add Passing Rent pa" />
          </div>
          <div className="column columnEmpty"></div>
          <div className="column bracketItem"></div>
        </div>
        </BrowserView>
        <MobileView>
          <div
            className={theColumnEmpty}
            onClick={() => this.props.changeAppStage()}
          >
            <AddBlock text="+ Add Passing Rent pa" />
          </div>
        </MobileView>
        </>
      )
    } else if (this.props.value == 7) {
      // Display Modal for Passing Rent

      return (
        <>
          <div className="columnEmpty"></div>
          <div className="column columnEmpty"></div>
          <div className={theColumnEmpty}>
            <AddBlock text="+ Add Passing Rent pa" />
          </div>
          <Backdrop />
          <div>
            <Modal
              title="Input your Passing Rent per annum"
              currencySymbol={this.props.currencySymbol}
              applyModalValue={this.handleDay1PassingRentPA}
              handleReverseAppStage={this.props.handleReverseAppStage}
            />
          </div>
        </>
      )
    } else if (this.props.value == 8) {
      // Display Passing Rent

      return (
        <>
          <div className={theContainer}>
            <BrowserView>
            <div className="titleItemLarge">Income</div>
            </BrowserView>
            <RentBlock
              autoFocus
              title="Passing Rent p.a."
              currencySymbol={this.props.currencySymbol}
              currencyCode={this.props.currencyCode}
              day1RentPA={this.props.day1RentPA}
              labelTip=""
              handleDay1PassingRentPA={this.handleDay1PassingRentPA}
              day1NIA={this.props.day1NIA}
              handleDay1NIA={this.props.handleDay1NIA}
            />
            <div
              className="theColumnEmpty"
              onClick={() => this.props.changeAppStage()}
            >
              <AddBlock text="+ Add Market Rent (ERV)" />
            </div>
            <div className="columnEmpty"></div>
            <div className="columnEmpty"></div>
            <div className="bracketItem"></div>
          </div>
        </>
      )
    } else if (this.props.value == 9) {
      // Display Modal for Market Rent
      // *********************************** TODO
      return (
        <>
          <div className={theContainer}>
            <BrowserView>
            <div className="titleItemLarge">Income</div>
            </BrowserView>
            <RentBlock
              autoFocus
              title="Passing Rent pa"
              currencySymbol={this.props.currencySymbol}
              currencyCode={this.props.currencyCode}
              day1RentPA={this.props.day1RentPA}
              labelTip=""
              handleDay1PassingRentPA={this.handleDay1PassingRentPA}
              day1NIA={this.props.day1NIA}
              handleDay1NIA={this.props.handleDay1NIA}
            />
            <div
              className="columnEmpty"
              onClick={() => this.props.changeAppStage()}
            >
              <AddBlock text="+ Add Market Rent (ERV)" />
            </div>
            <div className="columnEmpty"></div>
            <div className="bracketItem"></div>
          </div>
          <Backdrop />
          <div onClick={() => this.props.changeAppStage()}>
            <Modal
              title="Input your Market Rent per annum"
              currencySymbol={this.props.currencySymbol}
              applyModalValue={this.props.handleDay1MarketRent}
              handleReverseAppStage={this.props.handleReverseAppStage}
            />
          </div>
        </>
      )
    } else if (this.props.value == 10) {
      // Display Market Rent and Add New Leasing Event
      // *********************************** TODO
      return (
        <>
          <div className={theContainer}>
            <BrowserView>
            <div className="titleItemLarge">Income</div>
            </BrowserView>
            <RentBlock
              autoFocus
              title="Passing Rent pa"
              currencySymbol={this.props.currencySymbol}
              currencyCode={this.props.currencyCode}
              day1RentPA={this.props.day1RentPA}
              labelTip=""
              handleDay1PassingRentPA={this.handleDay1PassingRentPA}
              day1NIA={this.props.day1NIA}
              handleDay1NIA={this.props.handleDay1NIA}
            />
            <RentERVBlock
              // autoFocus
              title="ERV pa"
              currencySymbol={this.props.currencySymbol}
              currencyCode={this.props.currencyCode}
              day1NIA={this.props.day1NIA}
              day1RentPA={this.props.day1RentPA}
              day1MarketRentPA={this.props.day1MarketRentPA}
              handleDay1MarketRent={this.props.handleDay1MarketRent}

              rentReviewDate={this.props.rentReviewDate}
              rentReviewHeadlinePercent={this.props.rentReviewHeadlinePercent}
              rentReviewPercentFee={this.props.rentReviewPercentFee}
              handleRentReviewValues={this.props.handleRentReviewValues}
            />
            <div
              className="columnEmpty"
              onClick={() => this.props.changeAppStage()}
            >
              <AddBlock text="+Add New Lease Event" />
            </div>
            <div className="bracketItem"></div>
          </div>
        </>
      )
    } else if (this.props.value == 11) {
      // Display Modal for Market Rent
      // *********************************** TODO
      return (
        <>
          <div className={theContainer}>
            <BrowserView>
            <div className="titleItemLarge">Income</div>
            </BrowserView>
            <RentBlock
              autoFocus
              title="Passing Rent pa"
              currencySymbol={this.props.currencySymbol}
              currencyCode={this.props.currencyCode}
              day1RentPA={this.props.day1RentPA}
              labelTip=""
              handleDay1PassingRentPA={this.handleDay1PassingRentPA}
              day1NIA={this.props.day1NIA}
              handleDay1NIA={this.props.handleDay1NIA}
            />
            <RentERVBlock
              // autoFocus
              title="ERV pa"
              currencySymbol={this.props.currencySymbol}
              currencyCode={this.props.currencyCode}
              day1NIA={this.props.day1NIA}
              day1RentPA={this.props.day1RentPA}
              day1MarketRentPA={this.props.day1MarketRentPA}
              handleDay1MarketRent={this.props.handleDay1MarketRent}

              rentReviewDate={this.props.rentReviewDate}
              rentReviewHeadlinePercent={this.props.rentReviewHeadlinePercent}
              rentReviewPercentFee={this.props.rentReviewPercentFee}
              handleRentReviewValues={this.props.handleRentReviewValues}
            />
            <div className="columnEmpty">
              <AddBlock text="+ Add Market Rent (ERV)" />
            </div>
            <div className="bracketItem"></div>
          </div>
          <Backdrop />
          <Modal
            title="Enter months to Lease Expiry"
            currencySymbol=""
            applyModalValue={this.handleLeasingEventAddTiming}
            //applyModalValue={this.props.changeAppStage} //{this.props.handleDay1MarketRent}
            handleReverseAppStage={this.props.handleReverseAppStage}
          />
        </>
      )
    } else if (this.props.value == 12) {
      // Income Narrative complete - Display Other Options
      return (
        <>
          <div className={theContainer}>
            <BrowserView>
            <div className="titleItemLarge">Income</div>
            </BrowserView>
            <RentBlock
              autoFocus
              title="Passing Rent pa"
              currencySymbol={this.props.currencySymbol}
              currencyCode={this.props.currencyCode}
              day1RentPA={this.props.day1RentPA}
              labelTip=""
              handleDay1PassingRentPA={this.handleDay1PassingRentPA}
              day1NIA={this.props.day1NIA}
              handleDay1NIA={this.props.handleDay1NIA}
            />
            <RentERVBlock
              // autoFocus
              title="ERV pa"
              currencySymbol={this.props.currencySymbol}
              currencyCode={this.props.currencyCode}
              day1NIA={this.props.day1NIA}
              day1RentPA={this.props.day1RentPA}
              day1MarketRentPA={this.props.day1MarketRentPA}
              handleDay1MarketRent={this.props.handleDay1MarketRent}

              rentReviewDate={this.props.rentReviewDate}
              rentReviewHeadlinePercent={this.props.rentReviewHeadlinePercent}
              rentReviewPercentFee={this.props.rentReviewPercentFee}
              handleRentReviewValues={this.props.handleRentReviewValues}
            />
            <LeasingEventBlock
              autoFocus
              title="Event"
              currencySymbol={this.props.currencySymbol}
              currencyCode={this.props.currencyCode}
              holdPeriodYears={this.props.holdPeriodYears}
              day1NIA={this.props.day1NIA}
              day1RentPA={this.props.day1RentPA}
              day1MarketRentPA={this.props.day1MarketRentPA}
              handleDay1MarketRent={this.props.handleDay1MarketRent}
              theUnitInfo={this.props.theUnitInfo}
              handleReleasingValues={this.props.handleReleasingValues}
              monthsToLeaseExpiry={this.state.monthsToLeaseExpiry}
              passedLeaseExpiry={this.state.passedLeaseExpiry}
            />
            
            {/* <div className="columnEmpty">
            <AddBlock text="Decide next"/>
            </div> */}
          </div>
        </>
      )
    }
  }

  render() {

    var theIncomeContainer = isMobile ? "incomeContainerMobile" : "incomeContainer";

    return <div className={theIncomeContainer}>{this.incomeNarrativeStatus()}</div>
  }
}
export default IncomeNarrative
