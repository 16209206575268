import React from 'react'
import AddBlock from './AddBlock'
import LoanBlock from './LoanBlock'
import Modal from './Modal'
import Backdrop from './Backdrop'
import './../App.css'
import './../AppMobile.css'
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from 'react-device-detect'

class DebtNarrative extends React.Component {
  constructor(props) {
    super(props)
/*    this.state = {

    }*/
    this.handleDebtAssumptions = this.handleDebtAssumptions.bind(this)
}

  handleDebtAssumptions(ltvValue, intRateValue, arrFeeValue) {
    this.props.handleDebtAssumptions(ltvValue, intRateValue, arrFeeValue)
    console.log('sending LTV from DebtNarrative to parent: ' + ltvValue)
  }


  debtNarrativeStatus() {
    var theColumnEmpty = isMobile ? 'columnEmptyMobile' : 'columnEmpty'
    var theContainer = isMobile ? 'debtContainerMobile' : 'debtContainer'
    var theTitleItemLarge = isMobile ? 'titleItemLargeMobile' : 'titleItemLarge'

    var theDebtTitle = (this.props.seniorLTV > 0 ? "Debt" : "No Debt")

    if (this.props.value > 11) {
      // Income Narrative complete - Display Other Options
      return (
        <>
          <div className={theContainer}>
            <BrowserView>
            <div className="titleItemLarge">{theDebtTitle}</div>
            </BrowserView>
            <LoanBlock
              autoFocus
              seniorLTV={this.props.seniorLTV}
              seniorArrangementFee={this.props.seniorArrangementFee}
              seniorInterestRatePA={this.props.seniorInterestRatePA}
              seniorAmortisationPA={this.props.seniorAmortisationPA}

              currencySymbol={this.props.currencySymbol}
              currencyCode={this.props.currencyCode}
              applyModalDebtAssumptions={this.handleDebtAssumptions}
            />
          </div>
        </>
      )
    }
  }

  render() {

    var theIncomeContainer = isMobile ? "debtContainerMobile" : "debtContainer";

    return <div className={theIncomeContainer}>{this.debtNarrativeStatus()}</div>
  }
}
export default DebtNarrative
