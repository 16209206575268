import React, { useRef } from 'react'
import './../App.css'
import './../AppMobile.css'
import NumberFormat from "react-number-format"
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from 'react-device-detect'

var stylingObject = {
  input: {
    margin: '2px',
    padding: '5px',
  },
  outerDiv: {
    border: '0px',
  },
  addStyling: {
    border: '1px dashed white',
    margin: '40px',
    borderRadius: '20px',
    padding: '40px',
    lineHeight: '30px',
    fontSize: '20px',
    textAlign: 'center',
    fontWeight: 'bolder',
    cursor: 'pointer',
  },
  btnStyling: {
    backgroundColor: 'white',
    border: '1px solid grey',
    borderRadius: '5px',
    fontSize: '18px',
    margin: '15px',
    paddingLeft: '30px',
    paddingRight: '30px',
    color: '#38336E',
  },
  fieldStyling: {
    borderRadius: '20px',
  },
}

class Modal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      inputValue: '',
    }
    this.updateInputValue = this.updateInputValue.bind(this)
    this.submitValueToParent = this.submitValueToParent.bind(this)
    this.reverseTheAppStage = this.reverseTheAppStage.bind(this)

  }

  updateInputValue(evt) {
    const val = evt.target.value
    this.setState({ inputValue: val })
  }

  reverseTheAppStage() {
    this.props.handleReverseAppStage()
  }

  submitValueToParent() {
    this.props.applyModalValue(this.state.inputValue)
  }

  handleKeyDown(event) {
    if (event.keyCode === 13) {
      //console.log('Enter key pressed')
      this.submitValueToParent()
    } else if (event.keyCode === 27) {
      this.reverseTheAppStage()
    }
  }

  theButtonsContent() {
    return (
      <>
      <br />
        <button
          onClick={this.submitValueToParent}
          style={stylingObject.btnStyling}
        >
          Apply
        </button>
        <button
          onClick={this.reverseTheAppStage}
          style={stylingObject.btnStyling}
        >
          Cancel
        </button>
        <br />
      </>
    )
  }

  render() {

    var theModal = isMobile ? 'modalMobile' : 'modal'
    let thePrefix = (this.props.title.includes("Hold")) ? '' : '£'
    let theSuffix = (this.props.title.includes("Hold")) ? ' years' : ''
    return (
      <div className={theModal}>
        <p>{this.props.title}</p>
        <fieldset className="form-Fieldset" style={stylingObject.fieldStyling}>
          <label className="form-FieldLabel" style={stylingObject.fieldStyling}>
            <NumberFormat
              autoFocus
              value={this.state.inputValue}
              prefix = {this.props.currencySymbol}
              suffix = {theSuffix}
              inputMode={"Decimal"}
              thousandSeparator={true}
              //onValueChange={(evt) => this.updateInputValue(evt)}
              onValueChange={(values) => {
                const { formattedValue, value, floatValue } = values
                this.setState({ inputValue: floatValue }, () => {
                  //this.props.applyModalValue(this.state.inputValue)
                })
                // do something with floatValue
              }}
              onKeyDown={(evt) => this.handleKeyDown(evt)}
              //type="number"
              //name="theName"
              style={{ borderRadius: '5px', width: '90%', border: '0px', fontSize: '22px', textAlign: 'center', }}
            />
          </label>
        </fieldset>
        {/* </form> */}
        {this.theButtonsContent()}
        <p></p>
      </div>
    )
  }
}

export default Modal
